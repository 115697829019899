export const invoiceActions_setShipmentStatus = (status) => {
  return {
    type: 'SETINVOICESTATUS',
    payload: status,
  };
};

export const invoiceActions_setStartDate = (startDate) => {
  return {
    type: 'SETINVOICESTARTDATE',
    payload: startDate,
  };
};

export const invoiceActions_setEndDate = (endDate) => {
  return {
    type: 'SETINVOICEENDDATE',
    payload: endDate,
  };
};

export const invoiceActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETINVOICESEARCHOPTION',
    payload: searchOption,
  };
};

export const invoiceActions_setSearchText = (searchText) => {
  return {
    type: 'SETINVOICESEARCHTEXT',
    payload: searchText,
  };
};

export const invoiceActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETINVOICEPAGENUMBER',
    payload: pageNumber,
  };
};

export const invoiceActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETINVOICETOTALSIZE',
    payload: totalSize,
  };
};

export const invoiceActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETINVOICEISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const invoiceActions_setReset = () => {
  return {
    type: 'SETINVOICERESET',
  };
};

export const invoiceActions_setViewScale = (viewScale) => {
  return {
    type: 'SETINVOICEVIEWSCALE',
    payload: viewScale,
  };
};
