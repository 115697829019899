import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { moduleManagementActions_setPageNumber, moduleManagementActions_setReset, moduleManagementActions_setSearchText, moduleManagementActions_setTotalSize } from 'store/modules/actions/common/moduleManagementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { priceMenu } from 'api/apis/operator/priceMenu';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { ModuleManagementComponent } from './ModuleManagement.style';
import CreateModule from './crud/CreateModule';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import PriceTypeManagement from './priceType/PriceTypeManagement';
import { priceTypeApi } from 'api/apis/operator/priceType';

const ModuleManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { moduleReducer, userReducer } = useSelector((state) => state);
  const [_onload, setOnload] = useState('unload');

  const [_priceTypes, setPriceTypes] = useState([]);
  const [_moduleList, setModuleList] = useState([]);
  const keys = ['priceMenuTypeName', 'pageCode', 'defaultPageName', 'companyId', 'priceMenuId', 'companyPageCodeId'];

  const [_checkedItem, setCheckedItem] = useState({});

  const [_modalStatus, setModalStatus] = useState(false); // 모듈 모달
  const [_priceTypeModalStatus, setPriceTypeModalStatus] = useState(false); // 요금유형 모달

  // --------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getPriceTypes();
    getModuleList(moduleReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getModuleList(moduleReducer.pageNumber - 1);
    return () => { };
  }, [moduleReducer.pageNumber]);

  useEffect(() => { }, [moduleReducer, _priceTypes, _checkedItem]);

  // --------------------------------------------------------------------------------------------------------------

  const getModuleList = async (page, clear) => { // 모듈 리스트 불러오기
    const paging = `?page=${page}&size=35&sort=id,DESC`;
    const body = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(moduleReducer.searchText, false)) {
      body.priceMenuTypeId = moduleReducer.searchText;
    }

    await priceMenu.searchPriceMenu(paging, body).then((response) => {
      if (response === undefined) return;
      setModuleList(() => { return response.data.content });
      dispatch(moduleManagementActions_setTotalSize(response.data.totalElements));

    })
  }

  const getPriceTypes = async () => { // 모듈 요금유형 데이터 만들기
    await priceTypeApi.search(
      { companyId: userReducer.company.companyId }
    ).then(response => {
      if (response === undefined) return;
      setPriceTypes(() => { return response.data.content });
    })
    // const paging = `?page=0&size=7000&sort=id,DESC`;
    // const body = {
    //   companyId: userReducer.company.companyId,
    //   priceType: '',
    // };

    // await priceMenu.searchPriceMenu(paging, body).then((response) => {
    //   if (response === undefined) return;
    //   console.log('getPriceTypes : ', response);
    //   const newPriceType = response.data.content.map((item) => item.priceType)
    //   const checkedPriceType = newPriceType.filter((item, index) => newPriceType.indexOf(item) === index)
    //   setPriceTypes(() => { return checkedPriceType });
    // })
  }

  // --------------------------------------------------------------------------------------------------------------

  const actDelete = async (e) => { // 삭제
    e.preventDefault();
    const deleteText = `
    요금유형: ${_checkedItem.priceMenuTypeName}
    pageCode: ${_checkedItem.pageCode}
    defaultPageName: ${_checkedItem.defaultPageName}

    companyId: ${_checkedItem.companyId}
    priceMenuId: ${_checkedItem.priceMenuId}
    companyPageCodeId: ${_checkedItem.companyPageCodeId}

    삭제하시겠습니까?
    `;

    if (window.confirm(deleteText)) {
      await priceMenu.deletePriceMenu(_checkedItem.priceMenuId).then(response => {
        if (response === undefined) return;
        alert('삭제되었습니다.');
        getModuleList(moduleReducer.pageNumber - 1);
      })
    }
  }

  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      <OperatorBody contents={<>
        <ModuleManagementComponent className="Main">
          <NavBar
            title={
              <div className='Title'>
                <h4>모듈관리</h4>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <p>요금유형 선택</p>
                    <select className="SearchBar detail"
                      value={moduleReducer.searchText}
                      onChange={(e) => { dispatch(moduleManagementActions_setSearchText(e.target.value)) }}
                    >
                      <option value={''}>전체</option>
                      {checkNullArray(_priceTypes, []).map((priceType, index) => {
                        return (<option key={index + '_priceType'} value={priceType.priceMenuTypeId}>{priceType.priceMenuTypeName}</option>)
                      })}
                    </select>
                  </div>
                  <button data-searchbutton="true" onClick={(e) => {
                    e.preventDefault();
                    dispatch(moduleManagementActions_setPageNumber(1)); // 페이지 초기화 => 1
                    getModuleList(0); // 모듈 리스트 불러오기
                  }}><SearchButtonIcon /></button>
                  <button className="ResetButton"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(moduleManagementActions_setReset()); // 검색 조건 초기화
                      getModuleList(0, true); // 모듈 리스트 불러오기
                    }}
                  >초기화</button>
                </div>
              </div>
            }
            nav={''}
            buttons={
              <>
                <button className='btn-set' onClick={(e) => { e.preventDefault(); setPriceTypeModalStatus(() => { return true }); }}>요금유형</button>
                <button className='btn-add' onClick={(e) => { e.preventDefault(); setModalStatus(() => { return true }); }}>추가</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}></th>
                  {keys.map((key, index) => { return (<th key={index + '_key'}>{key}</th>) })}
                </tr>
              </thead>
              <tbody>
                {checkNullArray(_moduleList, []).map((module, index) => {
                  return (
                    <tr key={module.priceMenuId + '_modules'}>
                      <td>
                        <input
                          type="radio"
                          name="modules"
                          onChange={() => { setCheckedItem(() => { return module }) }}
                        />
                      </td>

                      {keys.map((key, keyIndex) => {
                        return (<td key={keyIndex + '_module_info'}>{module[key]}</td>)
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          } />
        </ModuleManagementComponent>

        <PagingComponent
          page={moduleReducer.pageNumber}
          count={moduleReducer.totalSize}
          size={35}
          pageEvent={(page) => { dispatch(moduleManagementActions_setPageNumber(page)); }}
        />
      </>} />

      {_modalStatus ? <>
        <CreateModule
          update={() => {
            dispatch(moduleManagementActions_setPageNumber(1)); // 페이지 초기화 => 1
            getModuleList(0); // 모듈 리스트 불러오기

            setTimeout(setModalStatus(() => { return false }), 1000); // 모달 닫기
          }}
          open={_modalStatus}
          close={() => { setModalStatus(() => { return false }) }}
          priceType={_priceTypes}
        />
      </> : null}

      {_priceTypeModalStatus ? <>
        <PriceTypeManagement
          update={() => {
            getPriceTypes(); // 요금 유형 리스트 불러오기
            getModuleList(moduleReducer.pageNumber - 1); // 모듈 리스트 불러오기

            // setTimeout(setPriceTypeModalStatus(() => { return false }), 1000); // 모달 닫기
          }}
          open={_priceTypeModalStatus}
          close={() => { setPriceTypeModalStatus(() => { return false }) }}
          priceType={_priceTypes}
        />
      </> : null}
    </>
  );
};

export default ModuleManagement;