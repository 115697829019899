import React from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { companyMenu } from 'api/apis/operator/companyMenu';

import { checkEmptyNull, checkNullParse } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';

const MainSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
`;

const DeleteCompanyMenu = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const companyName = searchParams.get('companyName');
  const deleteContent = checkNullParse(searchParams.get('deleteContent'), {});
  console.log('deleteContent : ', deleteContent);

  const actDelete = async () => {
    await companyMenu.deleteCompanyMenu(deleteContent.companyMenuId).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyMenu.deleteCompanyMenu : ', response);
      alert('메뉴를 삭제했습니다.')
      navigate({
        pathname: '/operator/companyManagement/menu',
        search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
        replace: true,
      })
    })
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={`${companyName} 메뉴관리`}
            buttons={
              <>
                <button className='btn-set'
                  onClick={(e) => {
                    e.preventDefault();
                    navigate({
                      pathname: '/operator/companyManagement/menu',
                      search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
                      replace: true,
                    })
                  }}
                >
                  뒤로
                </button>
              </>
            }
            nav={''}
          />

          <DeleteForm
            delteTitle={'메뉴'}
            deleteItem={
              <>
                {Object.keys({
                  companyId: deleteContent.companyId,
                  companyPageCodeId: deleteContent.companyPageCodeId,
                  companyMenuId: deleteContent.companyMenuId,
                  pageCode: deleteContent.pageCode,
                  pageName: deleteContent.pageName,
                  pageUrl: deleteContent.pageUrl,
                  indexX: deleteContent.indexX,
                  indexY: deleteContent.indexY,
                }).map((key, index) => {
                  return (<p key={index + '_infos'}>{`${key} : ${JSON.stringify(deleteContent[key])}`}</p>)
                })}
              </>
            }
            deleteText={<>메뉴를 삭제합니다.<br /></>}
            deleteButton={
              <>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate({
                      pathname: '/operator/companyManagement/menu',
                      search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
                      replace: true,
                    })
                  }}
                >
                  취소
                </button>
                <button className="formButton delete"
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.confirm('메뉴를 삭제합니다.')) actDelete();
                    else return;
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </MainSection>
      </>
    }
    />
  );
};

export default DeleteCompanyMenu;
