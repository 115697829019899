import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Content = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 60px auto;
  width: 100%; height: 100%;
`;
const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 24px 16px;
  width: 100%;
`;
const Title = styled.h1`
  font-size: 20px;
`;
const SelectType = styled.select`
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  height: 35px;
  padding: 2px 10px;
  width: 150px;
`;
const SearchBar = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex; gap: 8px;
  input {
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 4px 16px;
    width: 100%;
  }
`;
const ButtonsWrap = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

const ContractAttr = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_defaultList, setDefaultList] = useState([
    { key: 'contractDate', value: '수주일자', },
    { key: 'contractRegDate', value: '납품요구접수일자', },
    { key: 'scheduledEndDate', value: '납품기한일자', },
    { key: 'contractActualEndDate', value: '납품일자', },
    { key: 'contractCode', value: '수주코드', },
    { key: 'contractName', value: '수주이름', },
    { key: 'address', value: '수주주소', },
    { key: 'contractPrice', value: '수주총금액', },
    { key: 'remark', value: '비고', },
  ]);
  const [_attrList, setAttrList] = useState([]);

  const [_typeList, setTypeList] = useState([]);
  const [_attrType, setAttrType] = useState('all');

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getContractTypes();
    // getContractAttrs(_attrType);

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getContractTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.searchAccountType : ', response);

      if (checkNullArray(response.data, false)) {
        setAttrType(() => { return response.data[0].contractTypeId })
        getContractAttrs(response.data[0].contractTypeId);
      }
      setTypeList(() => { return response.data });
    });
  };

  const getContractAttrs = async (contractType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.contractAttributeName = _searchText;
    }

    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractAttributeApi.searchContractAttribute : ', response);

      setAttrList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  const actSearch = async () => {
    getContractAttrs(_attrType);
  };

  /* 추가 */
  const actCreate = async () => {
    if (_attrType === 'all') return alert('수주 유형을 선택해주세요.');

    const typeData = _typeList.find((thisItem) => {
      if (thisItem.contractTypeId === Number(_attrType)) return true;
      else return false;
    });

    if (typeData === undefined) return;

    const promptText = `
    수주 유형 : ${typeData.contractTypeName}
    추가할 항목명을 입력해주세요.
    `;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractTypeId: Number(_attrType),
      contractAttributeName: CreatePrompt,
    };
    console.log('actCreate - body : ', BodyToPost);

    await contractAttributeApi.createContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractAttributeApi.createContractAttribute : ', response);

      setAttrList((prev) => {
        return [...prev, response.data];
      });

      alert('항목을 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContentIndex = _attrList.findIndex((thisItem) => thisItem.contractAttributeId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 항목을 선택해 주세요.');
    const updateContent = _attrList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const typeData = _typeList.find((thisItem) => {
      if (thisItem.contractTypeId === updateContent.contractTypeId) return true;
      else return false;
    });

    if (typeData === undefined) return;

    const promptText = `
    수주 유형 : ${typeData.contractTypeName}
    항목을 수정합니다.
    `;
    const UpdatePrompt = window.prompt(
      promptText,
      updateContent.contractAttributeName,
    );
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      contractTypeId: updateContent.contractTypeId,
      contractAttributeId: updateContent.contractAttributeId,
      contractAttributeName: UpdatePrompt,
    };
    console.log('actUpdate - body : ', BodyToPut);

    await contractAttributeApi.updateContractAttribute(updateContent.contractAttributeId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('contractAttributeApi.updateContractAttribute : ', response);

      setAttrList((prev) => {
        const prevData = [...prev];
        prevData.splice(updateContentIndex, 1, response.data);

        return prevData;
      });

      alert('항목을 수정했습니다.');
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _attrList.findIndex((thisItem) => thisItem.contractAttributeId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 항목을 선택해 주세요.');
    const deleteContent = _attrList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    const typeData = _typeList.find((thisItem) => {
      if (thisItem.contractTypeId === deleteContent.contractTypeId) return true;
      else return false;
    });

    if (typeData === undefined) return;

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        content: JSON.stringify(deleteContent),
        type: 'contract',
        typeData: JSON.stringify(typeData),
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <ContentHeader>
        <Title>수주 항목 설정</Title>
        <div className='content-snb'>
          <SelectType value={_attrType} onChange={(e) => {
            setAttrType(() => { return e.target.value });
            getContractAttrs(e.target.value);
          }}
          >
            {_typeList.map((type, index) => {
              return (
                <option key={index + '_contractType'} value={type.contractTypeId}>{type.contractTypeName}</option>
              );
            })}
          </SelectType>
          <ButtonsWrap>
            {(() => {
              if (props.authority.indexOf('103-2') !== -1 && props.authority.indexOf('104-2') !== -1) {
                return (<>
                  <button className='btn-add' onClick={actCreate}>추가</button>
                  <button className='btn-edit' onClick={actUpdate}>수정</button>
                  <button className='btn-delete' onClick={actDelete}>삭제</button>
                </>);
              } else {
                return null;
              }
            })()}
          </ButtonsWrap>

          <SearchBar>
            <input placeholder="항목명..." onInput={(e) => { setSearchText(() => { return e.target.value }) }} />
            <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
          </SearchBar>
        </div>
      </ContentHeader>
      <TableSection
        content={
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ minWidth: 'unset', width: '50px' }}></th>
                <th>항목명</th>
              </tr>
            </thead>
            <tbody>
              {_defaultList.map((attr, index) => {
                return <tr key={index + '_default'}>
                  <td style={{ backgroundColor: 'var(--MainNavy)', minWidth: 'unset', width: '50px' }}></td>
                  <td>{attr.value}</td>
                </tr>
              })}
              {_attrList.map((attr, index) => {
                return (
                  <tr key={index + '_attr'}>
                    <td style={{ minWidth: 'unset', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="contractAttrs"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(attr.contractAttributeId, isChecked);

                          if (isChecked) {
                            document.getElementsByName('contractAttrs').forEach((thisAttr) => { thisAttr.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                      />
                    </td>
                    <td>{attr.contractAttributeName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      ></TableSection>
    </Content>
  );
};

export default ContractAttr;
