import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { company } from 'api/apis/operator/company';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const Section = styled.main`
  .TableSection {

  }
`;

const CreateCompany = () => {
  const navigate = useNavigate();
  const { userReducer, pageReducer } = useSelector((state) => state);
  const [_onload, setOnload] = useState('unload');

  const [_companyName, setCompanyName] = useState('');

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });

    setOnload('loaded');
    return () => { };
  }, []);

  const handleInputEvent = (e) => { // 입력 이벤트
    const { value } = e.target;
    setCompanyName(() => { return value });
  };

  const actCreate = async (e) => { // 회사추가
    e.preventDefault();
    if (!checkEmptyNull(_companyName, false)) return alert('companyName은 필수 값입니다.');

    const body = {
      companyName: _companyName,
      elementTypes: JSON.stringify(['material', 'half', 'product']),
      stockTypes: JSON.stringify({ stockTypes: 'In/Put/Out' }),
    };

    await company.createCompany(body).then((response) => {
      if (response === undefined) return;
      alert('회사를 추가했습니다');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <Section className="Main">
        <NavBar title={'회사관리'} nav={''} />

        <Form
          title={<>회사<br />추가</>}
          buttons={
            <>
              <button className="formButton" onClick={actCreate}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
              <div className="formBox">
                <h4>companyName</h4>
                <input
                  type="text"
                  name="companyName"
                  data-required="required"
                  placeholder="companyName"
                  onInput={handleInputEvent}
                />
              </div>
            </>
          }
        />
      </Section>
    }
    />
  );
};

export default CreateCompany;
