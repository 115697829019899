import { AlarmTemplate, CompanyAccessHistory, CompanyManagement, CompanyMenu, CreateCompanyManagement, CreateCompanyMenu, CreateCompanyModules, CreateModule, CreatePageCode, DeleteCompanyManagement, DeleteCompanyMenu, DeletePageCode, MasterUserManagement, ModuleManagement, OperatorDashboard, PageCodeManagement, ProjectManagement, ProjectManagementIndex, ProjectTypeManagement, QnAManagement, Sensor, Test, UpdateCompanyManagement, UpdateCompanyMenu, UpdateMasterCompany, UpdatePageCode } from "../RouteImport";
import RouteLayout from "../layouts/RouteLayout";

export const operaterRoutes = [
  { // ROLE_MASTER 어드민 페이지
    path: 'operator', element: <RouteLayout />,
    children: [
      { // 어드민 스프링보드
        path: '', element: <OperatorDashboard />
      },

      { // 프로젝트
        path: 'projectManagement', element: <ProjectManagementIndex />,
        children: [
          { path: '', element: <ProjectManagement />, },
          { path: 'type', element: <ProjectTypeManagement />, },
        ]
      },
      { // 페이지코드
        path: 'pageCode', element: <RouteLayout />,
        children: [
          { path: '', element: <PageCodeManagement />, },
          { path: 'create', element: <CreatePageCode />, },
          { path: 'update', element: <UpdatePageCode />, },
          { path: 'delete', element: <DeletePageCode />, },
        ],
      },
      { // 모듈
        path: 'module', element: <RouteLayout />,
        children: [
          { path: '', element: <ModuleManagement />, },
          { path: 'create', element: <CreateModule />, },
        ],
      },
      { // 회사
        path: 'companyManagement', element: <RouteLayout />,
        children: [
          { path: '', element: <CompanyManagement />, },
          { path: 'create', element: <CreateCompanyManagement />, },
          { path: 'update', element: <UpdateCompanyManagement />, },
          { path: 'delete', element: <DeleteCompanyManagement />, },
          { path: 'accessHistory', element: <CompanyAccessHistory />, },
          { // 회사 메뉴
            path: 'menu', element: <RouteLayout />,
            children: [
              { path: '', element: <CompanyMenu />, },
              { path: 'modules', element: <CreateCompanyModules />, },
              { path: 'create', element: <CreateCompanyMenu />, },
              { path: 'update', element: <UpdateCompanyMenu />, },
              { path: 'delete', element: <DeleteCompanyMenu />, },
            ]
          },
        ],
      },
      { path: 'user', element: <MasterUserManagement />, }, // 사용자
      { path: 'UpdateCompany', element: <UpdateMasterCompany />, }, // 접속자 회사 업데이트
      { path: 'hr', element: <OperatorDashboard /> }, // 근태 >> 머지 필요
      { path: 'alarm', element: <AlarmTemplate />, }, // 알람
      { path: 'sensor', element: <Sensor /> }, // 센서
      { path: 'qna', element: <QnAManagement /> }, // 문의
      { path: 'test', element: <Test />, }, // 테스트
    ],
  }
];