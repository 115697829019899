import React from 'react';

const UpdateInvoice = () => {
  return (
    <div>

    </div>
  );
};

export default UpdateInvoice;