import { AxiosInterceptor } from 'api/core';

export const accountApi = {
  getAccount: (accountId) => AxiosInterceptor.get('account/' + accountId),
  searchAccount: (paging, body) =>
    AxiosInterceptor.post('account/search' + paging, body),
  createAccount: (body) => AxiosInterceptor.post('account', body),
  uploadAccount: (body) => AxiosInterceptor.post('accounts', body),
  updateAccount: (accountId, body) =>
    AxiosInterceptor.put('account/' + accountId, body),
  deleteAccount: (accountId) => AxiosInterceptor.delete('account/' + accountId),
};
