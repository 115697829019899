import React from 'react';

import { userApi } from 'api/apis/userApi';

const RoleContent = ({
  close, modalData
}) => {
  const roleList = ['ROLE_MASTER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_GUEST'];
  const body = {
    companyId: modalData.companyId,
    teamId: modalData.team?.teamId,
    id: modalData.id,
    name: modalData.name,
    role: modalData.role,
    email: modalData.email,
    emailVerified: modalData.emailVerified,
    phoneNumber: modalData.phoneNumber,
    memo: modalData.memo,
  };
  const actRoleUpdate = async(role) => { // role 변경
    await userApi.updateUser(body.id, {...body, role: role}).then(response => {
      if(response === undefined) return;
      console.log('actRoleUpdate : ', response);
      alert('사용자 role을 변경했습니다.');
      close('update');
    })
  };
  return (
    <section className='role-content'>
      <div className={modalData.role}><span>현재: </span>{modalData.role}</div>
      {roleList.map(role => {
        return(
          <button key={role} className={'btn-role' + ' ' + role} 
            onClick={(e) => {e.preventDefault(); actRoleUpdate(role);}}
          >
            {role}
          </button>
        );
      })}
    </section>
  );
};

export default RoleContent;