import React from 'react';

import { userApi } from 'api/apis/userApi';

const DeleteContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    teamId: modalData.team?.teamId,
    id: modalData.id,
    loginId: modalData.loginId,
    name: modalData.name,
    role: modalData.role,
    email: modalData.email,
    emailVerified: modalData.emailVerified,
    phoneNumber: modalData.phoneNumber,
    memo: modalData.memo,
  };

  const actDelete = async(e) => { // 사용자 삭제
    const confirmText = `${body.name} 사용자를 삭제하시겠습니까?`;
    if(window.confirm(confirmText)) {
      await userApi.deleteUser(body.id).then(response => {
        if(response === undefined) return;
        console.log('actDelete : ', response);
        alert(`${body.name} 사용자를 삭제했습니다.`);
        close('update');
      })
    } else {
      close();
    }
  };

  return (
    <section className='delete-content'>
      <div>
        {[
          {name: 'id', title: 'id', disabled: true},
          {name: 'loginId', title: 'loginId', disabled: true},
          {name: 'name', title: '이름', disabled: true},
        ].map(((userData) => {
          return (
            <div key={userData.name}>
              <h4>{userData.title}</h4>
              <input
                type='text'
                name={userData.name}
                disabled={userData.disabled}
                value={body[userData.name]}
              />
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actDelete}>삭제</button>
    </section>
  );
};

export default DeleteContent;