import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { shipmentInspectionLogActions_setEndDate, shipmentInspectionLogActions_setPageNumber, shipmentInspectionLogActions_setReset, shipmentInspectionLogActions_setSearchOption, shipmentInspectionLogActions_setStartDate, shipmentInspectionLogActions_setTotalSize } from 'store/modules/actions/common/shipmentInspectionLogActions';

import { shipmentInspectionLog } from 'api/apis/shipmentInspection/shipmentInspectionLog';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg'
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShipmentInspectionLogFileModal from 'pages/shipmentInspectionLog/modal/ShipmentInspectionLogFileModal';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  & .TableSection tbody tr {
    cursor: pointer;
    &:hover {opacity: 0.8;}
  }
`;
const InspectionFileButton = styled.td`
  max-width: 70px!important;
  min-width: 70px!important;
  height: 100%;
  cursor: pointer;
  padding: 8px;
  vertical-align: middle;
  display: flex; justify-content: center; align-items: center;
  &:hover {opacity: .7;}
`;

const ShipmentInspectionLog = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, shipmentInspectionLogReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_shipmentInspectionLogList, setShipmentInspectionLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});
  console.log('_checkedItem : ', _checkedItem);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventShipmentInspectionLog, setEventShipmentInspectionLog] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('604') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getShipmentInspectionLogList(shipmentInspectionLogReducer.pageNumber - 1);

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentInspectionLogList(shipmentInspectionLogReducer.pageNumber - 1);
    }

    return () => { };
  }, [shipmentInspectionLogReducer.pageNumber]);

  useEffect(() => { }, [_checkedItem])

  /* ========================================================================= #4 */
  const getShipmentInspectionLogList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(shipmentInspectionLogReducer.startDate, false) && checkEmptyNull(shipmentInspectionLogReducer.endDate, false)) {
    }
    if (!clear && checkEmptyNull(shipmentInspectionLogReducer.searchOption, false) && checkEmptyNull(shipmentInspectionLogReducer.searchText, false)) {
      BodyToPost[shipmentInspectionLogReducer.searchOption] = shipmentInspectionLogReducer.searchText;
    }

    await shipmentInspectionLog.searchShipmentInspectionLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionLog.searchShipmentInspectionLog : ', response);
      setShipmentInspectionLogList(() => { return response.data.content; });
      dispatch(shipmentInspectionLogActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (item, isChecked) => {
    if (isChecked) setCheckedItem(() => { return item; });
    else setCheckedItem(() => { return {} })
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(shipmentInspectionLogActions_setPageNumber(1));
    await getShipmentInspectionLogList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(shipmentInspectionLogActions_setReset());
    dispatch(shipmentInspectionLogActions_setPageNumber(1));
    await getShipmentInspectionLogList(0, true);
  };

  /* 상세보기 */
  const goDetail = (detailContent) => {
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(detailContent) })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const updateContent = _checkedItem;
    if (!checkNullObject(updateContent, false)) return alert('수정할 시험성적서를 선택해주세요.');
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(updateContent) })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContent = _checkedItem;
    if (!checkNullObject(deleteContent, false)) return alert('삭제할 시험성적서를 선택해주세요.');
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(deleteContent) })}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={<NavTitle menuCode={'604'} />}
            buttons={
              <>
                {_authority.indexOf('604-2') !== -1 ? (
                  <>
                    <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }) }}>추가</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={shipmentInspectionLogReducer.startDate}
                      onChange={(e) => {
                        const startDate = e.target.value;
                        dispatch(shipmentInspectionLogActions_setStartDate(startDate));
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={shipmentInspectionLogReducer.endDate}
                      onChange={(e) => {
                        const endDate = e.target.value;
                        dispatch(shipmentInspectionLogActions_setEndDate(endDate));
                      }}
                    />
                  </div>
                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={shipmentInspectionLogReducer.searchOption}
                        onChange={(e) => {
                          const searchOption = e.target.value;
                          dispatch(shipmentInspectionLogActions_setSearchOption(searchOption))
                        }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'shipmentInspectionLogCode', optionText: '시험성적서 이름' },
                          { optionValue: 'shipmentInspectionLogName', optionText: '프로젝트' },
                          { optionValue: 'managerName', optionText: '담당자' },
                          { optionValue: 'checkerName', optionText: '검토자' },
                          { optionValue: 'approvalUserName', optionText: '승인자' },
                        ].map((option) => {
                          return (<option key={option.optionValue + '_shipmentInspectionLogSearchOption'} value={option.optionValue}>{option.optionText}</option>);
                        })}
                      </select>

                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={shipmentInspectionLogReducer.searchText}
                        onInput={(e) => {
                          const searchText = e.target.value;
                          dispatch(shipmentInspectionLogActions_setSearchOption(searchText))
                        }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}></th>
                  <th style={{ minWidth: '70px', padding: 'unset', width: '70px' }}>출력</th>
                  <th>일자</th>
                  <th>시험성적서 이름</th>
                  <th>프로젝트</th>
                  <th>담당자</th>
                  <th>검토자</th>
                  <th>승인자</th>
                </tr>
              </thead>
              <tbody>
                {_shipmentInspectionLogList.length == 0 ? LoadingMsg() :
                  checkNullArray(_shipmentInspectionLogList, []).map((shipmentInspectionLog, index) => {
                    return (
                      <tr key={index + '_shipmentInspectionLog'}
                        onClick={(e) => {
                          e.preventDefault();
                          goDetail(shipmentInspectionLog)
                        }}
                      >
                        {_authority.indexOf('604-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="shipmentInspectionLogs"
                              checked={checkEmptyNull(_checkedItem.shipmentInspectionLogId, false) ? shipmentInspectionLog.shipmentInspectionLogId === _checkedItem.shipmentInspectionLogId : false}
                              onChange={(e) => {
                                e.preventDefault();
                                const isChecked = e.target.checked;
                                handleCheckedItem(shipmentInspectionLog, isChecked);
                                if (isChecked) e.target.checked = true;
                                else e.target.checked = false;
                              }}
                              onClick={e => { e.stopPropagation(); }}
                            />
                          </td>
                        ) : null}
                        <InspectionFileButton onClick={(e) => {
                          e.stopPropagation();
                          setEventShipmentInspectionLog(shipmentInspectionLog);
                          setTimeout(setModalStatus(true), 1000);
                        }}>
                          <img src={process.env.PUBLIC_URL + '/src/icons/print.svg'} />
                        </InspectionFileButton>
                        <td>{checkEmptyNull(shipmentInspectionLog.shipmentInspectionLogDate, false) ? DateFormat(shipmentInspectionLog.shipmentInspectionLogDate) : ''}</td>
                        <td>{shipmentInspectionLog.shipmentInspectionLogCode}</td>
                        <td>{shipmentInspectionLog.shipmentInspectionLogName}</td>
                        <td>{checkNull(shipmentInspectionLog.managerName, '')}</td>
                        <td>{checkNull(shipmentInspectionLog.checkerName, '')}</td>
                        <td>{checkNull(shipmentInspectionLog.approvalUserName, '')}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          />
        </MainSection>

        <PagingComponent
          page={shipmentInspectionLogReducer.pageNumber}
          count={shipmentInspectionLogReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(shipmentInspectionLogActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true && checkNullObject(_eventShipmentInspectionLog, false) && (
          <ShipmentInspectionLogFileModal open={_modalStatus} close={() => { setModalStatus(false) }} shipmentInspectionLog={_eventShipmentInspectionLog} />
        )}
      </>
    }
    />
  );
};

export default ShipmentInspectionLog;
