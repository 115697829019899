import { useRoutes } from 'react-router-dom';
import { getCookie } from 'preferences/cookie/cookie';

import { guestRoutes } from './routes/guest';
import { userRoutes } from './routes/user';
import { operatoerRoutes } from './routes/operatoer';

const RouteList = () => {
  const guest = useRoutes([...guestRoutes]);
  const user = useRoutes([...userRoutes, ...operatoerRoutes]);

  return getCookie('accessToken') === undefined ? guest : user;
};

export default RouteList;