import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { setCookie } from 'preferences/cookie/cookie';
import { CUSTOM_AUTH_URL, REDIRECT_URL } from 'preferences/server/constants';
import { auth } from 'api/apis/auth/auth';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import { ClusterMainComponent } from 'platform/cluster/styles/main.style';
import ClusterHeader from 'platform/cluster/components/ClusterHeader';

const ClusterSignLogin = () => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const [_id, setID] = useState('');
  const [_pw, setPW] = useState('');
  useEffect(() => { }, [_id, _pw]);

  const actLogin = async () => {
    if (!checkEmptyNull(_id, false)) return alert('아이디를 확인해주세요.');
    if (!checkEmptyNull(_pw, false)) return alert('비밀번호를 확인해주세요.');
    const body = { loginId: _id, password: _pw };
    await auth.login(body).then(async (response) => {
      if (response === undefined) return;
      console.log('auth.login : ', response);

      // 쿠키 설정
      setCookie('accessToken', response.data.accessToken, {});
      setCookie('refreshToken', response.data.refreshToken, {});
      setCookie('tokenTime', moment().format('YYYY-MM-DD HH:mm:ss'), {});

      // 
    });
  };

  const actGoogleLogin = () => {
    window.location = CUSTOM_AUTH_URL + `/${clusterPath}` + REDIRECT_URL;
  };

  return (<>
    <ClusterMainComponent>
      <ClusterHeader />
      <section className='login'>
        <h1>로그인</h1>
        <div>
          <div>
            <div>
              <label htmlFor='cluster_id'>
                <p>아이디</p>
                <input
                  type='text'
                  id='cluster_id'
                  placeholder='아이디'
                  value={_id}
                  onInput={(e) => { setID(() => { return e.target.value; }) }}
                />
              </label>
              <label htmlFor='cluster_pw'>
                <p>비밀번호</p>
                <input
                  type='password'
                  id='cluster_pw'
                  placeholder='8자 이상 영문, 숫자, 특수기호'
                  value={_pw}
                  onInput={(e) => { setPW(() => { return e.target.value; }) }}
                />
              </label>
            </div>

            <button onClick={actLogin}>로그인</button>

            <div></div>
            {/* <div>
              <Link to={`${clusterPath}/sign/id/find`}>아이디 찾기</Link>
              <div></div>
              <Link to={`${clusterPath}/sign/pw/find`}>비밀번호 찾기</Link>
              <div></div>
              <Link to={`${clusterPath}/sign/join`}>회원가입</Link>
            </div> */}
          </div>

          <div></div>

          <div>
            <p>다른 로그인 방식</p>
            <button onClick={actGoogleLogin}>구글 로그인</button>
          </div>
        </div>
      </section>
    </ClusterMainComponent>
  </>);
};

export default ClusterSignLogin;