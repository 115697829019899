/* icons */
export const menuButtonIcon = process.env.PUBLIC_URL + '/src/menu/icon-gnball.svg';
export const uploadSection = process.env.PUBLIC_URL + '/src/icons/uploadSection.svg';
export const lastPageButton = process.env.PUBLIC_URL + '/src/icons/lastPageButton.svg';
export const nextPageButton = process.env.PUBLIC_URL + '/src/icons/nextPageButton.svg';
export const zoomMinus = process.env.PUBLIC_URL + '/src/icons/zoomMinus.svg';
export const zoomPlus = process.env.PUBLIC_URL + '/src/icons/zoomPlus.svg';
export const rightArrow = process.env.PUBLIC_URL + '/src/icons/rightArrow.svg';
export const downArrow = process.env.PUBLIC_URL + '/src/icons/downArrow.svg';
export const closeButtonIconUrl = process.env.PUBLIC_URL + '/src/icons/closeButton.svg';
export const barcodeButton = process.env.PUBLIC_URL + '/src/icons/barcodeButton.svg';
export const barcodeButtonIcon = process.env.PUBLIC_URL + '/src/icons/barcodeButton.svg'; // 메뉴
export const refreshButtonIcon = process.env.PUBLIC_URL + '/src/icons/refreshButton.svg';
export const searchButtonIcon = process.env.PUBLIC_URL + '/src/icons/searchButton.svg';
export const docButtonIcon = process.env.PUBLIC_URL + '/src/icons/docButton.svg';
export const securityIcon = process.env.PUBLIC_URL + '/src/icons/securityIcon.svg';
export const rotateRight = process.env.PUBLIC_URL + '/src/icons/rotateRight.svg';
export const bookmarkIcon = process.env.PUBLIC_URL + '/src/icons/star.svg';
export const homeIcon = process.env.PUBLIC_URL + '/src/icons/home.svg';
export const userIcon = process.env.PUBLIC_URL + '/src/icons/user.svg';
export const arrowRightToBracketIcon = process.env.PUBLIC_URL + '/src/icons/arrowRightToBracket.svg';

/* menu */
export const baseInformationIcon = process.env.PUBLIC_URL + '/src/menu/icon-baseInformation.svg';
export const orderIcon = process.env.PUBLIC_URL + '/src/menu/icon-orderManagement.svg';
export const stockIcon = process.env.PUBLIC_URL + '/src/menu/icon-stockManagement.svg';
export const productionIcon = process.env.PUBLIC_URL + '/src/menu/icon-productionManagement.svg';
export const workingIcon = process.env.PUBLIC_URL + '/src/menu/icon-workerScreen.svg';
export const qualityManagementIcon = process.env.PUBLIC_URL + '/src/menu/icon-qualityManagement.svg';
export const monitoringIcon = process.env.PUBLIC_URL + '/src/menu/icon-monitoring.svg';
export const systemIcon = process.env.PUBLIC_URL + '/src/menu/icon-system.svg';

/*  */
export const deleteIcon = process.env.PUBLIC_URL + '/src/icons/delete.svg';
export const editIcon = process.env.PUBLIC_URL + '/src/icons/edit.svg';
export const folderIcon = process.env.PUBLIC_URL + '/src/icons/folder.svg';
export const folderFillIcon = process.env.PUBLIC_URL + '/src/icons/folder_fill.svg';