import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { operatorReducer_setDeleteCompany, operatorReducer_setPageNumber, operatorReducer_setTotalSize, operatorReducer_setUpdateCompany } from 'store/modules/actions/operator/operatorActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { companyApi } from 'api/apis/companyApi';

import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import PagingComponent from 'components/paging/PagingComponent';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  display: grid;
  /* grid-row-start: 1; */
  /* grid-row-end: 3; */
  grid-template-rows: 60px calc(100% - 60px);

  & .TableSection {
    th, td {min-width: unset;}
    td.joinCodeCell {
      cursor: pointer;
      &:hover {background-color: var(--gray-300);}
    }
  }
`;

const CompanyManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operatorReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_companyList, setCompanyList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  /* ========================================================================= #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    getCompanyList(operatorReducer.pageNumber - 1);
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getCompanyList(operatorReducer.pageNumber - 1);
    return () => { };
  }, [operatorReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getCompanyList = async (page) => {
    const paging = `?page=${page}&size=20`;
    await companyApi.getCompanyList(paging).then((response) => {
      if (response === undefined) return;
      console.log('companyApi.getCompanyList : ', response);
      setCompanyList(response.data.content);
      dispatch(operatorReducer_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  const copyJoinCode = (joinCode) => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = joinCode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  }

  const actUpdate = () => {
    const updateContentIndex = _companyList.findIndex((item) => item.companyId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 회사를 선택해 주세요.');
    const updateContent = _companyList[updateContentIndex];
    console.log('updateContent : ', updateContent);
    dispatch(operatorReducer_setUpdateCompany(updateContent));
    navigate(pageReducer.currentPage + '/update', { replace: true });
  };

  const actDelete = () => {
    const deleteContentIndex = _companyList.findIndex((item) => item.companyId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 회사를 선택해 주세요.');
    const deleteContent = _companyList[deleteContentIndex];
    if (deleteContent.companyId === 1) return alert('어드민 회사로 삭제가 불가합니다.');
    dispatch(operatorReducer_setDeleteCompany(deleteContent));
    navigate(pageReducer.currentPage + '/delete', { replace: true });
  };

  /* ========================================================================= #6 */

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={'회사관리'}
            buttons={
              <>
                <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }); }}>추가</button>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>
              </>
            }
            nav={''}
          />

          <TableSection content={
            <table>
              <colgroup>
                <col width={'50px'}></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col></col>
                <col width={'120px'}></col>
                <col width={'120px'}></col>
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>company<br />Id</th>
                  <th>company<br />Name</th>
                  <th>adminCode</th>
                  <th>adminEmail</th>
                  <th>createdDate</th>
                  <th>modifiedDate</th>
                  <th>companyMenu</th>
                  <th>access<br />History</th>
                </tr>
              </thead>
              <tbody>
                {_companyList.map((company, index) => {
                  return (
                    <tr key={index + '_company'}>
                      <td>
                        <input
                          type="checkBox"
                          name="companies"
                          data-key={company.companyId}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedItem(company.companyId, isChecked);
                            if (isChecked) {
                              document.getElementsByName('companies').forEach((thisAttr) => { thisAttr.checked = false });
                              e.target.checked = true;
                            } else {
                              e.target.checked = false;
                            }
                          }}
                          onClick={(e) => { e.stopPropagation() }}
                        />
                      </td>
                      <td>{company.companyId}</td>
                      <td>{company.companyName}</td>
                      <td className='joinCodeCell' onClick={(e) => {e.preventDefault(); copyJoinCode(company.joinCode)}}>{company.joinCode}</td>
                      <td>{company.adminEmail}</td>
                      <td>{company.createdDate}</td>
                      <td>{company.modifiedDate}</td>
                      <td>
                        <button className='btn-inven-1'
                          style={{ minWidth: '100px', padding: '20px', width: '100px' }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate({
                              pathname: pageReducer.currentPage + '/menu',
                              search: `?${createSearchParams({ companyId: company.companyId, companyName: company.companyName })}`,
                              replace: true,
                            });
                          }}
                        >
                          메뉴관리
                        </button>
                      </td>
                      <td>
                        <button className='btn-inven-1'
                          style={{ backgroundColor: 'var(--MainNavy)', minWidth: '100px', padding: '20px', width: '100px' }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate({
                              pathname: pageReducer.currentPage + '/accessHistory',
                              search: `?${createSearchParams({ companyId: company.companyId, companyName: company.companyName })}`,
                              replace: true,
                            });
                          }}
                        >
                          접속현황
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>

        <PagingComponent
          page={operatorReducer.pageNumber}
          count={operatorReducer.totalSize}
          size={20}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(operatorReducer_setPageNumber(page));
          }}
        />
      </>
    }
    />
  );
};

export default CompanyManagement;
