import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { operatorActions_setCompany, operatorReducer_setDeleteCompany, operatorReducer_setTotalSize, operatorReducer_setUpdateCompany } from 'store/modules/actions/operator/operatorActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { cluster } from 'api/apis/operator/cluster';
import { companyApi } from 'api/apis/companyApi';

import { AiOutlineCluster } from "react-icons/ai";
import { CgMenuGridR } from "react-icons/cg";
import { TbHistoryToggle } from "react-icons/tb";
import { CompanyManagementComponent } from './CompanyManagement.style';
import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import ClusterManagement from './cluster/ClusterManagement';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import RegisterCluster from './cluster/RegisterCluster';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const CompanyManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operatorReducer, pageReducer, userReducer } = useSelector((state) => state);
  const { company } = operatorReducer;

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_clusterList, setClusterList] = useState([]);
  const [_companyList, setCompanyList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState({});

  const [_modalStatus, setModalStatus] = useState(false); // 클러스터 관리 모달
  const [_clusterModalStatus, setClusterModalStatus] = useState(false); // 클러스터 모달
  const [_modalContent, setModalContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    getClusterList();
    getCompanyList(company.pageNumber - 1);
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getCompanyList(company.pageNumber - 1);
    return () => { };
  }, [company.pageNumber]);
  useEffect(() => { }, [company]);

  /* ========================================================================= #4 */
  const getClusterList = async () => { // 클러스터 리스트 불러오기
    await cluster.search('', {}).then((response) => {
      if (response === undefined) return;
      setClusterList(() => { return response.data.content });
    })
  };

  const getCompanyList = async (page, clear) => { // 회사 리스트 불러오기
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const body = {};
    if (!clear && checkEmptyNull(company.cluster, false)) body.clusterId = Number(company.cluster);
    if (!clear && checkEmptyNull(company.searchText, false)) body.companyName = company.searchText;
    await companyApi.searchCompanies(paging, body).then((response) => {
      if (response === undefined) return;
      console.log('getCompanyList : ', response);
      setCompanyList(response.data);
      dispatch(operatorReducer_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (content, isChecked) => { // 선택 이벤트
    if (isChecked) setCheckedItem(() => { return content; });
    else setCheckedItem(() => { return {}; });
  };

  const copyJoinCode = (joinCode) => { // 가입코드 복사 이벤트
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = joinCode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  };

  const actUpdate = () => { // 회사 수정
    if (!checkNullObject(_checkedItem, false)) return alert('수정할 회사를 선택해 주세요.');
    const updateContent = _checkedItem;
    dispatch(operatorReducer_setUpdateCompany(updateContent));
    navigate(pageReducer.currentPage + '/update', { replace: true });
  };

  const actDelete = () => { // 회사 삭제
    if (!checkNullObject(_checkedItem, false)) return alert('삭제할 회사를 선택해 주세요.');
    const deleteContent = _checkedItem;
    if (deleteContent.companyId === 1) return alert('어드민 회사로 삭제가 불가합니다.');
    dispatch(operatorReducer_setDeleteCompany(deleteContent));
    navigate(pageReducer.currentPage + '/delete', { replace: true });
  };

  /* ========================================================================= #6 */

  return (<>
    <OperatorBody contents={<>
      <CompanyManagementComponent className="Main">
        <NavBar
          title={
            <div className='Title'>
              <h4>회사관리</h4>
              <div className="SearchSection">
                <div className="ContentCBox">
                  <p>클러스터</p>
                  <select
                    value={company.cluster}
                    onChange={(e) => { dispatch(operatorActions_setCompany({ ...company, cluster: e.target.value })) }}
                  >
                    <option value={''}>클러스터 선택</option>
                    {checkNullArray(_clusterList, []).map((cluster, index) => {
                      return (<option key={index + '_cluster'} value={cluster.clusterId}>{cluster.clusterName}</option>);
                    })}
                  </select>
                </div>
                <div className="ContentCBox">
                  <p>회사이름</p>
                  <input
                    type='text'
                    className='SearchBar detail'
                    placeholder='회사이름...'
                    value={company.searchText}
                    onInput={(e) => { dispatch(operatorActions_setCompany({ ...company, searchText: e.target.value })) }}
                  />
                </div>
                <button data-searchbutton="true" onClick={(e) => {
                  e.preventDefault();
                  dispatch(operatorActions_setCompany({ ...company, pageNumber: 1 })); // 페이지 초기화 => 1
                  getCompanyList(0); // 클러스터 리스트 불러오기
                }}
                ><SearchButtonIcon /></button>
                <button className="ResetButton"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(operatorActions_setCompany({
                      cluster: '',
                      searchText: '',
                      pageNumber: 1,
                      totalSize: 0,
                    })); // 검색 조건 초기화
                    getCompanyList(0, true); // 클러스터 리스트 불러오기
                  }}
                >초기화</button>
              </div>
            </div>
          }
          buttons={
            <>
              <button className='btn-set' onClick={(e) => { e.preventDefault(); setModalStatus(() => { return true }); }}>클러스터 관리</button>
              <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }); }}>추가</button>
              <button className='btn-edit' onClick={actUpdate}>수정</button>
              <button className='btn-delete' onClick={actDelete}>삭제</button>
            </>
          }
          nav={''}
        />

        <TableSection content={
          <table>
            <colgroup>
              <col width={'50px'}></col>
              <col></col><col></col><col></col><col></col><col></col><col></col><col></col>
              <col width={'400px'} />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>companyId</th>
                <th>companyCode</th>
                <th>companyName</th>
                <th>clusterName</th>
                <th>adminCode</th>
                <th>adminEmail</th>
                <th>modifiedDate</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_companyList.map((company, index) => {
                return (
                  <tr key={index + '_company'}>
                    <td>
                      <input
                        type="checkBox"
                        name="companies"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(company, isChecked);
                          if (isChecked) {
                            document.getElementsByName('companies').forEach((item) => { item.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                    <td>{company.companyId}</td>
                    <td>{company.companyCode}</td>
                    <td>{company.companyName}</td>
                    <td>{company.cluster?.clusterName}</td>
                    <td
                      className='joinCodeCell'
                      title='클릭해서 복사'
                      onClick={(e) => { e.preventDefault(); copyJoinCode(company.joinCode) }}
                    >{company.joinCode}</td>
                    <td>{company.adminEmail}</td>
                    <td>{moment(company.modifiedDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>
                      <div>
                        <div></div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setModalContent(() => { return company });
                            setTimeout(setClusterModalStatus(() => { return true }), 1000);
                          }}
                        >
                          <AiOutlineCluster />클러스터
                        </button>
                        <div></div>
                        <button onClick={(e) => { e.preventDefault(); navigate({ pathname: pageReducer.currentPage + '/menu', search: `?${createSearchParams({ company })}`, replace: true }); }}>
                          <CgMenuGridR />메뉴관리
                        </button>
                        <div></div>
                        <button onClick={(e) => { e.preventDefault(); navigate({ pathname: pageReducer.currentPage + '/accessHistory', search: `?${createSearchParams({ company })}`, replace: true }); }}>
                          <TbHistoryToggle />접속현황
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
        ></TableSection>
      </CompanyManagementComponent>
    </>} />

    {_modalStatus ? <>
      <ClusterManagement
        update={() => {
          dispatch(operatorActions_setCompany({ ...company, pageNumber: 1 })); // 페이지 초기화 => 1
          getCompanyList(0); // 클러스터 리스트 불러오기

          // setTimeout(setModalStatus(() => { return false }), 1000); // 모달 닫기
        }}
        open={_modalStatus}
        close={() => { setModalStatus(() => { return false }) }}
      />
    </> : null}

    {_clusterModalStatus ? <>
      <RegisterCluster
        company={_modalContent}
        update={() => {
          dispatch(operatorActions_setCompany({ ...company, pageNumber: 1 })); // 페이지 초기화 => 1
          getCompanyList(0); // 클러스터 리스트 불러오기

          setTimeout(setClusterModalStatus(() => { return false }), 1000); // 모달 닫기
        }}
        open={_clusterModalStatus}
        close={() => { setClusterModalStatus(() => { return false }) }}
      />
    </> : null}
  </>);
};

export default CompanyManagement;
