import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { stockLogApi } from 'api/apis/stockLogApi';
import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectElements from 'pages/element/modal/SelectElements';
import GsSelectElements from 'pages/element/modal/GsSelectElements';
import TableSection from 'components/layouts/table/TableSection';

const MainContents = styled.main`
  box-sizing: border-box;
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto 150px;
  justify-content: unset;
  padding: 0 20px;
  width: 100%;

  .TableSection {
    & td, & th {
      & input[name='amount'] {
        background-color: var(--ThirdBlue);
        box-sizing: border-box;
        color: var(--white);
        font-size: 2em;
        font-weight: 600;
        height: 50%;
        max-width: 250px;
        padding: 10px;
      }
      & textarea {
        border: 1px solid var(--gray-200);
        box-sizing: border-box;
        height: 50%;
        padding: 10px;
      }
    }
  }
`;
const EtcSection = styled.div`
  display: grid;
  grid-gap: 10px;
  & input, & select {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    min-height: 40px;
    padding: 10px;
  }
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed);cursor: pointer;height: 20px;width: 20px;}
`;

const ButtonSection = styled.div`
  display: grid;
  grid-template-rows: 150px auto;
  row-gap: 10px;
  height: 100%;
`;
const Button = styled.div`
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
  &:first-child {background-color: var(--MainNavy);}
  &:last-child {
    padding: 24px;

    &[data-type="In"] {background-color: var(--MainGreen);}
    &[data-type="Put"] {background-color: var(--MainYellow);}
    &[data-type="Out"] {background-color: var(--MainRed);}
  }
`;

const MultiStock = ({type}) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_authorityCode, setAuthorityCode] = useState(() => {
    if(type === 'In') return '302';
    else if(type === 'Put') return '303';
    else if(type === 'Out') return '304';
  })

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_stockPlaceList, setStockPlaceList] = useState([]); // 재고위치
  const [_stockArray, setStockArray] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false); // 물품선택 팝업

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith(_authorityCode) === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    const readAuthority = _authorityCode + '-2';
    
    if (authorityCodes.indexOf(readAuthority) === -1) {
      alert('권한이 없습니다.');
      navigate(pageReducer.currentPage, { replace: true });
      return;
    } else {
      dispatch(pageReducer_getCurrentPage(window.location.pathname));
    }
    getStockPlaces();

    setOnload('loaded');
    return () => { };
  }, []);
  useEffect(() => {
    setAuthorityCode(() => {
      if(type === 'In') return '302';
      else if(type === 'Put') return '303';
      else if(type === 'Out') return '304';
    });
  }, [type]);
  useEffect(() => {}, [_authorityCode]);

  /* ====================================================================== #4 */
  const getStockPlaces = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await stockPlace.searchStockPlace(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      const returnData = [...response.data];
      const etcIndex = returnData.findIndex(item => item.placeName === '기타');
      if (etcIndex !== -1) {
        const etcContent = returnData[etcIndex];
        returnData.splice(etcIndex, 1);
        returnData.unshift(etcContent);
      }
      setStockPlaceList(() => { return returnData });
    });
  }

  /* ====================================================================== #5 */
  const translateStockType = (stockType) => {
    if(type === 'In') return '입고';
    else if(type === 'Put') return '출고';
    else if(type === 'Out') return '출하';
  }

  const handleInputEvent = (e, index, element) => {
    const { name, value } = e.target;
    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0) eventValue = 0;
    const newData = { ...element, [name]: eventValue };
    setStockArray((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1, newData);
      return prevData;
    });
  };

  const actDelete = (index) => {
    setStockArray((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  /* actStock */
  const actStock = async () => {
    if (!checkNullArray(_stockArray, false)) return alert('물품을 추가해주세요.');
    const formData = _stockArray.map((stockElement) => {
      const amount = Number(stockElement.amount);
      if (isNaN(amount) || amount < 1) return null;
      const returnData = {
        companyId: userReducer.company.companyId,
        userId: userReducer.user.id,

        logType: type === 'Put' ? 'Out' : type,
        elementId: stockElement.elementId,
        amount: amount,
        checkedDate: checkEmptyNull(stockElement.checkedDate, false) ? new Date(stockElement.checkedDate).toISOString() : '', // 유통기한
        price: stockElement.price, // 금액
        stockPlaceId: stockElement.stockPlaceId, // 재고위치
        note: stockElement.note, // 비고

        fileFath: '',
        fileName: '',
      };
      return returnData;
    });
    const BodyToPost = formData.filter((thisItem) => thisItem !== null);
    if (!checkNullArray(BodyToPost, false)) return alert('물품을 추가해주세요.');
    console.log('BodyToPost : ', BodyToPost);
    await stockLogApi.createElementStockLogs(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockLogApi.createElementStockLogs : ', response);
      setStockArray(() => { return []; });
      const alertText = `${translateStockType(type)}되었습니다.`;
      alert(alertText);
    });
  };
  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <main className='Main'>
          <NavBar title={<NavTitle menuCode={_authorityCode} />} nav={''} />
          <MainContents>
            <TableSection content={
              <table>
                <thead>
                  <tr>
                    <th>물품코드</th>
                    <th>물품이름</th>
                    <th>현재고</th>
                    <th style={{ color: 'var(--MainRed)' }}>{translateStockType(type)}수량<br /><span style={{ fontSize: '0.7em' }}>*필수값</span></th>
                    <th>
                      {type === 'In' ? (
                        <>추가정보<br />(유통기한/금액/재고위치)</>
                      ) : (
                        <>재고위치</>
                      )}
                    </th>
                    <th>비고</th>
                    <th style={{ minWidth: '50px', width: '50px' }}></th>
                  </tr>
                </thead>

                <tbody>
                  {_stockArray.map((stockElement, index) => {
                    return (
                      <tr key={index + '_stock'}>
                        <td>{stockElement.elementCode}</td>
                        <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>{stockElement.elementName}</td>
                        <td className={checkEmptyNull(stockElement.elementSafeStock, false) && stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock'}>
                          {checkEmptyNull(stockElement.elementStock, 0).toLocaleString()} {stockElement.elementUnit}
                        </td>
                        <td>
                          <input
                            name='amount'
                            type="number"
                            min={0}
                            value={checkEmptyNull(stockElement.amount, '')}
                            onInput={(e) => { handleInputEvent(e, index, stockElement); }}
                          />
                        </td>
                        <td>
                          <EtcSection>
                            {type === 'In' ? (
                              <>
                                <input type='datetime-local'
                                  value={stockElement.checkedDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const checkedDate = DateTimeFormat(checkEmptyNull(e.target.value, ''));
                                    setStockArray(prev => {
                                      const returnData = [...prev];
                                      const newData = { ...stockElement, checkedDate: checkedDate }
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })
                                  }}
                                />
                                <input
                                  type="number"
                                  name='price'
                                  min={0}
                                  value={checkEmptyNull(stockElement.price, '')}
                                  onInput={(e) => { handleInputEvent(e, index, stockElement); }}
                                />
                              </>
                            ) : null}
                            <select
                              value={stockElement.stockPlaceId}
                              onChange={(e) => {
                                e.preventDefault();
                                const stockPlaceId = Number(e.target.value);
                                if (isNaN(stockPlaceId)) return;
                                setStockArray(prev => {
                                  const returnData = [...prev];
                                  const newData = { ...stockElement, stockPlaceId: stockPlaceId }
                                  returnData.splice(index, 1, newData);
                                  return returnData;
                                })
                              }}
                            >
                              <option value={''}>재고위치 선택</option>
                              {checkNullArray(_stockPlaceList, []).map((stockPlace, stockPlaceIndex) => {
                                return (<option key={stockPlaceIndex + '_stockPlace'} value={stockPlace.stockPlaceId}>{stockPlace.placeName}</option>)
                              })}
                            </select>
                          </EtcSection>
                        </td>
                        <td>
                          <textarea
                            name="note"
                            max={1000}
                            placeholder='비고...'
                            value={stockElement.note}
                            onInput={(e) => {
                              e.preventDefault();
                              const note = e.target.value;
                              setStockArray(prev => {
                                const returnData = [...prev];
                                const newData = { ...stockElement, note: note }
                                returnData.splice(index, 1, newData);
                                return returnData;
                              })
                            }}
                          />
                        </td>
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <DelButton onClick={() => { actDelete(index); }}><CloseButtonIcon /></DelButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }></TableSection>

            <ButtonSection>
              <Button onClick={(e) => { e.preventDefault(); setModalStatus(true); }}>물품<br />추가</Button>
              <Button data-type={type} onClick={(e) => { e.preventDefault(); actStock(); }}>다중<br />{translateStockType(type)}</Button>
            </ButtonSection>
          </MainContents>
        </main>

        {_modalStatus === true && userReducer.company.companyId === 3 ? (
          <GsSelectElements
            buttonEvent={(data) => {
              console.log('data : ', data);
              const setStockPlace = [...data].map(stockElement => {
                return { ...stockElement, stockPlaceId: _stockPlaceList[0]?.stockPlaceId }
              })
              setStockArray((prev) => {
                const returnData = [...prev, ...setStockPlace];
                return returnData;
              })
              setModalStatus(false);
            }}
            buttonTitle="물품 선택"
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        ) : (
          <SelectElements
            buttonEvent={(data) => {
              console.log('data : ', data);
              const setStockPlace = [...data].map(stockElement => {
                return { ...stockElement, stockPlaceId: _stockPlaceList[0]?.stockPlaceId }
              })
              setStockArray((prev) => {
                const returnData = [...prev, ...setStockPlace];
                return returnData;
              })
              setModalStatus(false);
            }}
            buttonTitle="물품 선택"
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        )}
      </>
    } />
  );
};

export default MultiStock;
