import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { kpi } from 'api/apis/kpi/kpi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

import KPIGraph from 'pages/kpi/contents/KPIGraph';
import KPITable from 'pages/kpi/contents/KPITable';

const Main = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);

  & .search-select {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 40px;
    padding: 4px 20px;
    position: relative;
    width: 120px;

    &.year::after {
      content: '년';

      background-color: var(--white);
      color: var(--Text);
      position: absolute;
      right: 5px;
      height: 40px;
      width: 40px;
    }
  }

  .btn-set {
    &.active {
      background-color: var(--MainBlue);
      color: var(--white);
      &:hover {background-color: var(--MainBlue);}
    }
  }
  .btn-search {padding-inline: 16px;}
  .ResetButton {
    box-shadow: none;
    &:hover {background: none;}
  }

  .contents {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    height: 100%;
    padding: 40px;
    width: 100%;
    
    .contentWrap {
      background-color: var(--white);
      border-radius: 20px;
      box-sizing: border-box;
      display: grid;
      gap: 1%;
      grid-template-columns: 69% 30%;
      height: 100%;
      padding: 20px;
      width: 100%;

      .section-table {
        display: grid;
        grid-gap: 10px;
        height: 100%;
        overflow-y: hidden;
      }

      .TableSection {
        height: 100%;
        
        table {
          td, th {
            border-color: var(--gray-200);
            padding-block: 6px;
          }
          th {
            background-color: var(--MainNavy);
            color: var(--white);
          }
        }
      }
    }
  }
`;

const KPIMonitoring = () => {
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  const [_kpiYear, setKpiYear] = useState(moment().year());
  const [_kpiUnits, setKpiUnits] = useState([]); // KPI 유형 리스트
  const [_kpiUnit, setKpiUnit] = useState({}); // KPI 유형

  const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
  const [_month, setMonth] = useState('01');

  const [_kpiDataList, setKpiDataList] = useState([]);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getKpiUnits(_kpiYear);

    return () => { }
  }, [])
  useEffect(() => { }, [_kpiYear, _kpiUnits, _kpiUnit, _kpiDataList, _month]);

  const getKpiUnits = async (year) => { // KPI 유형 리스트 불러오기
    // 로컬에서 모헨에서 2024-1 로 조회하면 확인할 수 있음
    const body = { companyId: userReducer.company.companyId, type: "shop", year: Number(year) }
    await kpi.searchKpiUnit(body).then(response => {
      if (response === undefined) return;
      console.log('kpi.searchKpiUnit : ', response);
      const returnData = response.data;
      setKpiUnits(() => { return returnData });
    })
  }
  const getKpiData = async (kpiUnit, month) => { // KPI 데이터 불러오기
    if (!checkEmptyNull(month, false)) return;

    const fromDate = `${_kpiYear}-${month}-01`
    const toDate = moment(fromDate).endOf('month').format('YYYY-MM-DD');
    // const fromDate = moment(_month).startOf('MONTH').format('YYYY-MM-DD');
    // const toDate = moment(_month).endOf('MONTH').format('YYYY-MM-DD');

    const body = {
      fromDate: fromDate,
      toDate: toDate,
      kpiUnitId: kpiUnit.id
    }
    await kpi.searchKpiData(body).then(response => {
      if (response === undefined) return;
      console.log('kpi.searchKpiData : ', response);
      setKpiDataList(() => { return response.data });
    })
  }

  const actSearch = () => { // 검색
    if (!checkEmptyNull(_month, false)) return alert('조회할 기간을 선택해 주세요.');
    getKpiData(_kpiUnit, _month);
  }
  const actReset = () => { // 초기화
    setKpiYear(() => { return ''; });
    setKpiUnits(() => { return []; });
    setKpiUnit(() => { return {}; });
    setMonth(() => { return '01'; });
    setKpiDataList(() => { return []; });
  }

  return (
    <Grid2Body contents={
      <Main>
        <NavBar
          title={<><NavTitle menuCode={'706'} /></>}
          buttons={<>
            <div className="SearchSection">
              <div className="SearchSection">
                <input
                  type='text'
                  className='search-select year'
                  // placeholder='조회할 연도 입력'
                  value={_kpiYear}
                  onChange={(e) => {
                    const year = e.target.value;
                    setKpiYear(() => { return year; });
                    if (year.length >= 4) {
                      setKpiUnits(() => { return []; });
                      setKpiUnit(() => { return {}; });
                      setMonth(() => { return '01'; });
                      setKpiDataList(() => { return []; });

                      getKpiUnits(year);
                    }
                  }}
                />
                <span>년</span>
              </div>

              {(checkEmptyNull(_kpiYear, false) && checkNullArray(_kpiUnits, false)) ? (<>
                <div className="SearchSection">
                  <select
                    className='SelectType'
                    style={{ marginInlineEnd: '10px', width: '180px' }}
                    value={_kpiUnit.id || ''}
                    onChange={(e) => {
                      const unitId = e.target.value * 1;
                      const unitData = _kpiUnits.find(item => item.id === unitId);
                      setKpiUnit(() => { return unitData || {} });
                      if (checkEmptyNull(e.target.value, false)) getKpiData(unitData, _month);
                    }}
                  >
                    <option value={''}>KPI 유형 선택</option>
                    {checkNullArray(_kpiUnits, []).map((unit, index) => {
                      if (checkNullArray(unit.kpiDataDtoList, false)) {
                        return (<option key={index + '_unit'} value={unit.id}>{unit.kpiCode.kpiDtlNm}</option>);
                      } else return null;
                    })}
                  </select>
                </div>

                {checkEmptyNull(_kpiUnit?.id, false) ? (
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select
                        className='search-select '
                        value={_month}
                        onChange={(e) => {
                          const month = e.target.value;
                          setMonth(() => { return e.target.value; })
                          getKpiData(_kpiUnit, month);
                        }}
                      >
                        {months.map((month, index) => {
                          return (<option key={index + '_month'} value={month}>{index + 1}월</option>);
                        })}
                      </select>
                      {/* <input
                        type={'MONTH'}
                        className='SelectDate'
                        min={moment(_month).startOf('MONTH').format('YYYY-MM-DD')}
                        max={moment(_month).endOf('MONTH').format('YYYY-MM-DD')}
                        value={_month}
                        onChange={(e) => { setMonth(() => { return e.target.value; }) }}
                      /> */}
                    </div>

                    <div className='ContentCBox'>
                      {/* <button className='btn-search' onClick={(e) => { e.preventDefault(); actSearch(); }}>검색<SearchButtonIcon /></button> */}
                      <button className='ResetButton' onClick={(e) => { e.preventDefault(); actReset(); }}>초기화</button>
                    </div>
                  </div>
                ) : null}
              </>) : null}
            </div>
          </>}
          nav={''}
        />

        <div className='contents'>
          <div className='contentWrap'>
            {checkEmptyNull(_kpiUnit?.id, false) ? (
              <>
                <KPIGraph unit={_kpiUnit} contents={_kpiDataList} />
                <KPITable unit={_kpiUnit} contents={_kpiDataList} />
              </>
            ) : null}
          </div>
        </div>
      </Main>
    }
    />
  );
};

export default KPIMonitoring;