import { AxiosInterceptor } from 'api/core';

export const workOrderTypeApi = {
  searchWorkOrderType: (body) =>
    AxiosInterceptor.post('workOrderType/search', body),
  createWorkOrderType: (body) => AxiosInterceptor.post('workOrderType', body),
  updateWorkOrderType: (workOrderTypeId, body) =>
    AxiosInterceptor.put('workOrderType/' + workOrderTypeId, body),
  deleteWorkOrderType: (workOrderTypeId) =>
    AxiosInterceptor.delete('workOrderType/' + workOrderTypeId),
};
