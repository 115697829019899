import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';
import { numpadActions_setCurrentPageNum, numpadActions_setTotalSize } from 'store/modules/actions/common/numpadActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { elementApi } from 'api/apis/elementApi';
import { elementStockLogApi } from 'api/apis/elementStockLogApi';
import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import PagingComponent from 'components/paging/PagingComponent';
import RefreshButtonIcon from 'components/icons/RefreshButtonIcon';
import RemarkModal from 'pages/stock/numpad/modal/RemarkModal';
import SelectFiles from 'pages/file/modal/SelectFiles';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: flex-start;
  overflow: hidden;
  width: 95%;

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  ${(props) => {
    return props.mobile !== true ? null : 'width: 90%';
  }}
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: calc(100% - 55px);
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  @media screen and (max-width: 600px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
  }

  ${(props) => {
    return props.mobile !== true ? null : 'align-items: center; flex-direction: column; justify-content: center; row-gap: 10px;';
  }}
`;

const Left = styled.div`
  display: grid;
  grid-template-rows: auto 40px;
  height: 100%;
  overflow-x: auto;
  row-gap: 10px;
  width: 40%;

  @media screen and (max-width: 600px) {
    height: 100%;
    max-height: 200px;
    min-width: unset;
    width: 100%;
    zoom: 80%;
  }

  ${(props) => {
    return props.mobile !== true ? null : 'height: 100%; max-height: 200px; min-width: unset; width: 100%; zoom: 80%;';
  }}
`;

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
  row-gap: 10px;
  width: 20%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  @media screen and (max-width: 600px) {
    align-items: unset;
    display: grid;
    flex-direction: unset;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    height: 100%;
    justify-content: unset;
    max-height: 200px;
    min-width: unset;
    width: 100%;
    zoom: 80%;
  }

  ${(props) => {
    return props.mobile !== true ? null : `align-items: unset; display: grid; flex-direction: unset; grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); height: 100%; justify-content: unset; max-height: 200px; min-width: unset; width: 100%; zoom: 80%;`;
  }}
`;
const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: flex-start;
  row-gap: 10px;
  margin-bottom: 20px;
  width: 100%;

  & input, & select, & textarea{
    border: 2px solid var(--MainNavy);
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1em;
    height: 50px;
    padding: 5px;
    width: 95%;
  }
`;
const ContentTitle = styled.h5`
  box-sizing: border-box;
  display: block;
  padding-left: 10px;
  width: 100%;
`;
const ContentTitleBox = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 0px 10px;
  position: relative;
  width: 100%;
`;
const RefreshButton = styled.div`
  height: 25px;
  position: absolute;
  right: 5px;
  top: 2px;
  width: 25px;
`;
const ContentBox = styled.div`
  border: 2px solid var(--gray-100);
  box-sizing: border-box;
  font-size: 1.2em;
  height: max-content;
  padding: 10px;
  word-break: break-all;
  width: 95%;

  background-color: ${(props) => {
    switch (props.stockStatus) {
      case 'safeStock': return 'var(--MainGreen)';
      case 'dangerStock': return 'var(--MainRed)';
      default: return 'unset';
    }
  }};
  color: ${(props) => {
    switch (props.stockStatus) {
      case 'safeStock': return 'var(--white)';
      case 'dangerStock': return 'var(--white)';
      default: return 'unset';
    }
  }};

  & p {font-size: 0.8em;}
`;

const FileList = styled.div`
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  font-size: 0.6em;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteFileButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;

  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const Right = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-width: 400px;
  overflow: auto;
  row-gap: 10px;
  width: 40%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  @media screen and (max-width: 600px) {
    height: 100%;
    min-width: unset;
    width: 100%;
  }

  ${(props) => {
    return props.mobile !== true ? null : 'height: 100%; min-width: unset; width: 100%;';
  }}
`;
const RightTop = styled.div`
  align-items: flex-start;
  column-gap: 10px;
  display: flex;
  height: calc(100% - 100px);
  justify-content: space-between;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  @media screen and (max-width: 600px) {
    height: 100%;
    min-width: unset;
    width: 100%;
  }

  ${(props) => {
    return props.mobile !== true ? null : 'height: 100%; min-width: unset; width: 100%;';
  }}
`;

const StockButton = styled.button`
  background: unset;
  background-color: ${(props) => {
    switch (props.type) {
      case '입고': return 'var(--MainGreen)';
      case '차감': return 'var(--MainYellow)';
      case '출하': return 'var(--MainRed)';
      case '출고': return 'var(--MainRed)';
      default: return 'unset';
    }
  }};
  border: unset;
  color: var(--white);
  font-size: 45px;
  font-weight: bolder;
  height: 100px;
  width: 100%;

  &:hover {
    background: unset;
    background-color: ${(props) => {
    switch (props.type) {
      case '입고': return 'var(--MainGreen)';
      case '차감': return 'var(--MainYellow)';
      case '출하': return 'var(--MainRed)';
      case '출고': return 'var(--MainRed)';
      default: return 'unset';
    }
  }};
  }
`;

const NumpadSection = styled.div`
  display: grid;
  grid-template-rows: 120px auto;
  height: 100%;
  row-gap: 10px;
  width: 100%;
`;
const NumpadDisplay = styled.div`
  column-gap: 5px;
  display: grid;
  grid-template-columns: auto minmax(30px, 60px);
  height: 100%;
  justify-content: flex-end;
  outline: 4px solid ${(props) => {
    switch (props.stockStatus) {
      case 'safeStock': return 'var(--MainGreen)';
      case 'dangerStock': return 'var(--MainRed)';
      default: return 'unset';
    }
  }};
  outline-offset: -4px;
  position: relative;
  width: 100%;
`;
const NumSection = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 10px;
  text-align: right;
  width: 100%;

  input, p {font-size: 60px; font-weight: 600; text-align: right; width: 100%;}
`;
const UnitSection = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  font-size: 20px;
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 20px;
  width: 100%;
`;

const NumButtons = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 100%;
  width: 100%;
`;
const NumButton = styled.button`
  color: var(--gray-700);
  font-size: 50px;
  font-weight: 600;
  min-height: 57px;
  height: 100%;
  width: 100%;
`;

const EctButtonsSection = styled.div`
  display: grid;
  grid-template-rows: 120px repeat(4, 1fr);
  height: 100%;
  row-gap: 10px;
  width: 110px;

  button {margin: 0px auto;}
`;
const EctButton = styled.button`
  background: unset;
  background-color: var(--MainBlue);
  border: unset;
  color: var(--white);
  font-weight: 600;
  height: 100%;
  min-height: 57px;
  width: 80px;
  &:hover {background: unset; background-color: var(--MainBlue);}
`;
const ACButton = styled.button`
  background: unset;
  background-color: var(--MainNavy);
  border: unset;
  color: var(--white);
  font-weight: 600;
  height: 100%;
  min-height: 57px;
  width: 80px;
  &:hover {background: unset; background-color: var(--MainNavy);}
`;
const AmountCell = styled.td`
  &.In {background-color: var(--MainGreen)}
  &.Put {background-color: var(--MainYellow)}
  &.Make {background-color: var(--MainBlue)}
  &.Out {background-color: var(--MainRed)}
  color: var(--white);
  min-width: 150px;
  width: 150px;
`;

// 유통기한 버튼 스타일
const CheckedDateBtnSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;

  button {margin: 0px auto;}
`;



const MobileNumpad = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { numpadReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { type, content } = props;

  const baseDate = new Date();
  const dayStart = moment().utc(baseDate).startOf('day');
  const dayStartTime = dayStart.toISOString();
  const dayEnd = moment().utc(baseDate).endOf('day');
  const dayEndTime = dayEnd.toISOString();

  const baseDate2 = new Date();
  baseDate2.setHours(baseDate2.getHours() + 9);
  const currentTime = baseDate2.toISOString();

  const NumDisplay = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_logList, setLogList] = useState([]);

  const [_currentStock, setCurrentStock] = useState(content.elementStock);
  const [_displayNum, setDisplayNum] = useState('0');

  const [_checkedDate, setCheckedDate] = useState(''); // 유통기한

  const [_stockPrice, setStockPrice] = useState(0); // 금액

  const [_stockPlaceList, setStockPlaceList] = useState([]);
  const [_selectedStockPlace, setSelectedStockPlace] = useState(''); // 재고위치

  const [_remark, setRemark] = useState(''); // 비고
  const [_remarkModal, setRemarkModal] = useState(false);

  const [_fileList, setFileList] = useState([]);
  const [_fileModalStatus, setFileModalStatus] = useState(false); // 파일선택 팝업

  const [_eventcontent, setEventContent] = useState({});
  const [_fileListModalStatus, setFileListModalStatus] = useState(false); // 로그 파일 팝업

  const [_mobile, setMobile] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('109') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    if (mobile()) setMobile(true);
    else setMobile(false);

    dispatch(numpadActions_setCurrentPageNum(1));
    getElementData();
    getStockLogs(0);
    getStockPlaceList();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getStockLogs(numpadReducer.currentPageNum - 1);
    }
  }, [numpadReducer.currentPageNum]);

  useEffect(() => { }, [_displayNum]);

  /* ====================================================================== #4 */
  const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const getElementData = async() => {
    await elementApi.getElement(content.elementId).then(response => {
      if(response === undefined) return;
      console.log('elementApi.getElement : ', response);
      setStockPrice(() => {return checkEmptyNull(response.data.price, 0)});
    })
  }
  const getStockLogs = async (page) => {
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: content.elementId,
      elementStockLogStartDate: dayStartTime,
      elementStockLogEndDate: dayEndTime,
    };
    console.log('getStockLogs - BodyToPost : ', BodyToPost);

    await elementStockLogApi.searchElementStockLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementStockLogApi.searchElementStockLog : ', response);
      setLogList(() => { return response.data.content; });
      dispatch(numpadActions_setTotalSize(response.data.totalElements));
    });
  };
  const getStockPlaceList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId
    }
    await stockPlace.searchStockPlace(BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      const returnData = [...response.data];
      const etcIndex = returnData.findIndex(item => item.placeName === '기타');
      if (etcIndex !== -1) {
        const etcContent = returnData[etcIndex];
        returnData.splice(etcIndex, 1);
        returnData.unshift(etcContent);
      }
      setStockPlaceList(() => { return returnData });
      if (checkNullArray(returnData, false)) {
        setSelectedStockPlace(() => { return returnData[0].stockPlaceId });
      }
    })
  }

  const setlogTypeText = (logType) => {
    switch (logType) {
      case 'In': return '입고';
      case 'Put': return '출고';
      case 'Out': return '출고';
      default: return '';
    }
  };
  const handleLogTypeName = (logType) => {
    switch (logType) {
      case 'In': return '입고';
      case 'Put': return '투입';
      case 'Make': return '생산';
      case 'Out': return '출고';
      default: return '';
    }
  }

  /* ====================================================================== #5 */
  const handleDisplayNum = (num) => {
    const currentNum = _displayNum;

    if (currentNum.length >= 15) return;
    if (num === '.' && currentNum.indexOf('.') !== -1) return; // '.' 1개 이상 막기
    if (num === '00' && currentNum === '0') return; // '00'으로 시작 막기

    let resultNum = currentNum + num;
    if (resultNum.startsWith('0.') !== true && resultNum.length === 2 && resultNum.slice(0, 1) === '0') {
      resultNum = resultNum.replace('0', ''); // 첫 숫자 0 막기
    }
    console.log('resultNum : ', resultNum);
    setDisplayNum(() => { return resultNum }); // '.' 인 경우 그 다음 숫자(0 제외)를 클릭해야 '.' 이 같이 표시됨
  };

  const handleStockAct = async () => {
    const displayAmount = NumDisplay.current.querySelector('input').value;
    const amount = Number(displayAmount.replace(/[^0-9\.]/g, ''));
    if (isNaN(amount)) return alert('숫자만 입력 가능합니다.');
    if (amount <= 0) return;

    let logType;
    if (type === 'In') {
      logType = 'In';
    } else {
      if (content.elementType !== 'product') logType = 'Put';
      else logType = 'Out';
    }

    if (logType !== 'In' && BigNumber(_currentStock).minus(BigNumber(amount)).toNumber() < 0) return alert('재고가 부족합니다.');

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      elementId: content.elementId,
      logType: logType === 'In' ? 'In' : 'Out',
      amount: amount,
      price: checkEmptyNull(_stockPrice, 0),
      stockPlaceId: checkEmptyNull(_selectedStockPlace, null),
      note: _remark,
      fileFath: '',
      fileName: '',
    };
    if (checkEmptyNull(_checkedDate, false)) {
      const checkedDate = new Date(_checkedDate);
      checkedDate.setHours(checkedDate.getHours() + 9);
      BodyToPost.checkedDate = checkedDate.toISOString(); // 유통기한
    }
    console.log('handleStockAct - BodyToPost : ', BodyToPost);

    await elementStockLogApi.createElementStockLog(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementStockLogApi.createElementStockLog : ', response);
      setLogList([response.data, ..._logList]);
      if (response.data.logType === 'In') setCurrentStock((prev) => parseFloat(prev + response.data.amount));
      else setCurrentStock((prev) => parseFloat(prev - response.data.amount));
      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            elementStockLogId: response.data.elementStockLogId,
          };
          console.log('BodyToFilePost : ', BodyToFilePost);
          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }
      alert(`재고를 ${setlogTypeText(type)}했습니다.`);
    }).finally(() => {
      actClear();
    });
  };

  // 유통기한 시간 추가
  const updateDateTime = (type) => {
    setCheckedDate(prevDate => {
      const currentDate = prevDate ? new Date(prevDate) : new Date();
      switch (type) {
        case 'day':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case 'year':
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          break;
        default:
          break;
      }

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      
      const formattedTime = `${hours}:${minutes}`;
      const formattedDate = `${year}-${month}-${day} ${formattedTime}`;
      
      return formattedDate;
    });
  };
  // --유통기한 시간 추가

  const actClear = () => {
    setDisplayNum(() => { return '0' }); // 초기화
    // setCheckedDate(() => { return '' });
    // setStockPrice(() => { return 0 });
    // setSelectedStockPlace(() => { return '' });
    setRemark(() => { return '' });
    setFileList(() => { return [] });
  };

  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const showFileList = (content, work) => {
    setEventContent(() => {
      if (work) return { ...content, work: true };
      else return content;
    });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection mobile={_mobile}>
            <ModalHeader>
              <ModalTitle>{`${content.elementName} ( ${content.elementCode} )`}</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain mobile={_mobile}>
              <Left mobile={_mobile}>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? <th>파일</th> : null}
                        <th>입력 시각</th>
                        <th style={{ minWidth: '150px', width: '150px' }}>수량</th>
                        <th>유통기한</th>
                        <th>금액</th>
                        <th>재고 위치</th>
                        <th>비고</th>
                        <th>작업자</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_logList.map((thisItem) => {
                        return (
                          <tr key={thisItem.elementStockLogId}>
                            {(() => {
                              if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                                if (checkNullArray(thisItem.attachFileList, false)) {
                                  return (
                                    <td className='table-preview'>
                                      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                        onClick={(e) => { e.stopPropagation(); showFileList(thisItem, true); }}
                                      >
                                        <img src={thisItem.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                        <div className='btn-file'><DOCButtonIcon /></div>
                                      </div>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td className='table-preview'>
                                      <div
                                        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                        onClick={(e) => { e.stopPropagation(); showFileList(thisItem, true); }}
                                      >
                                        <div className='no-image'><p>이미지가 없습니다.</p></div>
                                        <div className='btn-file'><DOCButtonIcon /></div>
                                      </div>
                                    </td>
                                  );
                                }
                              }
                            })()}
                            <td>{checkEmptyNull(thisItem.createdDate, false) ? DateTimeFormat(thisItem.createdDate) : ''}</td>
                            <AmountCell className={thisItem.logType}>
                              {handleLogTypeName(thisItem.logType) + ' ' + checkEmptyNull(thisItem.amount, 0).toLocaleString() + ' ' + checkEmptyNull(thisItem.elementUnit, '')}
                            </AmountCell>
                            <td>{checkEmptyNull(thisItem.checkedDate, false) ? DateTimeFormat(thisItem.checkedDate) : ''}</td>
                            <td>{checkEmptyNull(thisItem.price, false) ? !isNaN(Number(thisItem.price)) && Number(thisItem.price).toLocaleString() : ''}</td>
                            <td>{checkEmptyNull(thisItem.stockPlaceName, '')}</td>
                            <td>{thisItem.note}</td>
                            <td>{checkEmptyNull(thisItem.userName, '')}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                ></TableSection>

                <PagingComponent
                  page={numpadReducer.currentPageNum}
                  count={numpadReducer.totalSize}
                  size={20}
                  pageEvent={(page) => {
                    console.log('page : ', page);
                    dispatch(numpadActions_setCurrentPageNum(page));
                  }}
                />
              </Left>

              <Center mobile={_mobile}>
                <Content>
                  <ContentTitle>안전재고</ContentTitle>
                  <ContentBox>{(checkEmptyNull(content.elementSafeStock, 0) * 1).toLocaleString()}</ContentBox>
                </Content>
                <Content>
                  <ContentTitle>현재고</ContentTitle>
                  <ContentBox stockStatus={checkEmptyNull(content.elementSafeStock, false) ? content.elementSafeStock <= content.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                    {(checkEmptyNull(_currentStock, 0) * 1).toLocaleString()}
                  </ContentBox>
                </Content>
                {type === 'In' ? (
                  <Content>
                    <ContentTitle>유통기한</ContentTitle>
                    <input
                      type="datetime-local"
                      name='checkedDate'
                      value={_checkedDate}
                      onChange={(e) => {
                        e.preventDefault();
                        const checkedDate = e.target.value;
                        if (!checkEmptyNull(checkedDate, false)) return;
                        setCheckedDate(() => { return DateTimeFormat(checkedDate) })
                      }}
                    />
                  </Content>
                ) : null}

                {/* 유통기한시간추가버튼 */}
                <Content
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  }}
                >
                  <EctButton onClick={() => updateDateTime('day')}>+일</EctButton>
                  <EctButton onClick={() => updateDateTime('week')}>+주</EctButton>
                  <EctButton onClick={() => updateDateTime('month')}>+월</EctButton>
                  <EctButton onClick={() => updateDateTime('year')}>+년</EctButton>
                </Content>
                {/* --유통기한시간추가버튼 */}
                
                {['In', 'Out'].includes(type) ?
                  <Content>
                    <ContentTitle>금액</ContentTitle>
                    <input
                      type="text"
                      name='price'
                      value={checkEmptyNull(_stockPrice, 0).toLocaleString()}
                      onInput={(e) => {
                        e.preventDefault();
                        let eventValue = e.target.value.replace(/[^\d.-]/g, ''); // 바뀐 생산목표수량
                        if (eventValue.length > 15) return;
                        if (!checkEmptyNull(eventValue, false)) eventValue = 0;
                        if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
                        eventValue = BigNumber(eventValue).toNumber();
                        console.log('eventValue : ', eventValue);
                        setStockPrice(() => { return eventValue });
                      }}
                    />
                  </Content> : null}
                <Content>
                  <ContentTitle>재고 위치</ContentTitle>
                  <select
                    name='stockPlace'
                    value={_selectedStockPlace}
                    onChange={(e) => {
                      e.preventDefault();
                      const stockPlace = e.target.value;
                      if (!checkEmptyNull(stockPlace, false)) return setSelectedStockPlace(() => { return '' });
                      setSelectedStockPlace(() => { return Number(stockPlace) })
                    }}
                  >
                    {checkNullArray(_stockPlaceList, []).map((option, index) => {
                      return <option key={index + '_stockPlaces'} value={option.stockPlaceId}>{option.placeName}</option>
                    })}
                  </select>
                </Content>
                <Content>
                  <ContentTitleBox>
                    <h5>비고</h5>
                    {/* <RefreshButton onClick={() => { setRemark(() => { return '' }) }}><RefreshButtonIcon /></RefreshButton> */}
                  </ContentTitleBox>
                  <textarea
                  name='note'
                  maxLength={1000}
                  value={_remark}
                  onInput={(e) => {
                    if (e.target.value.length > 1000) return;
                    setRemark(() => {
                      return e.target.value;
                    });
                  }}
                ></textarea>
                  {/* <ContentBox>{_remark}</ContentBox> */}
                </Content>
                {(() => {
                  if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                    return (
                      <Content>
                        <ContentTitleBox>
                          <h5>파일</h5>
                          <RefreshButton onClick={() => { setFileList(() => { return [] }) }}><RefreshButtonIcon /></RefreshButton>
                        </ContentTitleBox>
                        <ContentBox>
                          <FileList>
                            {_fileList.map((file, index) => {
                              return (
                                <FileBox key={index + '_files'}>
                                  {file.fileDataName}
                                  <DeleteFileButton onClick={(e) => { actDeleteFile(e, index, file) }}><CloseButtonIcon /></DeleteFileButton>
                                </FileBox>
                              );
                            })}
                          </FileList>
                        </ContentBox>
                      </Content>
                    );
                  } else {
                    return null;
                  }
                })()}
              </Center>

              <Right mobile={_mobile}>
                <RightTop mobile={_mobile}>
                  <NumpadSection>
                    <NumpadDisplay stockStatus={checkEmptyNull(content.elementSafeStock, false) ? content.elementSafeStock <= content.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                      <NumSection ref={NumDisplay}>
                        {(() => {
                          if (_displayNum === '0.') {
                            return <p>{_displayNum}</p>;
                          } else {
                            return <CommaNum customAttr={'readonly'} displayType={'input'} num={_displayNum} />;
                          }
                        })()}
                      </NumSection>
                      <UnitSection>{content.elementUnit}</UnitSection>
                    </NumpadDisplay>

                    <NumButtons>
                      <NumButton onClick={() => { handleDisplayNum('1') }}>1</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('2') }}>2</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('3') }}>3</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('4') }}>4</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('5') }}>5</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('6') }}>6</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('7') }}>7</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('8') }}>8</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('9') }}>9</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('00') }}>00</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('0') }}>0</NumButton>
                      <NumButton onClick={() => { handleDisplayNum('.') }}>.</NumButton>
                    </NumButtons>
                  </NumpadSection>

                  <EctButtonsSection>
                    <ACButton onClick={() => { setDisplayNum(() => { return '0' }) }}>AC</ACButton>
                    {/* <EctButton onClick={() => { setRemarkModal(true) }}>비고</EctButton> */}
                    {(() => {
                      if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                        return (
                          <EctButton
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(modalFileActions_setFileReset());
                              setTimeout(setFileModalStatus(true), 1000);
                            }}
                          >
                            파일
                          </EctButton>
                        );
                      } else {
                        return null;
                      }
                    })()}
                  </EctButtonsSection>
                </RightTop>

                <StockButton type={setlogTypeText(type)} onClick={handleStockAct}>{setlogTypeText(type)}</StockButton>
              </Right>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_remarkModal === true && (
        <RemarkModal
          remark={_remark}
          setRemark={setRemark}
          open={_remarkModal}
          close={() => { setRemarkModal(false) }}
        ></RemarkModal>
      )}

      {_fileModalStatus === true && (
        <SelectFiles
          buttonTitle={'파일 선택'}
          buttonEvent={(data) => {
            const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
            setFileList((prev) => { return [...prev, ...newDataList] });
            setTimeout(setFileModalStatus(false), 1000);
          }}
          open={_fileModalStatus}
          close={() => { setFileModalStatus(false) }}
        />
      )}

      {_fileListModalStatus === true && _eventcontent.elementStockLogId !== undefined ? (
        <ShowFileList
          type={'elementStockLog'}
          content={_eventcontent}
          open={_fileListModalStatus}
          close={() => { setFileListModalStatus(false) }}
        ></ShowFileList>
      ) : null}
    </>
  );
};

export default MobileNumpad;
