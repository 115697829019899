import React, { useState } from 'react';
import styled from 'styled-components';

import { shipmentApi } from 'api/apis/shipmentApi';

import { checkNullParse } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 300px;
  max-width: 300px;
  min-width: 200px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  justify-content: flex-start;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
`;

const CheckSection = styled.div`
  height: fit-content;
  width: 100%;

  input {
    display: inline;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
  label {
    cursor: pointer;
    margin-left: 10px;
    p {color: var(--MainRed); font-size: 0.8em;}
  }
`;

const ShipmentButton = styled.div`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const ShipmentModal = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const { shipment } = props;
  const [_stockFlag, setStockFlag] = useState(false);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actShipment = async () => {
    const confirmText = `출하하시겠습니까?`;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...shipment,
        customizedContent: JSON.stringify({
          ...checkNullParse(shipment.customizedContent, {}),
          shipmentStatus: 'end',
        }),
        shipmentStatus: 'end',
        stockFlag: _stockFlag,
      };
      console.log('BodyToPut : ', BodyToPut);

      await shipmentApi.updateShipment(shipment.shipmentId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('shipmentApi.updateShipment : ', response);

        alert('출하했습니다.');
        props.close(response.data);
      });
    } else {
      return props.close();
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>출하</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <CheckSection>
                <input
                  type="checkBox"
                  id="stockCheckBox"
                  checked={_stockFlag}
                  onChange={(e) => {
                    e.preventDefault();
                    setStockFlag((prev) => {return !prev;});
                  }}
                />
                <label htmlFor="stockCheckBox">
                  출하물품 재고 처리
                  <p style={{fontSize: '0.8em', paddingLeft: '30px'}}>
                    체크하면 출하 시 출하수량만큼 <br />재고가 감소합니다.
                  </p>
                </label>
              </CheckSection>
              <ShipmentButton onClick={actShipment}>출하</ShipmentButton>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ShipmentModal;
