import { AxiosInterceptor } from 'api/core';

export const elementAttributeApi = {
  searchElementAttribute: (body) =>
    AxiosInterceptor.post('elementAttribute/search', body),
  createElementAttribute: (body) =>
    AxiosInterceptor.post('elementAttribute', body),
  updateElementAttribute: (elementAttributeId, body) =>
    AxiosInterceptor.put('elementAttribute/' + elementAttributeId, body),
  deleteElementAttribute: (elementAttributeId) =>
    AxiosInterceptor.delete('elementAttribute/' + elementAttributeId),
};
