import { AxiosInterceptor } from 'api/core';

export const manHour = {
  getWorkingTime: (workingTimeId) => AxiosInterceptor.get('workingTime/' + workingTimeId),
  searchWorkingTime: (body) => AxiosInterceptor.post('workingTime/search?page=0&size=7000', body),
  createhWorkingTime: (body) => AxiosInterceptor.post('workingTime', body),
  updateWorkingTime: (workingTimeId, body) => AxiosInterceptor.put('workingTime/' + workingTimeId, body),
  deleteWorkingTime: (workingTimeId) => AxiosInterceptor.delete('workingTime/' + workingTimeId),

  grouping: (searchData) => AxiosInterceptor.get(`workingTime/group?${searchData}`),
  groupingUser: (searchData) => AxiosInterceptor.get(`workingTime/group?${searchData}&grouping=USER`),
  groupingWork: (searchData) => AxiosInterceptor.get(`workingTime/group?${searchData}&grouping=WORK_ORDER`),
};