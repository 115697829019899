import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { invoiceActions_setPageNumber, invoiceActions_setReset, invoiceActions_setSearchOption, invoiceActions_setSearchText, invoiceActions_setTotalSize, invoiceActions_setViewScale } from 'store/modules/actions/common/invoiceActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { invoice } from 'api/apis/invoice/Invoice';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';
import { rotateRight } from 'components/icons/src';
import { ViewResetBtn } from 'components/buttons/ViewButton';

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';

const InvoiceTable = styled.table`
  thead tr {border: none;}
  tbody {
    tr {border-bottom: 1px solid var(--MainBlue);}
    td {border: 1px solid var(--gray-200); height: fit-content;}
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 24px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Invoice = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoiceReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_invoiceList, setInvoiceList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_printModalStatus, setPrintModalStatus] = useState(false);
  const [_eventInvoice, setEventInvoice] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((authority) =>
      authority.authority.startsWith('205') === true || // 송장
      authority.authority.startsWith('207') === true, // 송장관리
    );
    const authorityCodes = authorityContent.map((authority) => authority.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getInvoiceList(invoiceReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getInvoiceList(invoiceReducer.pageNumber - 1);
    }

    return () => { };
  }, [invoiceReducer.pageNumber]);

  useEffect(() => { }, [invoiceReducer]);

  /* ========================================================================= #4 */
  /* 송장 */
  const getInvoiceList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(invoiceReducer.searchOption, false) && checkEmptyNull(invoiceReducer.searchText, false)) {
      BodyToPost[invoiceReducer.searchOption] = invoiceReducer.searchText;
    }

    await invoice.searchInvoice(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('invoiceApi.searchInvoice : ', response);
      setInvoiceList(() => { return response.data.content });
      dispatch(invoiceActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (invoice, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(invoice);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(invoiceActions_setPageNumber(1));
    await getInvoiceList(0, true);
  };

  /* 송장 */
  const actPrintInvoiceSpecs = (invoice, index) => {
    setEventInvoice(() => { return invoice });
    setTimeout(setPrintModalStatus(true), 1000);
  };

  /* 복사 */
  const actCopy = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('복사할 송장을 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({
        copyContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 송장을 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 송장을 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        deleteContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={'송장관리'}
              buttons={
                <>
                  {_authority.indexOf('205-2') !== -1 ? (
                    <>
                      <button className='btn-add'
                        onClick={(e) => {
                          e.preventDefault();
                          setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                        }}
                      >
                        추가
                      </button>
                      <button className='btn-copy' onClick={actCopy}>복사</button>
                      <button className='btn-edit' onClick={actUpdate}>수정</button>
                      <button className='btn-delete' onClick={actDelete}>삭제</button>
                    </>
                  ) : null}
                </>
              }
              nav={'search'}
              firstRow={
                <>
                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={invoiceReducer.searchOption}
                        onChange={(e) => { dispatch(invoiceActions_setSearchOption(e.target.value)) }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'invoiceCode', optionText: '송장코드' },
                          { optionValue: 'invoiceName', optionText: '송장이름' },
                        ].map((thisItem) => {
                          return (
                            <option key={thisItem.optionValue + '_invoiceSearchOption'} value={thisItem.optionValue}>
                              {thisItem.optionText}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={invoiceReducer.searchText}
                        onInput={(e) => { dispatch(invoiceActions_setSearchText(e.target.value)); }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton"
                      onClick={() => {
                        dispatch(invoiceActions_setReset());
                        getInvoiceList(0, false);
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </>
              }
            />

            <TableSection
              content={
                <InvoiceTable style={{ zoom: `${invoiceReducer.viewScale}%` }}>
                  <thead>
                    <tr>
                      {_authority.indexOf('205-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                      <th style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>
                      <th>작성일자</th>
                      <th>송장코드</th>
                      <th>송장이름</th>
                      <th>거래처</th>
                      <th>비고</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!checkNullArray(_invoiceList, false) ? LoadingMsg() :
                      checkNullArray(_invoiceList, []).map((invoice, index) => {
                        return (<tr key={index + '_invoice'}>
                          <td>
                            <input
                              type="checkBox"
                              name="invoices"
                              data-key={invoice.invoiceId}
                              checked={(() => {
                                const checkedIndex = _checkedItem ? Array.from(_checkedItem).findIndex((item) => item.invoiceId === invoice.invoiceId) : -1;
                                if (checkedIndex !== -1) return true;
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(invoice, isChecked);
                              }}
                              onClick={(e) => { e.stopPropagation() }}
                            />
                          </td>
                          <td
                            style={{ alignItems: 'center', border: 'none', cursor: 'pointer', display: 'flex', height: '100%', justifyContent: 'center', minWidth: '70px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <img src={process.env.PUBLIC_URL + '/src/icons/print.svg'} alt='송장출력 팝업 버튼' />
                          </td>
                          <td>{checkEmptyNull(invoice.createdDate, false) ? DateFormat(invoice.createdDate) : ''}</td>
                          <td>{checkEmptyNull(invoice.invoiceCode, '')}</td>
                          <td>{checkEmptyNull(invoice.invoiceName, '')}</td>
                          <td>{checkEmptyNull(invoice.accountName, '')}</td>
                          <td>
                            {checkEmptyNull(invoice.remark, false) ? invoice.remark.indexOf('\n') !== -1 ? invoice.remark.split('\n').map((text, textIndex) => {
                              return <p key={index + '_remark_' + textIndex}>{text}</p>
                            }) : invoice.remark : ''}
                          </td>
                        </tr>);
                      })}
                  </tbody>
                </InvoiceTable>
              }
            ></TableSection>
          </main>

          <PagingComponent
            page={invoiceReducer.pageNumber}
            count={invoiceReducer.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(invoiceActions_setPageNumber(page));
            }}
          />

          <ViewerButtons>
            <ViewMinusButton
              viewScale={invoiceReducer.viewScale}
              handleViewScale={(scale) => { dispatch(invoiceActions_setViewScale(scale)) }}
            />
            <ViewPlusButton
              viewScale={invoiceReducer.viewScale}
              handleViewScale={(scale) => { dispatch(invoiceActions_setViewScale(scale)) }}
            />
            <button onClick={ViewResetBtn}><img src={rotateRight} className='icon-default' alt='화면 줌 초기화 버튼' /></button>
          </ViewerButtons>
        </>
      }
    />
  );
};

export default Invoice;
