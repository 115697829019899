import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { userReducer_setUser } from 'store/modules/actions/user/userActions';

import { companyApi } from 'api/apis/companyApi';
import { userApi } from 'api/apis/userApi';

const LoginContent = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;

  img {
    height: 100%;
    width: auto;
  }
`;
const LogoContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50px 50px;
  /* grid-template-rows: 150px 35px 50px; */
  justify-items: center;
  margin: 0px auto;
  row-gap: 40px;
  transform: translateY(-30px);
  width: 240px;
`;
const LoginButton = styled.button`
  align-items: center;
  background-color: var(--white);
  border: none;
  border-radius: 25px;
  color: var(--MainNavy);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 900;
  height: 50px;
  justify-content: center;
  text-align: center;
  width: 240px;

  &:hover {
    background: unset;
    background-color: var(--white);
  }
`;
const CompanyInfo = styled.div`
  align-items: flex-end;
  color: var(--white);
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
`;
const CompanySelecBox = styled.select`
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding: 0px 10px;
  width: 300px;
`;

const UpdateMasterCompany = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_currentCompany, setCurrentComapny] = useState({});
  const [_companyList, setCompanyList] = useState([]);
  const [_user, setUser] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') {
      return navigate('/', { replace: true });
    } else {
      getCompanyList();

      setOnload('loaded');
    }
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getCompanyList = async () => {
    await companyApi.searchCompanies().then((response) => {
      if (response === undefined) return;
      console.log('companyApi.getCompanies : ', response);

      setCompanyList(() => { return response.data });
      getMyData(response.data);
    });
  };

  const getMyData = async (companyList) => {
    await userApi.getUserMe().then((response) => {
      if (response === undefined) return;
      console.log('getUserMe : ', response);

      Finish_getMyData(response.data, companyList);
    });
  };
  const Finish_getMyData = (Result, companyList) => {
    const thisFindIndex = companyList.findIndex((thisItem) => thisItem.companyId === Result.companyId);
    const thisFindItem = companyList[thisFindIndex];

    setCurrentComapny(thisFindItem);
    setUser(Result);
  };

  /* ====================================================================== #5 */
  const selectCompany = (e) => {
    const thisCompanyId = Number(e.target.value);

    setUser({
      ..._user,
      companyId: thisCompanyId,
    });
  };

  const updateMasterCompany = async () => {
    if (_user.companyId === null) return;

    const thisCompanyIndex = _companyList.findIndex(
      (thisItem) => thisItem.companyId === _user.companyId,
    );
    const thisCompany = _companyList[thisCompanyIndex];

    const BodyToPut = {
      companyId: _user.companyId,
    };
    console.log('BodyToPut : ', BodyToPut, thisCompany, _user);

    await userApi.updateUserMe(BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('put/user/selfCompany-response : ', response.data);

      dispatch(userReducer_setUser({}));
      dispatch(mallSiteUserActions_setUser({}));

      navigate('/', { replace: true });
    }).catch((error) => {
      console.log('put/user/selfCompany-error :', error);
    });
  };

  /* ====================================================================== #6 */

  return (
    <LoginContent>
      <LogoContent>
        <CompanyInfo>
          현재 회사:{' '}
          {Object.keys(_currentCompany).length > 0 &&
            _currentCompany.companyName}
        </CompanyInfo>

        <CompanySelecBox defaultValue="" onChange={selectCompany}>
          <option value="" disabled>
            회사선택
          </option>
          {_companyList.map((thisCompany) => (
            <option key={thisCompany.companyId} value={thisCompany.companyId}>
              {thisCompany.companyName}
            </option>
          ))}
        </CompanySelecBox>

        <LoginButton onClick={updateMasterCompany}>변경하기</LoginButton>
      </LogoContent>
    </LoginContent>
  );
};

export default UpdateMasterCompany;
