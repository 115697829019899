const initialState = {
  companyMenu: [],
  companyTopMenu: [],
  companySubMenu: [],

  /* ================================ */

  totalMenuLinks: {
    101: '/baseInformation/element', // 물품정보 || 원료 자재 제품
    102: '/baseInformation/category', // 패밀리
    103: '/baseInformation/attributes', // 항목
    104: '/baseInformation/account', // 거래처
    105: '/baseInformation/user', // 사용자
    106: '/baseInformation/team', // 팀
    107: '', //
    108: '/baseInformation/equipment', // 설비
    109: '/baseInformation/file', // 파일
    110: '/baseInformation/drawing', // 도면
    111: '/baseInformation/workOrder', // 작업정의
    112: '/baseInformation/process', // 공정정의
    113: '/baseInformation/shipmentInspectionItem', // 점검/검사 정의
    114: '/baseInformation/shipmentInspection', // 시험성적서 정의
    /* 100 */

    201: '/order/contract', // 수주
    202: '/order/procurement', // 발주
    203: '/order/procurementManagement', // 발주 미입고
    204: '/order/drawing', // 도면
    205: '/order/shipment', // 출하
    206: '/order/shipment', // 출하 -> 경서?
    207: '/order/invoice', // 송장관리
    /* 200 */

    301: '/stock/current', // 현재고
    302: '/stock/multi/In', // 다중입고
    303: '/stock/multi/Put', // 다중투입
    304: '/stock/multi/Out', // 다중출하
    305: '/stock/history', // 재고상세이력
    306: '/stock/shipment', // 출하 || 송장관리
    307: '/stock/placeHistory', // 재고위치추적
    /* 300 */

    401: '/',
    402: '/',
    403: '/production/order', // 작업지시
    404: '/production/scheduler', // 작업일정
    405: '/production/status', // 작업현황
    406: '/production/calendar', // 일일계획
    407: '/production/performance', // 작업실적
    408: '/production/history', // 작업이력
    409: '/',
    410: '/',
    411: '/production/failedOrder', // 불량재작업
    412: '/',
    413: '/production/reOrder', // 재작업이력
    /* 400 */

    501: '/working', // 작업실행
    502: '/',
    503: '/working/equipment', // 설비가동
    /* 500 */

    601: '/quality/control', // 품질관리
    602: '/quality/failedReason', // 불량사유
    603: '/quality/incomingInspection', // 수입검사
    604: '/quality/shipmentInspectionLog', // 시험성적서
    /* 600 */

    701: '/monitoring/total', // 모니터링
    702: '/monitoring/sensorData', // 센서데이터
    703: '/monitoring/scheduler', // 작업일정
    704: '/monitoring/accessHistory', // 사용자접속현황
    /* 700 */

    801: '/barcode/scan',
    802: '/barcode/print',
    /* 800 */

    901: '/system/user', // 사용자
    902: '/system/team', // 팀
    903: '/system/types', // 유형정의
    904: '/system/coreElement', // 핵심지표제품(KPI)
    998: '/system/myProfile', // 내정보
    999: '/system/companyInformation', // 회사정보
    /* 900 */
  },

  totalMenu: {
    top: [
      { menuCode: '100', menuName: '기준정보' },
      { menuCode: '200', menuName: '주문' },
      { menuCode: '300', menuName: '재고' },
      { menuCode: '400', menuName: '생산관리' }, // 생산 -> 생산관리
      { menuCode: '500', menuName: '제조실행' }, // 실행 -> 제조실행
      { menuCode: '600', menuName: '품질' },
      { menuCode: '700', menuName: '모니터링' },
      { menuCode: '800', menuName: '바코드' },
      { menuCode: '900', menuName: '시스템' },
    ],

    sub: [
      { menuCode: '101', menuName: '물품정보' },
      { menuCode: '102', menuName: '패밀리' },
      { menuCode: '103', menuName: '항목' },
      { menuCode: '104', menuName: '거래처' },
      { menuCode: '105', menuName: '사용자' },
      { menuCode: '106', menuName: '팀' },
      { menuCode: '107', menuName: '' },
      { menuCode: '108', menuName: '설비' },
      { menuCode: '109', menuName: '파일' },
      { menuCode: '110', menuName: '도면' },
      { menuCode: '111', menuName: '공정묶음' },
      { menuCode: '112', menuName: '공정 BOM' },
      { menuCode: '113', menuName: '점검/검사 정의' },
      { menuCode: '114', menuName: '시험성적서 정의' },
      /* 100 */

      { menuCode: '201', menuName: '수주' },
      { menuCode: '202', menuName: '발주' },
      { menuCode: '203', menuName: '발주 미입고' },
      { menuCode: '204', menuName: '도면' },
      { menuCode: '205', menuName: '출하' },
      { menuCode: '206', menuName: '송장관리' }, // 출하 -> 경서?
      { menuCode: '207', menuName: '송장관리' }, // 송장관리
      /* 200 */

      { menuCode: '301', menuName: '현재고' },
      { menuCode: '302', menuName: '다중입고' },
      { menuCode: '303', menuName: '다중차감' },
      { menuCode: '304', menuName: '다중출하' },
      { menuCode: '305', menuName: '재고상세이력' },
      { menuCode: '306', menuName: '출하' },
      { menuCode: '307', menuName: '재고위치추적' },
      /* 300 */

      { menuCode: '401', menuName: '' },
      { menuCode: '402', menuName: '' },
      { menuCode: '403', menuName: '작업지시' },
      { menuCode: '404', menuName: '작업일정' },
      { menuCode: '405', menuName: '작업현황' },
      { menuCode: '406', menuName: '일일계획' },
      { menuCode: '407', menuName: '작업실적' },
      { menuCode: '408', menuName: '작업이력' },
      { menuCode: '409', menuName: '' },
      { menuCode: '410', menuName: '' },
      { menuCode: '411', menuName: '불량재작업' },
      { menuCode: '412', menuName: '' },
      { menuCode: '413', menuName: '재작업이력' },
      /* 400 */

      { menuCode: '501', menuName: '작업실행' },
      { menuCode: '502', menuName: '' },
      { menuCode: '503', menuName: '설비가동' },
      /* 500 */

      { menuCode: '601', menuName: '품질관리' },
      { menuCode: '602', menuName: '불량사유' },
      { menuCode: '603', menuName: '수입검사' },
      { menuCode: '604', menuName: '시험성적서' },
      /* 600 */

      { menuCode: '701', menuName: '모니터링' },
      { menuCode: '702', menuName: '센서데이터' },
      { menuCode: '703', menuName: '작업일정' },
      { menuCode: '704', menuName: '사용자접속현황' },
      /* 700 */

      { menuCode: '801', menuName: '바코드' },
      { menuCode: '802', menuName: '바코드출력' },
      /* 800 */

      { menuCode: '901', menuName: '사용자' },
      { menuCode: '902', menuName: '팀' },
      { menuCode: '903', menuName: '설정' },
      { menuCode: '904', menuName: '핵심지표제품' },
      { menuCode: '998', menuName: '내 정보' },
      { menuCode: '999', menuName: '회사정보' },
      /* 900 */
    ],
  },
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCOMPANYMENU': return { ...state, companyMenu: action.payload };
    case 'SETCOMPANYTOPMENU': return { ...state, companyTopMenu: action.payload };
    case 'SETCOMPANYSUBMENU': return { ...state, companySubMenu: action.payload };

    case 'SETTOTALMENULINKS': return { ...state, totalMenuLinks: action.payload };
    case 'SETTOTALMENUL': return { ...state, totalMenu: action.payload };

    case 'SETMENURESET': return initialState;
    default: return state;
  }
};

export default menuReducer;
