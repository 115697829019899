import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";

import { pageReducer_getCurrentPage } from "store/modules/actions/default/pageActions";

import { postSensorSearch, postSensorSave, putSensorUpdate, deleteSensor } from "pages/operator/sensor/sensorCRUD";

import { checkEmptyNull, checkNullArray, checkNullObject } from "components/checkValues/checkValues";
import NavBar from "components/nav/NavBar";
import OperatorBody from "components/layouts/body/OperatorBody";
import SensorResponse from "pages/operator/sensor/SensorResponse";
import { sensor } from "api/apis/sensor";

const Section = styled.main`
  grid-template-rows: 66px 120px calc(100% - 186px);

  input[type="text"], select {
    border: 1px solid var(--gray-200);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 10px;
    width: 180px;
  }

  button.btn-save {width: 60px;}
  button.create {background-color: var(--MainBlue);}
  button.update {background-color: var(--MainNavy);}
  button.delete {background-color: var(--MainRed);}

  .section-form {
    align-content: center;
    box-sizing: border-box;
    display: grid;
    gap: 5px;
    height: 100%;
    padding: 20px;
    width: 100%;
    
    &>div {
      align-items: center;
      display: flex;
      gap: 10px;
      height: fit-content;
      justify-content: flex-start;
    }
  }

  .TableSection {
    td, th {
      min-width: unset;
      padding-inline: 8px;
    }
    li {
      font-size: 0.8em;
      text-align: left;
    }
  }
`;

const Sensor = () => {
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  const companyId = userReducer.company.companyId;
  const reloadBody = { companyId: companyId };

  const [sensorList, setSensorList] = useState([]);

  const [searchBody, setSearchBody] = useState({
    companyId: companyId,
    // kind: '',
    // type: '',
    // name: '',
    // code: ''
  });

  const [checkedItem, setCheckedItem] = useState({});
  const [createBody, setCreateBody] = useState({ companyId: companyId });
  const [updateBody, setUpdateBody] = useState({ companyId: companyId });

  const [_kind, setKind] = useState('group');
  const [_kindDataList, setKindDataList] = useState([]);

  const [_group, setGroup] = useState('');
  const [_data, setData] = useState('');

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    actSearch(searchBody.kind);
  }, []);
  useEffect(() => { }, [sensorList]);
  useEffect(() => { }, [checkedItem]);
  useEffect(() => { }, [createBody, updateBody]);

  useEffect(() => {
    if (_kind === 'data') getKindData('group');
    if (_kind === 'setting') getKindData('data');
    return () => { };
  }, [_kind])
  useEffect(() => { }, [_kindDataList, _group, _data]);

  const getKindData = async (kind) => {
    const body = { companyId: companyId, kind: kind };
    await sensor.searchSensor(body).then(response => {
      if (response === undefined) return;
      console.log('sensor.searchSensor : ', response);
      setKindDataList(() => { return response.data });
    })
  }

  const onSensorIdChange = (sensor) => {
    setCheckedItem(() => { return sensor });
    setUpdateBody(() => { return sensor });
  }

  const actSearch = async (kind) => {
    const body = {
      companyId: companyId,
      kind: checkEmptyNull(kind, undefined),
      type: checkEmptyNull(searchBody.type, undefined),
      name: checkEmptyNull(searchBody.name, undefined),
      code: checkEmptyNull(searchBody.code, undefined),
    };
    // console.log('body : ', body);
    const response = await postSensorSearch(body)
    setSensorList(() => { return response.data });
  }

  const actCreate = (e) => {
    e.preventDefault();
    if (_kind !== 'group' && !checkEmptyNull(createBody.parentId, false)) return alert('parentId 필요!');
    const body = { ...createBody, kind: _kind }
    console.log('body : ', body);
    postSensorSave(body).then(() => { actSearch(searchBody.kind); setCreateBody(() => { return { companyId: companyId } }) });
  }

  return (
    <OperatorBody contents={
      <Section className='Main'>
        <NavBar
          title={'센서'}
          buttons={
            <>
              <select value={searchBody.kind} onChange={(e) => { setSearchBody({ ...searchBody, kind: e.target.value }); actSearch(e.target.value) }}>
                <option value={''}>kind 선택</option>
                {['group', 'data', 'setting'].map((option, index) => {
                  return (<option key={index + '_option'} value={option}>{option}</option>)
                })}
              </select>
              <input
                type="text"
                placeholder="type"
                value={searchBody.type}
                onChange={(e) => setSearchBody({ ...searchBody, type: e.target.value })}
              />
              <input
                type="text"
                placeholder="name"
                value={searchBody.name}
                onChange={(e) => setSearchBody({ ...searchBody, name: e.target.value })}
              />
              <input
                type="text"
                placeholder="code"
                value={searchBody.code}
                onChange={(e) => setSearchBody({ ...searchBody, code: e.target.value })}
              />
              <button className="btn-search" onClick={(e) => { e.preventDefault(); actSearch(searchBody.kind) }}>검색</button>
            </>
          }
          nav={''}
        />

        <section className="section-form">
          <div>
            <select value={_kind} onChange={(e) => { setKind(() => { return e.target.value }); setCreateBody(() => { return { companyId: companyId } }) }}>
              {['group', 'data', 'setting'].map((option, index) => {
                return (<option key={index + '_option'} value={option}>{option}</option>)
              })}
            </select>
            {_kind === 'data' ? (
              <select value={_group} onChange={(e) => { setGroup(() => { return e.target.value }); setCreateBody((prev) => { return { ...prev, parentId: e.target.value * 1 } }); }}>
                <option value={''}>group 선택</option>
                {checkNullArray(_kindDataList, []).map((kindData, index) => {
                  return (<option key={index + '_kindData'} value={kindData.id}>[{kindData.id}] {kindData.name} {kindData.code}</option>);
                })}
              </select>
            ) : null}
            {_kind === 'setting' ? (
              <select value={_data} onChange={(e) => { setData(() => { return e.target.value }); setCreateBody((prev) => { return { ...prev, parentId: e.target.value * 1 } }); }}>
                <option value={''}>data 선택</option>
                {checkNullArray(_kindDataList, []).map((kindData, index) => {
                  return (<option key={index + '_kindData'} value={kindData.id}>[{kindData.id}] {kindData.name} {kindData.code}</option>);
                })}
              </select>
            ) : null}
            {_kind !== 'group' ? (
              <input
                type="text"
                placeholder="type"
                defaultValue={''}
                value={createBody.type}
                onChange={(e) => setCreateBody({ ...createBody, type: e.target.value })}
              />
            ) : null}
            <input
              type="text"
              placeholder="name"
              defaultValue={''}
              value={createBody.name}
              onChange={(e) => setCreateBody({ ...createBody, name: e.target.value })}
            />
            {_kind !== 'group' ? (
              <input
                type="text"
                placeholder="code"
                defaultValue={''}
                value={createBody.code}
                onChange={(e) => setCreateBody({ ...createBody, code: e.target.value })}
              />
            ) : null}
            <button className="btn-save create" onClick={actCreate}>추가</button>
          </div>
          <div>
            {checkNullObject(checkedItem, false) ? (
              <>
                <input
                  type="text"
                  placeholder="type"
                  value={updateBody?.type}
                  onChange={(e) => setUpdateBody({ ...updateBody, type: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="name"
                  value={updateBody?.name}
                  onChange={(e) => setUpdateBody({ ...updateBody, name: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="code"
                  value={updateBody?.code}
                  onChange={(e) => setUpdateBody({ ...updateBody, code: e.target.value })}
                />
                <button className="btn-save update" onClick={(e) => { e.preventDefault(); putSensorUpdate(checkedItem.id, updateBody, reloadBody).then(() => { setCheckedItem(() => { return {} }); actSearch(searchBody.kind); }) }}>수정</button>
                <button className="btn-save delete" onClick={(e) => { e.preventDefault(); deleteSensor(checkedItem.id, reloadBody).then(() => { setCheckedItem(() => { return {} }); actSearch(searchBody.kind); }) }}>삭제</button>
              </>
            ) : null}
          </div>
        </section>

        <SensorResponse sensorList={sensorList} onSensorIdChange={onSensorIdChange} setUpdateBody={setUpdateBody} />
      </Section>
    } />
  );
};

export default Sensor;