import React from 'react';
import styled from 'styled-components';

const FormSection = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const FormLayout = styled.form`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 200px calc(100% - 200px);
  grid-template-rows: 100%;
  /* height: 100%; */
  height: calc(100% - 60px);
  overflow: hidden;
  margin: 30px auto;
  padding: 0px 30px;
  position: relative;
  width: 95%;  
`;

const FormHeader = styled.div`
  background-color: var(--Bg);
  box-sizing: border-box;
  display: grid;
  gap: 50px;
  height: fit-content;
  left: 0px;
  padding: 20px 0;
  position: sticky;
  top: 0px;
  width: 200px;
`;
const FormTitle = styled.div`
  box-sizing: border-box;
  display: grid;
  font-size: 30px;
  font-weight: 500;
  max-width: 150px;
`;
const FormButtons = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 5px;
  width: 100%;
`;

const FormContents = styled.div`
  align-content: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 4px 24px;
  /* height: calc(100% - 66px); */
  height: 100%;
  overflow: auto;
  justify-content: flex-start;
  padding-block-end: 50px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  & .formBox {height: fit-content;}

  input {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue);}
    &[data-required="required"]::placeholder {color: var(--MainBlue);}
  }
  input[type="checkbox"] {cursor: pointer; display: inline-block; height: 20px; width: 20px;}

  select {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--Text);
    height: 40px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue); color: var(--MainBlue);}
  }

  textarea {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--Text);
    height: 80px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue); color: var(--MainBlue);}
  }

  & h4 {height: 35px; line-height: 35px;}
`;

const Form = (props) => {
  return (
    <FormSection /* style={props.formStyle} */>
      <FormLayout /* style={props.formLayoutStyle} */>
        <FormHeader>
          <FormTitle>{props.title}</FormTitle>
          <FormButtons>{props.buttons}</FormButtons>
        </FormHeader>

        <FormContents /* style={props.formSectionStyle} */>{props.forms}</FormContents>
      </FormLayout>
    </FormSection>
  );
};

export default Form;