import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { pageReducer_setMenuStauts } from 'store/modules/actions/default/pageActions';
import { userReducer_reset } from 'store/modules/actions/user/userActions';

import { removeCookie } from 'preferences/cookie/cookie';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { barcodeButtonIcon, baseInformationIcon, menuButtonIcon, monitoringIcon, orderIcon, productionIcon, qualityManagementIcon, stockIcon, workingIcon, homeIcon, userIcon, arrowRightToBracketIcon } from 'components/icons/src';
import TotalMenus from 'components/header/menu/TotalMenus';
import Version from 'components/header/Version';

const HeaderSection = styled.header`
  align-items: center;
  background-color: var(--MainNavy);
  box-shadow: 8px 8px 8px 8px #eee;
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  justify-content: space-between;
  overflow: initial;
  padding: 0 40px;
  width: 100%;
  z-index: 900;
  &::-webkit-scrollbar {display: none;}
`;

const TotalMenuButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13376f;
  height: 40px;
  width:  40px;
  border-radius: 8px;
  margin: 10px;
  img {height: 24px; width:  24px;}
  &:hover {background-color: var(--MainNavy)}
`;
const TopMenuSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  gap: 24px;
  width: max-content;
  @media (max-width: 1440px){gap: 16px; font-size: 1.25rem; padding-left: 10px;}
  @media (max-width: 1200px){gap: 10px; font-size: 1.25rem; padding-left: 6px;}
  @media (max-width:  980px){gap: 8px;  font-size: 1rem; padding-left: 2px;}
  Link:nth-of-type(1) div{left: 0px;}
`;
const TopMenu = styled.div`
  align-items: center;
  border-left: 2px solid var(--balck);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  height: 60px;
  justify-content: flex-start;
  padding: 0px 10px;

  ${(props) => {
    return props.thisPage.split('/')[1] === window.location.pathname.split('/')[1] ? 'opacity: 1;' : 'opacity: .4;';
  }}

  &:hover {opacity: 1;}
  &.Highlight {opacity: 1;}
  @media (max-width: 1440px){padding: 0px 8px;}
  @media (max-width: 980px){padding: 0px 6px;}
`;
const TopMenuIcon = styled.div`
  height: 24px;
  width:  24px;
  margin-right: 5px;

  mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;

  &[data-menucode='100'],
  &[data-menucode='900'] {mask-image: url(${baseInformationIcon}); --webkit-mask-image: url(${baseInformationIcon});}
  &[data-menucode='200'] {mask-image: url(${orderIcon}); --webkit-mask-image: url(${orderIcon});}
  &[data-menucode='300'] {mask-image: url(${stockIcon}); --webkit-mask-image: url(${stockIcon});}
  &[data-menucode='400'] {mask-image: url(${productionIcon}); --webkit-mask-image: url(${productionIcon});}
  &[data-menucode='500'] {mask-image: url(${workingIcon}); --webkit-mask-image: url(${workingIcon});}
  &[data-menucode='600'] {mask-image: url(${qualityManagementIcon}); --webkit-mask-image: url(${qualityManagementIcon});}
  &[data-menucode='700'] {mask-image: url(${monitoringIcon}); --webkit-mask-image: url(${monitoringIcon});}
  &[data-menucode='800'] {mask-image: url(${barcodeButtonIcon}); --webkit-mask-image: url(${barcodeButtonIcon});}
  @media (max-width: 1440px){display: none;}
`;

const BorderMenu = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 60px;
  padding-bottom: 10px;
  width: 200px;
  z-index: 99999;
  box-shadow: 0px 4px 10px #333;
  border-radius: 0px 0px 10px 10px;
  left:-40px;
  @media (max-width: 1440px){
    left: ${(props) => {
    if (props.isshownmenu === false || props.isshownmenu === undefined || props.isshownmenu === null) return;
    const menuIndex = props.isshownmenu;
    const cal = menuIndex === 0 ? 0 : menuIndex * 4;
    const calW = menuIndex * 20 + menuIndex * 5 + 230;
    if (cal <= 0) return `210px`;
    else return `calc(${cal}vw + ${calW}px)`;
  }};
  }
  @media (max-width: 1200px){
    left: ${(props) => {
    if (props.isshownmenu === false || props.isshownmenu === undefined || props.isshownmenu === null) return;
    const menuIndex = props.isshownmenu;
    const cal = menuIndex === 0 ? 0 : menuIndex * 4.5;
    const calW = menuIndex * 20 + menuIndex * 5 + 230;
    if (cal <= 0) return `210px`;
    else return `calc(${cal}vw + ${calW}px)`;
  }};
  }
  @media (max-width: 980px){
    left: ${(props) => {
    if (props.isshownmenu === false || props.isshownmenu === undefined || props.isshownmenu === null) return;
    const menuIndex = props.isshownmenu;
    const cal = menuIndex === 0 ? 0 : menuIndex * 2;
    const calW = menuIndex * 20 + menuIndex * 5 + 230;
    if (cal <= 0) return `210px`;
    else return `calc(${cal}vw + ${calW}px)`;
  }};
  }
  &::-webkit-scrollbar {display: none;}
  & a {display: block; width: 100%;}
`;
const SubMenu = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.125rem;
  font-weight: 300;
  height: 50px;
  justify-content: center;
  width: 100%;
  &:hover {background-color: var(--ThirdBlue);}
`;

const Header = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const _topMenus = [...userReducer.userMenu].map((menu) => {
    if (menu.pageCode.includes('00')) { return menu; }
    else if (menu.indexY === 1) { return menu; }
    else return null;
  }).filter((thisItem) => thisItem !== null);

  const [_isShown, setIsShown] = useState(false);
  const [_isShownMenu, setIsShownMenu] = useState(false);

  const [_subMenus, setSubMenus] = useState(null);

  const [onMenu, setOnMenu] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_setMenuStauts('open'));
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const setTotalMenu = () => {
    if (pageReducer.menuStatus === 'open') dispatch(pageReducer_setMenuStauts('close'));
    else dispatch(pageReducer_setMenuStauts('open'));
  };

  const setMenuList = (menu) => {
    setSubMenus(() => {
      const subMenuList = [];
      const sortList = [...userReducer.userMenu].sort((a, b) => {
        if (a.indexX > b.indexX) return 1;
        if (a.indexX < b.indexX) return -1;
        if (a.indexY > b.indexY) return 1;
        if (a.indexY < b.indexY) return -1;
        else return 0;
      });
      sortList.forEach((subMenu) => {
        if ((subMenu.indexX === menu.indexX) && !subMenu.pageCode.includes('00')) subMenuList.push(subMenu);
      });
      return subMenuList;
    });
  };

  /* ====================================================================== #5 */
  const SubInfo = () => { setOnMenu(!onMenu); }

  const actLogout = () => {
    console.log('================================= removeCookie');
    removeCookie('accessToken');
    removeCookie('refreshToken');
    dispatch(userReducer_reset());
    dispatch(mallSiteUserActions_setUser());
    navigate('/', { replace: true });
  }

  /* ====================================================================== #6 */

  return (
    <HeaderSection>
      <Link to="/dashboard"><img src={homeIcon} alt="대쉬보드버튼" style={{ color: 'white' }} /></Link>
      <div style={{ display: 'flex' }}>
        <TotalMenuButton onClick={setTotalMenu}><img src={menuButtonIcon} alt="전체메뉴버튼" /></TotalMenuButton>
        <TopMenuSection>
          {/* 상위 메뉴 */}
          {checkNullArray(_topMenus, []).map((menu, index) => {
            if (menu.pageCode.includes('00')) {
              const menuDataIndex = menuReducer.companyTopMenu.findIndex(item => item.pageCode === menu.pageCode);
              const menuData = menuReducer.companyTopMenu[menuDataIndex];
              return (
                <Fragment key={index + '_topMenu'}>
                  <TopMenu
                    name="TopMenus"
                    style={{ position: 'relative' }}
                    data-menucode={menu.pageCode}
                    thisPage={menuData.pageUrl}
                    onMouseEnter={() => {
                      setMenuList(menu);
                      setIsShownMenu(index);
                      setIsShown(true);
                    }}
                    onMouseLeave={() => { setIsShown(false); }}
                    onClick={(e) => {
                      e.preventDefault();
                      // 변경 전 : 메뉴 호버 시 서브 메뉴 나오고, 클릭 시 해당 메뉴로 이동
                      // navigate(menu.pageUrl, { replace: true });
                      // 변경 후 : 메뉴 클릭 시 서브 메뉴 나오고, 클릭 시 하위 메뉴 나옴
                      setMenuList(menu);
                      setIsShownMenu(index);
                      setIsShown(true);
                    }}
                    isshownmenu={_isShownMenu}
                  >
                    <TopMenuIcon data-menucode={menu.pageCode}></TopMenuIcon>
                    {menuData.pageName}
                    {pageReducer.menuStatus === 'open' && _isShown && index === _isShownMenu ? (
                      <div
                        isshownmenu={_isShownMenu}
                        data-menucode={menu.pageCode}
                        onMouseEnter={() => { setIsShown(true) }}
                        onMouseLeave={() => { setIsShownMenu(false); setIsShown(false); }}
                      >
                        <BorderMenu>
                          {_subMenus.map((thisSubMenu, subIndex) => {
                            if (!checkEmptyNull(thisSubMenu.companyPageCodeId, false)) return;
                            return (
                              <SubMenu key={index + '_subMenu_' + subIndex}
                                onClick={(e) => { e.stopPropagation(); navigate(thisSubMenu.pageUrl, { replace: true }) }}
                              >
                                {thisSubMenu.pageName}
                              </SubMenu>
                            );
                          })}
                        </BorderMenu>
                      </div>
                    ) : null}
                  </TopMenu>
                </Fragment>
              );
            } else return null;
          })}
        </TopMenuSection>
      </div>
      <div style={{ display: 'flex', gap: '16px' }}>
        <button onClick={SubInfo}><img src={userIcon} alt="유저버튼" style={{ width: '24px', height: '24px' }} /></button>
        <button onClick={actLogout}><img src={arrowRightToBracketIcon} alt="로그아웃버튼" style={{ width: '22px', height: '22px', paddingTop: '2px' }} /></button>
      </div>
      {onMenu ? <div className='user-info'>
        <Link to='/system/companyInformation'>회사정보 <span>({userReducer.company.companyName})</span></Link>
        <Link to='/system/myProfile'>내정보 <span>({userReducer.user.name} 님)</span></Link>
        <a href='/inquiry'>문의<span></span></a>
        {/* <Version /> */}
      </div> : null}
      {/* 전체 메뉴 */}
      {pageReducer.menuStatus !== 'open' ? (<TotalMenus onClick={setTotalMenu} />) : null}
    </HeaderSection>
  );
};

export default Header;
