import axios from 'axios';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

import { getCookie } from 'preferences/cookie/cookie';

/* ====================================================================== 로컬 */
// export const API_BASE_URL = 'http://localhost:8080';
// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect';
// const STOMP_URL = 'http://localhost:3000/stomp';

/* ====================================================================== API_BASE_URL */
/* 로컬 일반 SSL */
// export const API_BASE_URL = 'https://lvh.me';

/* 테스트 서버 */
// export const API_BASE_URL = 'http://ifistest.link:8080';

/* 서버 */
export const API_BASE_URL = 'https://ifis.pro:8080';

// >> 주석 풀어야 함!!
export const OAUTH2_REDIRECT_URI = API_BASE_URL.replace(':8080', '') + '/oauth2/redirect';
const STOMP_URL = API_BASE_URL.replace(':8080', '') + '/stomp';

/* ====================================================================== GOOGLE_AUTH_URL */
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const MALL_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=';

/* ====================================================================== axios */
export const AXIOS_BASE_URL = API_BASE_URL + '/api';
export const AXIOS_BASE_HEADERS = {
  headers: {
    Authorization: getCookie('accessToken'),
  },
};

axios.defaults.baseURL = '';
axios.defaults.headers.common['Authorization'] = getCookie('accessToken');

/* ====================================================================== stomp */
const STOMP_HEADERS = getCookie('accessToken')
  ? getCookie('accessToken').replace('Bearer ', '')
  : null;

/* 웹소켓 */
export const WS_CLIENT = new Client({
  webSocketFactory: () => new SockJS(STOMP_URL),

  connectHeaders: {
    'auth-token': STOMP_HEADERS,
    'Authorization': getCookie('accessToken'),
  },

  reconnectDelay: 5000,
  heartbeatIncoming: 4000,
  heartbeatOutgoing: 4000,

  debug: (str) => {
    console.log(
      `
    ${str}
    `,
    );
  },
});

export const ACCESS_TOKEN = 'accessToken';

axios.defaults.baseURL = '';
axios.defaults.headers.common['Authorization'] = getCookie('accessToken');
