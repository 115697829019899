import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Sign, PasswordInput } from 'mall/styles/style';
import moment from 'moment';

import { userReducer_reset, userReducer_setUser } from 'store/modules/actions/user/userActions';
import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';

import { getCookie, removeCookie, setCookie } from 'preferences/cookie/cookie';
import { any } from 'api/apis/any/any';
import { sign } from 'api/apis/mall/sign';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

const MallSignLogin = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_loginId, setLoginId] = useState('');

  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');

  /* ====================================================================== #3 */
  const loadCurrentlyLoggedInUser = async (token) => {
    const actError = () => {
      console.log('================================= removeCookie');
      removeCookie('accessToken');
      removeCookie('refreshToken');
      dispatch(userReducer_reset());
      dispatch(mallSiteUserActions_setUser());

      setLoginId(() => { return '' });
      setPw(() => { return '' });

      navigate(0);
    }
    await any.getMyData(getCookie('accessToken')).then((response) => {
      console.log('any.getMyData : ', response);
      if (response === undefined) return actError();

      const userData = {
        ...response.data,
        userId: response.data.id,
        mallUserName: response.data.name
      }
      dispatch(userReducer_setUser(userData));
      dispatch(mallSiteUserActions_setUser(userData));
      setTimeout(navigate(`/${companyCode}/mall/product/list`, { replace: true }), 1000);
    }).catch((error) => {
      console.log('any.getMyData : ', error);
      alert('다시 접속해주시기 바랍니다.');
      actError();
    });
  };
  useEffect(() => {
    console.log(`getCookie('accessToken') : `, getCookie('accessToken'));
    console.log('userReducer.user : ', userReducer.user);
    if (!checkEmptyNull(getCookie('accessToken'), false) && !checkEmptyNull(getCookie('refreshToken'), false)) {
      console.log('** mall login - no token **');
      return Promise.reject('No access token set.');
    } else if (checkEmptyNull(getCookie('accessToken'), false) && checkEmptyNull(getCookie('refreshToken'), false) && !checkNullObject(userReducer.user, false)) {
      console.log('** mall login - no userData **');
      loadCurrentlyLoggedInUser();
    } else if (checkNullObject(userReducer.user, false)) {
      console.log('** mall login - normal **');
      const userData = {
        ...userReducer.user,
        userId: userReducer.user.id,
        mallUserName: userReducer.user.name
      }
      console.log('userData : ', userData);
      dispatch(userReducer_setUser(userData));
      dispatch(mallSiteUserActions_setUser(userData));

      // alert('이미 로그인되어있습니다.')
      setTimeout(navigate(`/${companyCode}/mall/product/list`, { replace: true }), 1000);
    }
  }, [])

  useEffect(() => { checkPw(); return () => { }; }, [_pw]);

  // 비밀번호 체크
  const checkPw = () => {
    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    setPwStatus(() => {
      if (!reg.test(_pw)) return false; // 불통
      else return true; // 통과
    });
  };

  // 로그인
  const actLogin = async () => {
    if (_loginId.length === 0) return alert('아이디를 확인해주세요.');
    if (_pw.length < 8 || !_pwStatus) return alert('비밀번호를 확인해주세요.');

    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합입니다.');

    const BodyToPost = {
      companyCode: companyCode,
      loginId: _loginId,
      password: _pw,
    };
    await sign.login(BodyToPost).then(response => {
      if (response === undefined) return;
      const options = {};
      // httpOnly: true,
      setCookie('accessToken', response.data.accessToken, options);
      setCookie('refreshToken', response.data.refreshToken, options);
      setCookie('tokenTime', moment().format('YYYY-MM-DD HH:mm:ss'), options);
      loadCurrentlyLoggedInUser(response.data.accessToken);
    })
  };

  return (
    <>
      <Sign>
        <header>
          <Link to={`/${companyCode}/mall/product/list`}>아이피스 몰</Link>
          <Link to={`/${companyCode}/mall/sign/login`}>로그인</Link>
        </header>
        <section className='sign'>
          <h3>아이피스 몰 로그인</h3>
          <fieldset>
            <input
              type="text" placeholder="아이디" required
              value={_loginId}
              onInput={(e) => {
                const loginId = e.target.value;
                setLoginId(() => { return loginId; });
              }}
            />
            <PasswordInput
              type="password" placeholder="비밀번호 (8자리 이상 영문+숫자+특수기호)" name="password" required
              data-status={_pwStatus} value={_pw} minLength={8}
              onInput={(e) => {
                const pw = e.target.value;
                setPw(() => { return pw; });
              }}
            />
          </fieldset>
          <button type="submit" className='submit' onClick={actLogin}>로그인</button>
          <div className='signitem'>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/join`, { replace: true }); }} >회원가입</button>
          </div>
        </section>
      </Sign>
    </>
  );
};

export default MallSignLogin;
