import styled from "styled-components";

export const CommentComponent = styled.div`
  background-color: var(--white);
  border: 1px solid #eee;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-sizing: border-box;
  display: grid;
  gap: 50px;
  grid-template-rows: 50px calc(100% - 100px);
  height: 80%;
  justify-items: center;
  left: 50%;
  min-height: 500px;
  padding: 30px;
  position: absolute;
  top: 0px;
  transform: translateX(-50%);
  width: 90%;
  z-index: 11;
  
  &>header {
    align-items: flex-end;
    display: flex;
    height: 50px;
    justify-content: space-between;
    max-width: 1240px;
    width: 100%;

    & h1 { // 타이틀
      font-size: 30px;
    }

    & .btn-close { // 닫기 버튼
      height: 60px;
      width: 30px;

      &:hover {background: unset;}

      &>svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  &>div {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;    
    
    &>section {
      align-content: flex-start;
      display: grid;
      gap: 30px;
      height: 100%;
      overflow-y: auto;
      width: 100%;

      &>.section-comment { // 입력 폼 섹션
        align-content: flex-start;
        border: none;
        /* border-radius: 12px; */
        box-sizing: border-box;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        height: fit-content;
        padding: unset;
      }
      &>.section-action { // 액션 버튼 섹션
        align-items: flex-start;
        background-color: var(--white);
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        height: 100px;
        justify-content: space-between;
        padding-block-start: 10px;
        position: sticky;
        width: 100%;

        & button {
          background-color: #246BEB;
          border-radius: 8px;
          box-sizing: border-box;
          color: var(--white);
          height: fit-content;
          padding: 10px 16px;
          width: fit-content;
          
          &.btn-save {width: 120px;}
          &.btn-delete {
            background-color: #EB003B;
            width: 120px;
          }
          &.btn-cancel {
            background-color: var(--white);
            border: 1px solid #1D1D1D;
            color: #1D1D1D;
            &:hover {background: unset;}
          }
        }
      }
    }
  }

  &.form { // CommentForm
    gap: 30px;
    height: fit-content;
    grid-template-rows: 50px calc(100% - 80px);
    max-width: 700px;
    min-height: unset;
    width: 90%;

    &>div>section .section-form { // 입력 폼 섹션
      align-content: flex-start;
      border-radius: 12px;
      box-sizing: border-box;
      gap: 20px;
      display: grid;
      height: 100%;
      overflow-y: auto;
      padding-block: unset;

      & .form-content { // 폼 컨텐츠
        align-content: flex-start;
        display: grid;
        gap: 10px;

        & h4 { // 인풋 이름
          font-weight: 400;
        }
        
        & input, & select, & textarea { // 텍스트 인풋 스타일
          background-color: var(--white);
          border: 1px solid #717171;
          border-radius: 8px;
          padding: 16px;
          max-width: 60%;
          &:focus {border: 2px solid #246BEB;}
        }
        & textarea {
          height: 200px;
          max-width: 90%;
          resize: none;
        }
      }
    }
  }
`;

export const CommentBox = styled.div`
  border: 1px solid #C6C6C6;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  box-sizing: border-box;
  display: grid;
  padding: 30px;
  position: relative;
  gap: 10px;
  width: 100%;

  &>.form-btns { // Proposer 수정/삭제
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 30px;

    &>button {
      height: 24px;
      width: 24px;
      
      &.edit:hover {background: unset; color: #003675;}
      &.delete:hover {background: unset; color: #ae0000;}
      &>svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  
  &>.top {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }

  & .form-content { // 폼 컨텐츠
  align-content: flex-start;
  display: grid;
  gap: 10px;

  & h4 { // 인풋 이름
    font-size: 15px;
    font-weight: 400;
  }
  
  & input, & select, & textarea { // 텍스트 인풋 스타일
    background-color: var(--white);
    border-block-end: 1px solid #cfcfcf;
    border-radius: unset;
    font-size: 14px;
    padding: 12px 16px;
    max-width: unset;
    width: 100%;
    &:focus {outline: none;}
  }
  & textarea {
    background-color: #F8F8F8;
    border: none;
    height: 100px;
    max-width: 100%;
    resize: none;
  }
}
`;