import { AxiosInterceptor, ClusterAxiosInterceptor } from "api/core/consts";

export const cluster = {
  get: (id) => AxiosInterceptor.get('/cluster/' + id),
  search: (paging, body) => AxiosInterceptor.post('/cluster/search' + paging, body),
};

export const equipmentSharingType = { // 장비 유형
  get: (id) => ClusterAxiosInterceptor.get('/equipmentSharingType/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/equipmentSharingType/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/equipmentSharingType', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/equipmentSharingType/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/equipmentSharingType/' + id),
};
export const peopleSharingType = { // 인력 유형
  get: (id) => ClusterAxiosInterceptor.get('/peopleSharingType/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/peopleSharingType/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/peopleSharingType', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/peopleSharingType/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/peopleSharingType/' + id),
};

export const equipmentSharingAttribute = { // 장비 속성
  get: (id) => ClusterAxiosInterceptor.get('/equipmentSharingAttribute/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/equipmentSharingAttribute/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/equipmentSharingAttribute', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/equipmentSharingAttribute/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/equipmentSharingAttribute/' + id),
};
export const peopleSharingAttribute = { // 인력 속성
  get: (id) => ClusterAxiosInterceptor.get('/peopleSharingAttribute/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/peopleSharingAttribute/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/peopleSharingAttribute', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/peopleSharingAttribute/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/peopleSharingAttribute/' + id),
};

export const equipmentSharing = { // 공유 장비
  get: (id) => ClusterAxiosInterceptor.get('/equipmentSharing/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/equipmentSharing/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/equipmentSharing', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/equipmentSharing/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/equipmentSharing/' + id),
};
export const peopleSharing = { // 공유 인력
  get: (id) => ClusterAxiosInterceptor.get('/peopleSharing/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/peopleSharing/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/peopleSharing', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/peopleSharing/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/peopleSharing/' + id),
};

export const equipmentSharingBoard = { // 요창 장비
  get: (id) => ClusterAxiosInterceptor.get('/equipmentSharingBoard/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/equipmentSharingBoard/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/equipmentSharingBoard', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/equipmentSharingBoard/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/equipmentSharingBoard/' + id),
};
export const peopleSharingBoard = { // 요창 인력
  get: (id) => ClusterAxiosInterceptor.get('/peopleSharingBoard/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/peopleSharingBoard/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/peopleSharingBoard', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/peopleSharingBoard/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/peopleSharingBoard/' + id),
};

export const publicBoard = { // 공용 게시판
  get: (id) => ClusterAxiosInterceptor.get('/publicBoard/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/publicBoard/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/publicBoard', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/publicBoard/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/publicBoard/' + id),
};

export const jointPurchase = { // 공동 구매
  get: (id) => ClusterAxiosInterceptor.get('/jointPurchase/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/jointPurchase/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/jointPurchase', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/jointPurchase/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/jointPurchase/' + id),
};
export const jointPurchaseProposer = { // 공동 구매 Proposer
  get: (id) => ClusterAxiosInterceptor.get('/jointPurchaseProposer/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/jointPurchaseProposer/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/jointPurchaseProposer', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/jointPurchaseProposer/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/jointPurchaseProposer/' + id),
};
export const reciprocalTransaction = { // 상호 거래
  get: (id) => ClusterAxiosInterceptor.get('/reciprocalTransaction/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/reciprocalTransaction/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/reciprocalTransaction', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/reciprocalTransaction/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/reciprocalTransaction/' + id),
};
export const reciprocalTransactionProposer = { // 상호 거래 Proposer
  get: (id) => ClusterAxiosInterceptor.get('/reciprocalTransactionProposer/' + id),
  search: (paging, body) => ClusterAxiosInterceptor.post('/reciprocalTransactionProposer/search' + paging, body),
  create: (body) => ClusterAxiosInterceptor.post('/reciprocalTransactionProposer', body),
  update: (id, body) => ClusterAxiosInterceptor.put('/reciprocalTransactionProposer/' + id, body),
  delete: (id) => ClusterAxiosInterceptor.delete('/reciprocalTransactionProposer/' + id),
};