import { Link } from "react-router-dom";
import styled from "styled-components";

export const PMSideBarComponent = styled.nav`
  align-content: start;
  border-inline-end: 1px solid #c6c6c6;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  justify-items: safe;
  padding-inline-end: 50px;
  width: 100%;

  &>h1 {
    display: inline-block;
    border-block-end: 1px solid #8e8e8e;
    box-sizing: border-box;
    font-size: 21px;
    font-weight: 600;
    height: 100px;
    line-height: 100px;
    padding-inline-start: 8px;
  }
  `;
export const PMMenuComponent = styled(Link)`
  display: inline-block;
  border-block-end: 1px solid #d8d8d8;
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 600;
  height: 65px;
  line-height: 65px;
  padding-inline-start: 8px;
  
  &.active {
    background-color: #EDF1F5;
    border-block-end: 3px solid #003675;
    color: #003675;
  }
`;