import { AxiosInterceptor } from 'api/core';

export const barcodeApi = {
  getElement: (id) => AxiosInterceptor.get('barcode/element/' + id),
  getStockLog: (id) => AxiosInterceptor.get('barcode/stockLog/' + id),
  getLot: (id) => AxiosInterceptor.get('barcode/lot/' + id),
  getSerial: (id) => AxiosInterceptor.get('barcode/serial/' + id),
  searchLot: (paging, body) => AxiosInterceptor.post('barcode/lot' + paging, body),
  searchSerial: (paging, body) => AxiosInterceptor.post('barcode/serial' + paging, body),
};
