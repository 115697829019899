import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { kpi } from 'api/apis/kpi/kpi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

import KPIGraph from 'pages/kpi/contents/KPIGraph';
import KPITable from 'pages/kpi/contents/KPITable';

const Main = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);

  .btn-set {
    &.active {
      background-color: var(--MainBlue);
      color: var(--white);
      &:hover {background-color: var(--MainBlue);}
    }
  }
  .btn-search {padding-inline: 16px;}
  .ResetButton {
    box-shadow: none;
    &:hover {background: none;}
  }

  .contents {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    height: 100%;
    padding: 40px;
    width: 100%;
    
    .contentWrap {
      background-color: var(--white);
      border-radius: 20px;
      box-sizing: border-box;
      display: grid;
      gap: 1%;
      grid-template-columns: 69% 30%;
      height: 100%;
      padding: 20px;
      width: 100%;

      .section-table {
        display: grid;
        grid-gap: 10px;
        height: fit-content;
      }

      .TableSection {
        height: 100%;
        
        table {
          td, th {
            border-color: var(--gray-200);
            padding-block: 6px;
          }
          th {
            background-color: var(--MainNavy);
            color: var(--white);
          }
        }
      }
    }
  }
`;

const KPIMonitoring = () => {
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  const [_kpiUnits, setKpiUnits] = useState([]);
  const [_kpiUnit, setKpiUnit] = useState({});
  const [_start, setStart] = useState('');
  const [_end, setEnd] = useState('');

  const [_kpiDataList, setKpiDataList] = useState([]);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getKpiUnits();

    return () => { }
  }, [])
  useEffect(() => { }, [_kpiUnit, _kpiDataList]);

  const getKpiUnits = async () => {
    // const body = { companyId: 28 }
    const body = { companyId: userReducer.company.companyId, type: "shop", year: 2023 }
    await kpi.searchKpiUnit(body).then(response => {
      if (response === undefined) return;
      console.log('kpi.searchKpiUnit : ', response);
      const returnData = response.data;
      // returnData.reverse();
      setKpiUnits(() => { return returnData });
    })
  }
  const getKpiData = async (kpiUnit) => {
    if (!checkEmptyNull(_start, false)) return;
    // if (!checkEmptyNull(_start, false) || !checkEmptyNull(_end)) return;
    const fromDate = moment(_start).startOf(kpiUnit.cycle).format('YYYY-MM-DD');
    const toDate = moment(_start).endOf(kpiUnit.cycle).format('YYYY-MM-DD');

    const body = {
      fromDate: fromDate,
      toDate: toDate,
      kpiUnitId: kpiUnit.id
    }
    // if (_start > _end) {
    //   body.fromDate = _end;
    //   body.toDate = _start;
    // }
    // console.log('body : ', body);
    await kpi.searchKpiData(body).then(response => {
      if (response === undefined) return;
      console.log('kpi.searchKpiData : ', response);
      setKpiDataList(() => { return response.data });
    })
  }

  const actSearch = () => {
    if (!checkEmptyNull(_start, false)) return alert('조회 기간을 선택해주세요.');
    // if (!checkEmptyNull(_start, false) || !checkEmptyNull(_end)) return alert('조회 기간을 선택해주세요.');
    getKpiData(_kpiUnit);
  }
  const actReset = () => {
    setStart(() => { return ''; });
    setEnd(() => { return ''; });
  }

  return (
    <Grid2Body contents={
      <Main>
        <NavBar
          title={<><NavTitle menuCode={'706'} /></>}
          buttons={
            <div className="SearchSection">
              <select
                className='SelectType'
                style={{ marginInlineEnd: '10px', width: '180px' }}
                value={_kpiUnit.id || ''}
                onChange={(e) => {
                  const unitId = e.target.value * 1;
                  const unitData = _kpiUnits.find(item => item.id === unitId);
                  setKpiUnit(() => { return unitData || {} });
                  if (checkEmptyNull(e.target.value, false)) getKpiData(unitData);
                }}
              >
                <option value={''}>KPI 유형 선택</option>
                {_kpiUnits.map((unit, index) => {
                  if (checkNullArray(unit.kpiDataDtoList, false)) {
                    return (<option key={index + '_unit'} value={unit.id}>{unit.kpiCode.kpiDtlNm}</option>);
                  } else return null;
                })}
              </select>

              {checkEmptyNull(_kpiUnit?.id, false) ? (
                <div className="SearchSection">
                  <div className="ContentCBox">
                    {/* <input
                      type='date'
                      className='SelectDate'
                      value={_start}
                      onChange={(e) => { setStart(() => { return e.target.value; }) }}
                    />
                    <IconRightArrow />
                    <input
                      type='date'
                      className='SelectDate'
                      value={_end}
                      onChange={(e) => { setEnd(() => { return e.target.value; }) }}
                    /> */}
                    <input
                      type={_kpiUnit.cycle}
                      className='SelectDate'
                      value={_start}
                      onChange={(e) => { setStart(() => { return e.target.value; }) }}
                    />
                  </div>

                  <div className='ContentCBox'>
                    <button className='btn-search' onClick={(e) => { e.preventDefault(); actSearch(); }}>검색<SearchButtonIcon /></button>
                    <button className='ResetButton' onClick={(e) => { e.preventDefault(); actReset(); }}>초기화</button>
                  </div>
                </div>
              ) : null}
            </div>
          }
          nav={''}
        />

        <div className='contents'>
          <div className='contentWrap'>
            {checkEmptyNull(_kpiUnit?.id, false) ? (
              <>
                <KPIGraph unit={_kpiUnit} contents={_kpiDataList} />
                <KPITable unit={_kpiUnit} contents={_kpiDataList} />
              </>
            ) : null}
          </div>
        </div>
      </Main>
    }
    />
  );
};

export default KPIMonitoring;