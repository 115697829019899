import React from 'react';
import styled from 'styled-components';

const MainContents = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70vh;
  margin-bottom: 40px;
`;

const ContentBox = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  column-gap: 50px;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-evenly;
  margin: 0px auto;
  overflow: hidden;
  padding: 0px 70px;
  position: relative;
  width: 80%;
`;
const Content = styled.div`
  align-items: flex-start;
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  column-gap: 50px;
  display: flex;
  height: 80%;
  justify-content: space-between;
  margin: 0px auto;
  overflow: auto;
  padding: 0px 30px;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ContentTitle = styled.div`
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 500;
  height: 100%;
  left: 0px;
  padding: 60px 0px;
  position: sticky;
  top: 0px;
  width: 200px;
`;
const ContentText = styled.div`
  padding: 30px 0px;
  width: 100%;
`;
const ContentButtonBox = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  justify-content: center;
`;

const DeleteForm = (props) => {
  return (
    <MainContents>
      <ContentBox>
        <Content>
          <ContentTitle>
            <p>
              {props.delteTitle}
              <p>삭제</p>
            </p>
          </ContentTitle>

          <ContentText>
            {props.deleteItem}
            <br />
            {props.deleteText}
            <br />
            <p>그래도 삭제하시겠습니까?</p>
          </ContentText>
        </Content>

        <ContentButtonBox>{props.deleteButton}</ContentButtonBox>
      </ContentBox>
    </MainContents>
  );
};

export default DeleteForm;
