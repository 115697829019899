import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import BigNumber from 'bignumber.js';

const KPIGraph = (props) => {
  const { unit, contents } = props;
  const Content = useRef();
  const KPIChart = useRef();

  const [_unit, setUnit] = useState(unit);
  const [_dataList, setDataList] = useState(contents);

  const options = {
    chart: {
      id: moment().format('YYYY-MM-DD') + '_KPI',
      toolbar: { show: true },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       y: unit.target,
    //       borderColor: '#9C27B0',
    //       label: {
    //         borderColor: '#9C27B0',
    //         style: { background: '#9C27B0', color: '#fff' },
    //         text: '목표치',
    //       }
    //     }
    //   ]
    // },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
      padding: { right: 30 },
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    legend: { show: 'bottom' },
    markers: { size: [0, 5, 0] },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: unit.kpiCode.kpiDtlNm,
      align: 'left'
    },
    xaxis: {
      type: '일',
      categories: [],
      tickPlacement: 'on',
    },
    yaxis: [
      {
        seriesName: '실적',
        axisTicks: { show: true },
        axisBorder: { show: true, },
        title: { text: "실적" },
        labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} ${unit.unit}` } },
      },
      {
        seriesName: '실적',
        show: false,
        axisTicks: { show: true },
        axisBorder: { show: true, },
        title: { text: "실적" },
        labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} ${unit.unit}` } },
      },
      {
        opposite: true,
        seriesName: '향상률',
        axisTicks: { show: true },
        axisBorder: { show: true, },
        title: { text: "향상률" },
        labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} %`; } },
      },
      {
        opposite: true,
        seriesName: '향상률',
        show: false,
        axisTicks: { show: true },
        axisBorder: { show: true, },
        title: { text: "향상률" },
        labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} %`; } },
      },
    ],
    // yaxis: [
    //   {
    //     type: unit.unit,
    //     min: 0,
    //     tickAmount: 5,
    //     title: '1',
    //     labels: { formatter: (val) => { return checkEmptyNull(val, 0).toLocaleString() } }
    //   },
    //   {
    //     opposite: true,
    //     type: unit.unit,
    //     min: 0,
    //     tickAmount: 10,
    //     title: '2',
    //     labels: { formatter: (val) => { return checkEmptyNull(val, 0).toLocaleString() } }
    //   }
    // ],
  };
  const [_options, setOptions] = useState(options);

  const series = [{ name: '실적', type: 'bar', data: [] }];
  const [_series, setSeries] = useState(series);

  useEffect(() => {
    setUnit(() => { return props.unit });
    setDataList(() => { return props.contents });

    const data = contents.map(content => content.value);
    const accumulatedData = data.map((amount, index) => {
      let accumulatedAmount = (amount * 1);
      for (let i = 0; i < index; i++) {
        accumulatedAmount += (data[i] * 1);
      }
      return accumulatedAmount;
    })

    const currentList = [];
    const targetList = [];
    const achievementList = [];
    const goalRateList = [];
    /* 
    향상률(향상률) = ((실적 - 현재) / 현재) * 100 >증가목표 / ((현재 - 실적) / 현재) * 100 >감소목표
    목표달성율 = ((실적 - 현재) / (목표 - 현재)) * 100

    일일 실적 = data[i].value * 1
    일일 향상률 = ((일일 실적 - 일일 현재) / 일일 현재) * 100 >증가목표 / ((일일 현재 - 일일 실적) / 일일 현재) * 100 >감소목표
    일일 달성률 = ((일일 실적 - 일일 현재) / (일일 목표 - 일일 현재)) * 100
    */
    for (let i = 0; i < data.length; i++) {
      const day_current = BigNumber((props.unit.current * 1)).dividedBy(props.unit.cycle === 'WEEK' ? 7 : props.unit.cycle === 'MONTH' ? moment().daysInMonth() : 1).toNumber();
      const day_target = BigNumber((props.unit.target * 1)).dividedBy(props.unit.cycle === 'WEEK' ? 7 : props.unit.cycle === 'MONTH' ? moment().daysInMonth() : 1).toNumber();
      const day_value = (data[i] * 1);
      // const day_achievement = props.unit.realDtlCd === 'production' ?
      //   BigNumber(day_value).minus(BigNumber(day_current)).dividedBy(BigNumber(day_current)).multipliedBy(100).toNumber()
      //   : BigNumber(day_current).minus(BigNumber(day_value)).dividedBy(BigNumber(day_current)).multipliedBy(100).toNumber();
      let day_achievement, day_goalRate;

      if (props.unit.realDtlCd === 'production') { // 생산량 증가 (증가목표)
        day_achievement = BigNumber(day_value).minus(BigNumber(day_current)).dividedBy(BigNumber(day_current)).multipliedBy(100).toNumber();
        day_goalRate = BigNumber(day_value).minus(BigNumber(day_current)).dividedBy(BigNumber(day_target).minus(BigNumber(day_current))).multipliedBy(100).toNumber();
      } else if (props.unit.realDtlCd === 'shorten') { // 납기 단축 (감소목표)
        day_achievement = BigNumber(day_current).minus(BigNumber(day_value)).dividedBy(BigNumber(day_current)).multipliedBy(100).toNumber();
        day_goalRate = BigNumber(day_value).minus(BigNumber(day_current)).dividedBy(BigNumber(day_target).minus(BigNumber(day_current))).multipliedBy(100).toNumber();
      }
      // console.log(props.unit.realDtlCd, data, day_value, day_achievement, day_goalRate);
      currentList.push(day_current);
      targetList.push(day_target);
      achievementList.push(day_achievement);
      goalRateList.push(day_goalRate);
    }

    // 시리즈 데이터의 최대값 계산
    const maxSeries1 = Math.max(...data);  // 첫 번째 시리즈 최대값
    const maxSeries2 = Math.max(...targetList);  // 두 번째 시리즈 최대값

    const maxSeries3 = Math.max(...achievementList);  // 세 번째 시리즈 최대값
    const maxSeries4 = Math.max(...goalRateList);  // 네 번째 시리즈 최대값

    // 최대값 중에서 가장 큰 값 찾기
    const overallMax = Math.max(maxSeries1, maxSeries2);  // 4개의 시리즈 중 최대값
    const overallMax2 = Math.max(maxSeries3, maxSeries4);  // 4개의 시리즈 중 최대값

    const categories = contents.map(content => content.occurrenceDate);
    const returnOptionData = {
      ..._options,
      title: { ..._options.title, text: unit.kpiCode.kpiDtlNm },
      xaxis: { ..._options.xaxis, categories: categories },
      yaxis: [
        {
          seriesName: '실적',
          title: { text: "실적" },
          axisTicks: { show: true },
          axisBorder: { show: true, },
          // max: overallMax,
          labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} ${unit.unit}` } },
        },
        {
          seriesName: '실적',
          show: false,
          title: { text: "실적" },
          axisTicks: { show: true },
          axisBorder: { show: true, },
          // max: overallMax,
          labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} ${unit.unit}` } },
        },
        // {
        //   seriesName: '목표',
        //   show: false,
        //   axisTicks: { show: true },
        //   axisBorder: { show: true, },
        //   max: overallMax,
        //   labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} ${unit.unit}` } },
        // },
        {
          opposite: true,
          seriesName: '향상률',
          title: { text: "향상률" },
          axisTicks: { show: true },
          axisBorder: { show: true, },
          // max: overallMax2,
          labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} %`; } },
        },
        {
          opposite: true,
          seriesName: '향상률',
          show: false,
          title: { text: "향상률" },
          axisTicks: { show: true },
          axisBorder: { show: true, },
          // max: overallMax2,
          labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} %`; } },
        },
        // {
        //   opposite: true,
        //   seriesName: '달성률',
        //   show: false,
        //   axisTicks: { show: true },
        //   axisBorder: { show: true, },
        //   max: overallMax2,
        //   labels: { formatter: (val) => { return `${checkEmptyNull(val, 0).toLocaleString()} %`; } },
        // },
      ],
    };

    const returnSeriesData = [{ name: '실적', type: 'bar', data: data }];
    // if (props.unit.realDtlCd === 'production' && props.unit.cycle !== 'DAY') {
    //   returnSeriesData.push({ name: '누적', type: 'line', data: accumulatedData });
    // }
    returnSeriesData.push({ name: '목표', type: 'line', data: targetList });
    returnSeriesData.push({ name: '향상률', type: 'line', data: achievementList });
    returnSeriesData.push({ name: '달성률', type: 'line', data: goalRateList });

    setOptions(() => { return returnOptionData; });
    setSeries(() => { return returnSeriesData; });

    return () => { }
  }, [props]);
  useEffect(() => { }, [_options, _series]);

  const [_height, setHeight] = useState(250);
  useEffect(() => {
    const handleResize = () => { setHeight(() => { return Content.current.offsetHeight - 50 }); };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); }
  }, []);

  return (
    <div ref={Content} className='graph'>
      <ReactApexChart ref={KPIChart} options={_options} series={_series} /* type='line' */ width={'100%'} height={_height} />
    </div>
  );
};

export default KPIGraph;