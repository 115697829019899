import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BwipJs from 'bwip-js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled from 'styled-components';

import { barcodeActions_setContentType, barcodeActions_setPrintList, barcodeActions_setReset } from 'store/modules/actions/common/barcodeActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

/* contents */
import Element from 'pages/barcode/print/contents/Element';
import StockLog from 'pages/barcode/print/contents/StockLog';
import Lot from 'pages/barcode/print/contents/Lot';
import Serial from 'pages/barcode/print/contents/Serial';

/* content */
import BarcodeContents from 'pages/barcode/print/BarcodeContents';

const PrintBarcodeSection = styled.main`
  display: grid;
  grid-template-rows: 66px calc(100% - 66px);
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
  
  & .printBarcodeContents {
    border-top: 1px solid var(--MainNavy);
    box-sizing: border-box;
    position: relative;
    height: 100%;
    min-width: 100%;
    overflow: hidden;
    width: fit-content;

    .printSection {
      background-color: var(--white);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      box-sizing: border-box;
      display: unset;
      display: grid;
      gap: 15px;
      grid-template-rows: 30px calc(100% - 160px) 100px;
      height: 100%;
      max-width: 350px;
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
      transition: 0.5s;
      width: calc(100% - 25px);
      z-index: 900;
      
      &.disactive {
        transform: translateX(100%);
        div {display: none;}
        .handleButton {display: flex;}
      }

      .handleButton {
        align-items: center;
        background-color: var(--MainNavy);
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        color: var(--white);
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        height: 50px;
        justify-content: flex-end;
        left: -25px;
        padding-inline-end: 6px;
        padding-block-end: 3px;
        position: absolute;
        text-align: center;
        top: calc(50% - 12.5px);
        width: 25px;
      }

      .printSectionHeader {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .printContentSection {
        box-sizing: border-box;
        height: 100%;
        overflow-y: scroll;
        width: 100%;
        
        &::-webkit-scrollbar {height: 12px; width: 12px;}
        &::-webkit-scrollbar-track {background-color: var(--gray-100);}
        &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
      }

      .printContentList {
        display: grid;
        gap: 10px;
      }
      
      .printContent {
        align-items: center;
        background-color: var(--white);
        border: 1px solid var(--gray-200);
        box-sizing: border-box;
        cursor: pointer;
        display: grid;
        grid-template-rows: 30px 60px auto;
        height: 200px;
        justify-items: center;
        overflow: hidden;
        padding-block: 5px 10px;
        width: 95%;

        &:hover{
          border-color: var(--MainRed);
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        & img {
          max-width: 80%;
          height: 60px;
          margin: 0px auto;
        }
        & p {
          font-size: 0.8em;
          /* line-height: 0.8em; */
          text-align: center;
          white-space: nowrap;
          word-break: keep-all;
          width: 100%;

          &:first-child {
            font-weight: 600;
            line-height: 2.3em;
            word-spacing: 1px;
          }
        }
        & table {
          align-self: flex-start;
          /* border: 1px solid var(--Text); */
          /* box-sizing: border-box; */
          /* border-collapse: collapse; */
          margin: 0px auto;
          width: 80%;

          & td {
            /* border: 1px solid var(--Text); */
            box-sizing: border-box;
            font-size: 0.8em;
            line-height: 1.1em;
            text-align: left;

            &:first-child {
              font-weight: 600;
              text-align: center;
            }
          }
        }
      }

      .printButtons {
        display: flex;
        gap: 5px;
      }
      .printButton {
        align-items: center;
        background-color: var(--MainNavy);
        border-radius: 5px;
        color: var(--white);
        cursor: pointer;
        display: flex;
        font-size: 1.2em;
        font-weight: bold;
        justify-content: center;
        line-height: 1em;
        width: 100%;
      }
    }
  }
`;

const PrintBarcode = () => {
  const dispatch = useDispatch();
  const { barcodeReducer, userReducer } = useSelector((state) => state);

  const [_contentTypeList, setContentTypeList] = useState([
    { code: 'A', type: 'element', name: '기준정보' },
    { code: 'B', type: 'stockLog', name: '재고상세이력' },
    { code: 'C', type: 'lot', name: 'LOT' },
    { code: 'D', type: 'serial', name: 'Serial' },
  ]);
  const [_contentType, setContentType] = useState(barcodeReducer.contentType);
  const [_printSecStatus, setPrintSecStatus] = useState(false);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
  }, [])
  useEffect(() => {
    setContentType(() => { return barcodeReducer.contentType });
  }, [barcodeReducer.contentType]);
  useEffect(() => { }, [_printSecStatus]);
  useEffect(() => { }, [barcodeReducer.printList]);

  /* 컨텐츠 변경 */
  const handleContentType = (type) => {
    dispatch(barcodeActions_setContentType(type));
  }

  /* 프린트 섹션 핸들링 */
  const handlePrintSection = () => {
    setPrintSecStatus((prev) => { return !prev });
  }

  /* 바코드 변환 */
  const generateBarcode = (content) => {
    const canvas = document.createElement('canvas')
    BwipJs.toCanvas(canvas, {
      backgroundcolor: 'FFFFFF',
      height: 10,
      includetext: false,
      // padding: 10,
      scale: 2,
      showborder: false,
      // showborder: true,
      textxalign: 'center',
      textyoffset: 1,

      bcid: 'code128',
      text: content,
      // text: `a_000000000`,
    })
    return canvas.toDataURL('image/png')
  }

  /* 바코드 출력 */
  const actPrintBarcode = (size) => {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    let format = [60, 40];
    if (size === 40) format = [40, 25];
    if (size === 80) format = [80, 60];

    const doc = new jsPDF({
      orientation: 'ㅣ',
      unit: 'mm',
      format: format
    });

    document.getElementsByName('printContent').forEach(async (content, index) => {
      const thisHeight = content.offsetHeight;
      const thisWidth = content.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(content, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        let width = doc.internal.pageSize.getWidth();
        let height = 40;
        if (size === 40) height = 25;
        if (size === 80) height = 56.5;
        width = ratio * height;

        const margin = size === 40 ? 2 : 60 ? 2 : 0;
        const position = 0;

        doc.addImage(imgData.replace('data:image/png;base64,', ''), 'PNG', margin, position, width, height);
        if (index < document.getElementsByName('printContent').length - 1) {
          doc.addPage();
        }
      });

      if (index === document.getElementsByName('printContent').length - 1) {
        style.remove();
        window.open(doc.output('bloburl'));
      }
    });

  }

  /* 바코드 삭제 */
  const actDelete = (content, index) => {
    const printList = [...barcodeReducer.printList];
    printList.splice(index, 1);
    dispatch(barcodeActions_setPrintList(printList));
  }

  /* 바코드 복사 */
  const actCopy = (content, index) => {
    const promptText = `복사할 수량을 입력해 주세요.`;
    const amount = window.prompt(promptText, 0);

    const newData = [];
    if (checkEmptyNull(amount, false) && !isNaN(amount)) {
      for (let i = 0; i < Number(amount); i++) {
        newData.push(content);
      }
    }

    const printList = [...barcodeReducer.printList];
    printList.splice(index, 1, ...newData);
    dispatch(barcodeActions_setPrintList(printList));
  }

  return (
    <Grid2Body contents={
      <PrintBarcodeSection className='Main'>
        <NavBar
          title={
            <div className='Title'>
              <Link to='/barcode/scan' className='btn-barcode'>바코드 스캔</Link>
              <Link to='/barcode/out' className='btn-barcode btn-barcode-active'>바코드 출력</Link>
            </div>
          }
          buttons={
            <>
              {_contentTypeList.map((content, index) => {
                return (
                  <button
                    key={index + '_contentType'}
                    className={_contentType === content.type ? 'btn-barcode btn-barcode-active' : 'btn-barcode'}
                    onClick={() => { handleContentType(content.type) }}
                  >
                    <b>{content.code}</b>
                    {content.name}
                  </button>
                );
              })}
            </>
          }
          nav={''}
        />

        <div className='printBarcodeContents'>
          {(() => {
            switch (_contentType) {
              case 'element': return <Element printList={barcodeReducer.printList} />;
              case 'stockLog': return <StockLog printList={barcodeReducer.printList} />;
              case 'lot': return <Lot printList={barcodeReducer.printList} />;
              case 'serial': return <Serial printList={barcodeReducer.printList} />;

              default: return null;
            }
          })()}

          <div className={_printSecStatus ? 'printSection' : 'printSection disactive'}>
            <div className='handleButton' onClick={handlePrintSection}>||</div>

            <div className='printSectionHeader'>
              <h4>바코드 출력 리스트</h4>
              <button className='btn-set' onClick={() => { dispatch(barcodeActions_setReset()) }}>초기화</button>
            </div>

            <div className='printContentSection'>
              <div className='printContentList'>
                {checkNullArray(barcodeReducer.printList, []).map((content, index) => {
                  return (
                    <div key={index + '_printContent'} name='printContent' className='printContent' title='마우스 왼쪽 클릭 <삭제> / 오른쪽 클릭 <복사>'
                      onClick={() => { actDelete(content, index) }}
                      onContextMenu={() => { actCopy(content, index) }}
                    >
                      <p>{userReducer.company.companyName}</p>
                      <BarcodeContents content={content} generateBarcode={generateBarcode} />
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='printButtons'>
              <button className='printButton' onClick={() => { actPrintBarcode(40) }}>40x25</button>
              <button className='printButton' onClick={() => { actPrintBarcode(60) }}>60x40</button>
              <button className='printButton' onClick={() => { actPrintBarcode(80) }}>80x60</button>
            </div>
          </div>
        </div>
      </PrintBarcodeSection>
    } />
  );
};

export default PrintBarcode;