import styled from "styled-components";

export const ModuleManagementComponent = styled.section`
  background-color: var(--white);
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 120px), calc(100% - 240px)) 60px;

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }

    p {
      font-size: 18px;
      font-weight: 400;
    }

    select {
      border: 1px solid var(--gray-200);
      border-radius: 8px;
      box-sizing: border-box;
      height: 35px;
      margin-right: 4px;
      padding: 0px 10px;
      width: 250px;
    }
  }

  & .TableSection {
    grid-row-start: 2;
    grid-row-end: 4;
    margin: auto;
    width: fit-content;
    & table {
      min-width: unset;
      width: fit-content;

      th, td {
        border: none;
        font-size: 18px;
        min-width: unset;
        padding: 18px 36px;
        text-align: left;
      }

      th {
        background-color: #EDF1F5;
        color: var(--Text);
      }
  
      td {
        background: unset;

        & input[type="radio"] {
          cursor: pointer;
          transform: scale(1.2);
        }
      }

      & tbody tr {
        box-sizing: border-box;
        border-bottom: 1px solid #99B0CB;
      }
    }
  }
`;