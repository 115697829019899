import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const ProcurementSection = styled.main`
  & input[name='accountName'] {
    background-color: var(--ThirdBlue);
    color: var(--white);
    &::placeholder {color: var(--white);}
  }

  & .TableSection {
    max-height: 350px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const CopyProcurement = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const copyContent = checkNullParse(searchParams.get('copyContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사
    userId: userReducer.user.id, // 작성자

    procurementDate: DateFormat(checkEmptyNull(copyContent.procurementDate, '')), // 발주일자
    scheduledEndDate: DateFormat(checkEmptyNull(copyContent.scheduledEndDate, '')), // 입고예정일자

    procurementStatus: 'waiting', // 발주상태
    procurementCode: checkEmptyNull(copyContent.procurementCode, ''), // 발주코드
    procurementName: checkEmptyNull(copyContent.procurementName, ''), // 발주이름

    accountId: copyContent?.account?.accountId, // 거래처
    accountName: copyContent?.account?.accountName, // 거래처

    procurementPrice: checkEmptyNull(copyContent.procurementPrice, 0), // 발주총금액

    customizedContent: JSON.stringify({}), // 커스텀

    procurementElementList: [], // 발주물품
    deleteProcurementElementList: [], // 발주물품 삭제
  });

  const [_account, setAccount] = useState(checkNullObject(copyContent?.account, {}));

  const [_procurementElements, setProcurementElements] = useState(() => {
    const procurementElementList = checkNullArray(copyContent.procurementElementList, []);
    const returnData = procurementElementList.map((element) => {
      if (checkNullObject(element.element, false)) {
        const elementData = {
          ...element,

          elementId: element.element.elementId,
          elementName: element.element.elementName,
          elementCode: element.element.elementCode,
          scheduledInputStock: element.element.scheduledInputStock,
          elementSafeStock: element.element.elementSafeStock,
          elementStock: element.element.elementStock,

          price: checkEmptyNull(element.element.price, 0), // 단가
          calPrice: element.price, // 금액
        };

        return elementData;
      } else {
        return element;
      }
    });
    return returnData;
  });

  const [_modalStatus, setModalStatus] = useState(false);
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('202') === true || // procurement
      thisItem.authority.startsWith('205') === true, // procurement
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_account, _procurementElements]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };

  const handleProcurementPrice = (e) => {
    const { name, value } = e.target;
    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;
    setFormData((prev) => { return { ...prev, [name]: BigNumber(eventValue).toNumber() } });
  };

  const handleElementInput = (e, index, element) => {
    const { value } = e.target;
    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;

    const prevData = [..._procurementElements];
    switch (e.target.name) {
      case 'quantity':
        const newData_quantity = {
          ...element,
          quantity: BigNumber(eventValue).toNumber(),
          calPrice: BigNumber(eventValue).multipliedBy(BigNumber(checkEmptyNull(element.price, 0))).toNumber(),
        };
        prevData.splice(index, 1, newData_quantity);
        break;

      case 'calPrice':
        const newData_calPrice = {
          ...element,
          calPrice: BigNumber(eventValue).toNumber(),
        };
        prevData.splice(index, 1, newData_calPrice);
        break;

      default: return;
    }

    const sumArray = prevData.map((item) => item.calPrice);
    const setSumArray = sumArray.filter((item) => item !== null);
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

    setFormData((prev) => { return { ...prev, procurementPrice: sum } });
    setProcurementElements(() => { return prevData });
  };

  const deleteElement = (index, element) => {
    setProcurementElements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      const sumArray = prevData.map((item) => item.calPrice);
      const setSumArray = sumArray.filter((item) => item !== null);
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

      setFormData((prev) => { return { ...prev, procurementPrice: sum } });
      return prevData;
    });
  };

  const actCopy = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.procurementCode, false)) return alert('발주코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.procurementName, false)) return alert('발주이름을 입력해주세요.');
    if (!checkEmptyNull(_account.accountId, false)) return alert('거래처를 선택해주세요.');

    const BodyToPost = {
      ..._formData,
      accountId: _account?.accountId,

      procurementElementList: _procurementElements.map((element) => {
        const returnData = {
          elementId: element.elementId,
          quantity: element.quantity,
          price: element.calPrice,
        };
        return returnData;
      }),
    };
    await procurementApi.createProcurement(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.createProcurement : ', response);
      alert('발주를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <ProcurementSection className="Main">
          <NavBar title={'발주관리'} nav={''} />

          <Form
            title={
              <>
                발주<br />
                추가<br />
                <p style={{ color: 'var(--MainNavy)', fontSize: '16px', fontWeight: '400', margin: '10px 0px' }}>* 복사된 정보입니다.</p>
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actCopy}>저장</button>
                <button className="formButton cancle" onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>발주물품</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--MainBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => { setModalStatus(true) }}
                    >
                      발주물품 추가
                    </div>
                  </div>

                  <TableSection content={
                    <table>
                      <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                        <tr>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>단가</th>
                          <th>수량</th>
                          <th>금액</th>
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_procurementElements, false) && _procurementElements.map((element, index) => {
                          return (
                            <tr key={index + '_ProcurementElements'} data-key={element.elementId}>
                              <td className={checkEmptyNull(element.elementName, false) && element.elementName.startsWith('*') ? 'outsourcingText' : ''}>
                                {element.elementName}
                              </td>
                              <td>{element.elementCode}</td>
                              <td>{checkEmptyNull(element.price, 0).toLocaleString()}</td>
                              <td>
                                <input
                                  type="number"
                                  name="quantity"
                                  min={0}
                                  value={element.quantity}
                                  onInput={(e) => { handleElementInput(e, index, element) }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="calPrice"
                                  min={0}
                                  value={element.calPrice}
                                  onInput={(e) => { handleElementInput(e, index, element) }}
                                />
                              </td>
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <DelButton onClick={() => { deleteElement(index, element) }}><CloseButtonIcon /></DelButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                    formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                  />

                  <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>※ 발주수량을 꼭! 입력해 주세요.</div>
                </div>

                <div className="formBox">
                  <h4>발주코드</h4>
                  <input
                    type="data"
                    name="procurementCode"
                    placeholder="* 발주코드..."
                    data-required="required"
                    value={_formData.procurementCode}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주이름</h4>
                  <input
                    type="text"
                    name="procurementName"
                    placeholder="* 발주이름..."
                    data-required="required"
                    value={_formData.procurementName}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <div style={{ display: 'grid', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>벤더사</h4>
                    <div className="formButton"
                      style={{ borderRadius: '5px', width: '150px', backgroundColor: 'var(--ThirdBlue)' }}
                      onClick={() => { setAccountModalStatus(true) }}
                    >
                      공급사 선택
                    </div>
                  </div>
                  <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%' }}>
                    <input
                      type="text"
                      name="accountName"
                      value={checkEmptyNull(_account?.accountName, '')}
                      readOnly
                      placeholder="공급사를 선택해주세요."
                    />
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', height: '40px', width: '50px', }}
                      onClick={() => { setAccount(() => { return {} }) }}
                    >
                      삭제
                    </div>
                  </div>
                </div>

                <div className="formBox">
                  <h4>발주일자</h4>
                  <input
                    type="date"
                    name="procurementDate"
                    value={_formData.procurementDate}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>입고예정일자</h4>
                  <input
                    type="date"
                    name="scheduledEndDate"
                    value={_formData.scheduledEndDate}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주총금액</h4>
                  <input
                    type="number"
                    name="procurementPrice"
                    placeholder="* 발주총금액..."
                    value={_formData.procurementPrice}
                    onInput={handleProcurementPrice}
                  />
                </div>
              </>
            }
            formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
          />
        </ProcurementSection>

        {_accountModalStatus === true && (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data });
              setTimeout(setAccountModalStatus(false), 1000);
            }}
            open={_accountModalStatus}
            close={() => { setAccountModalStatus(false) }}
          ></SelectOneAccount>
        )}

        {_modalStatus === true && (
          <SelectElements
            buttonEvent={(data) => {
              console.log('data : ', data);
              const procurementElementIds = _procurementElements.map((thisItem) => thisItem.elementId);
              const newElements = data.map((element) => {
                if (procurementElementIds.indexOf(element.elementId) !== -1) return null;
                else return { ...element, calPrice: 0 };
              });
              const setNewElements = newElements.filter((thisItem) => thisItem !== null);
              setProcurementElements((prev) => { return [...prev, ...setNewElements] });
              setTimeout(setModalStatus(false), 1000);
            }}
            buttonTitle="물품 선택"
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        )}
      </>
    }
    />
  );
};

export default CopyProcurement;
