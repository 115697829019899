import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { userReducer_setUser } from 'store/modules/actions/user/userActions';

import { companyApi } from 'api/apis/companyApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import UpdatePassword from 'pages/user/myProfile/modal/UpdatePassword';

const MyProfileSection = styled.main`
  .formBox.disabled {
    align-items: center;
    column-gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    h4 {
      display: inline-block;
      position: relative;
      width: 100px;
      &::after {
        content: ':';
        position: absolute;
        right: 0px;
      }
    }
  }

  .master select {
    background-color: var(--color-1);
    border-color: var(--color-1);
    color: var(--white);
  }
`;
const ProfileSection = styled.section`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: flex-start;
  width: 100%;

  &>div {
    display: grid;
    gap: 10px;
    height: fit-content;
    width: fit-content;
    &:first-child {padding-block-start: 25px;}

    .formBox {
      padding: unset;
      width: fit-content;

      input {width: fit-content;}
    }
  }
`;

const MyProfile = () => {
  /* ========================================================================= #1들 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_myProfile, setMyProfile] = useState({ ...userReducer.user });

  const [_companyList, setCompanyList] = useState([]);
  const [_masterCompanyId, setMasterCompanyId] = useState(userReducer.company.companyId);

  const [_pwModalStatus, setPwModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    
    getMyData();
    if (userReducer.user.role === 'ROLE_MASTER') getCompanyList();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getMyData = async () => {
    await userApi.getUserMe().then((response) => {
      if (response === undefined) return;
      console.log('userApi.getUserMe : ', response);
      setMyProfile(() => { return response.data });
    });
  };

  const getCompanyList = async () => {
    await companyApi.searchCompanies().then((response) => {
      if (response === undefined) return;
      console.log('companyApi.getCompanies : ', response);
      setCompanyList(() => { return response.data });
    });
  };

  /* ========================================================================= #5 */
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setMyProfile((prev) => {return { ...prev, [name]: value };});
  };
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setMyProfile((prev) => {return {...prev, phoneNumber: phoneNumber};});
  };

  const actUpdate = async () => {
    if (!checkEmptyNull(_myProfile.name, false)) { return alert('이름을 입력해주세요.'); }
    await userApi.updateUser(_myProfile.id, _myProfile).then(response => {
      if (response === undefined) return;
      console.log('userApi.updateUser : ', response);
      alert('내 정보를 저장했습니다.');
    })
    if (_myProfile.role === 'ROLE_MASTER') {
      if (_myProfile.companyId !== _masterCompanyId) updateMasterCompany();
    }
  }
  const updateMasterCompany = async () => {
    const findCompany = _companyList.findIndex((company) => company.companyId === _masterCompanyId);
    const masterComapny = _companyList[findCompany];
    const BodyToPut = {companyId: _masterCompanyId};
    console.log('BodyToPut : ', BodyToPut, masterComapny, _myProfile);
    await userApi.updateUserMe(BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('put/user/selfCompany-response : ', response.data);
      dispatch(userReducer_setUser({}));
      dispatch(mallSiteUserActions_setUser({}));
      navigate('/', { replace: true });
    }).catch((error) => {
      console.log('put/user/selfCompany-error :', error);
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <MyProfileSection className="Main">
          <NavBar
            title={'내 정보'}
            buttons={
              <>
                {checkEmptyNull(_myProfile.loginId, false) ? (
                  <button className='btn-edit' onClick={(e) => {e.preventDefault(); setPwModalStatus(() => {return true})}}>비밀번호 변경</button>
                ) : null}
                <button className='btn-save' onClick={actUpdate}>저장</button>
              </>
            }
            nav={''}
          />

          <Form
            title={<>내<br />정보</>}
            buttons={<></>}
            forms={
              <>
                <ProfileSection>
                  <div>
                    <div className="formBox disabled">
                      <h4>팀</h4>
                      <p>{checkEmptyNull(_myProfile?.team.teamName, '')}</p>
                    </div>

                    {(() => {
                      if (checkEmptyNull(_myProfile.loginId, false)) {
                        return (
                          <div className="formBox disabled">
                            <h4>아이디</h4>
                            <p>{checkEmptyNull(_myProfile.loginId, '')}</p>
                          </div>
                        ); 
                      } else return null;
                    })()}

                    <div className="formBox disabled">
                      <h4>이메일</h4>
                      <p>{checkEmptyNull(_myProfile.email, '')}</p>
                    </div>
                  </div>

                  <div>
                    <div className="formBox">
                      <h4>이름</h4>
                      <input
                        type="text"
                        name="name"
                        data-required="required"
                        value={checkEmptyNull(_myProfile.name, '')}
                        onInput={handleInputValue}
                      />
                    </div>

                    <div className="formBox">
                      <h4>연락처</h4>
                      <input
                        type="text"
                        name="phoneNumber"
                        data-required="required"
                        placeholder="연락처 - 를 제외하고 입력해주세요. ex) 01012341234"
                        maxLength={14}
                        value={checkEmptyNull(_myProfile.phoneNumber, '')}
                        onInput={makePhoneNumber}
                      />
                    </div>

                    {userReducer.user.role === 'ROLE_MASTER' ? (
                      <>
                        <div className="formBox master">
                          <h4>company</h4>
                          <select
                            name="role"
                            data-required="required"
                            value={_masterCompanyId}
                            onChange={(e) => {setMasterCompanyId(() => { return e.target.value; });}}
                          >
                            {_companyList.map((company) => (<option key={company.companyId} value={company.companyId}>{company.companyName}</option>))}
                          </select>
                        </div>

                        <div className="formBox master">
                          <h4>role</h4>
                          <select
                            name="role"
                            data-required="required"
                            value={_myProfile.role}
                            onChange={handleInputValue}
                          >
                            <option value="ROLE_GUEST">ROLE_GUEST</option>
                            <option value="ROLE_USER">ROLE_USER</option>
                            <option value="ROLE_ADMIN">ROLE_ADMIN</option>
                            <option value="ROLE_MASTER">ROLE_MASTER</option>
                          </select>
                        </div>
                      </>
                    ) : null}
                  </div>
                </ProfileSection>
              </>
            }
          />
        </MyProfileSection>

        {_pwModalStatus ? (
          <UpdatePassword
            open={_pwModalStatus}
            close={() => {setPwModalStatus(() => {return false})}}
          />
        ) : null}
      </>
    }
    />
  );
};

export default MyProfile;
