import { AxiosInterceptor } from 'api/core';

export const shipmentInspection = {
  getShipmentInspection: (shipmentInspectionId) => AxiosInterceptor.get('shipmentInspection/' + shipmentInspectionId),
  searchShipmentInspection: (paging, body) => AxiosInterceptor.post('shipmentInspection/search' + paging, body),
  createShipmentInspection: (body) => AxiosInterceptor.post('shipmentInspection', body),
  copyShipmentInspection: (body) => AxiosInterceptor.post('shipmentInspection', body),
  updateShipmentInspection: (shipmentInspectionId, body) => AxiosInterceptor.put('shipmentInspection/' + shipmentInspectionId, body),
  deleteShipmentInspection: (shipmentInspectionId) => AxiosInterceptor.delete('shipmentInspection/' + shipmentInspectionId),
};
