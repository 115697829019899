import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { shipmentInspection } from 'api/apis/shipmentInspection/shipmentInspection';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td.blankCell {
      background-color: var(--Bg);
      opacity: 0.8;
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectOneShipmentInspection = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ItemCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_shipmentInspectionList, setShipmentInspectionList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});

  const [_searchData, setSearchData] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageSize, setPageSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getShipmentInspectionList(0);

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentInspectionList(_pageNum - 1);
      ItemCheckBox.current.checked = false;
    }

    return () => { };
  }, [_pageNum]);

  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getShipmentInspectionList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
      BodyToPost[_searchOption] = _searchText;
    }

    await shipmentInspection.searchShipmentInspection(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('shipmentInspection.searchShipmentInspection : ', response);

        setShipmentInspectionList(() => {
          return response.data.content;
        });

        setPageSize(() => {
          return response.data.totalElements
        })
      })
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    if (isChecked) {
      setCheckedItem(() => {
        return item;
      });
    } else {
      setCheckedItem(() => {
        return {}
      })
    }
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => {
      return 1;
    });
    await getShipmentInspectionList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchData(() => {
      return {};
    });
    setSearchOption(() => {
      return '';
    });
    setSearchText(() => {
      return '';
    });

    setPageNum(() => {
      return 1;
    });
    await getShipmentInspectionList(0, true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공정 선택</ModalTitle>
              <ModalHeaderButtons></ModalHeaderButtons>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={_searchOption}
                        onChange={(e) => {
                          const searchOption = e.target.value;
                          setSearchOption(() => {
                            return searchOption;
                          })
                        }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'shipmentInspectionCode', optionText: '시험성적서 이름' },
                          { optionValue: 'shipmentInspectionName', optionText: '프로젝트' },
                        ].map((option) => {
                          return (
                            <option
                              key={option.optionValue + '_shipmentInspectionSearchOption'}
                              value={option.optionValue}
                            >
                              {option.optionText}
                            </option>
                          );
                        })}
                      </select>

                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={_searchText}
                        onInput={(e) => {
                          const searchText = e.target.value;
                          setSearchText(() => {
                            return searchText;
                          })
                        }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                }
                nav={''}
              />

              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                        <th>시험성적서 이름</th>
                        <th>프로젝트</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkNullArray(_shipmentInspectionList, []).map((shipmentInspection, index) => {
                        return (<tr key={index + '_shipmentInspection'}>
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="shipmentInspections"
                              checked={checkEmptyNull(_checkedItem.shipmentInspectionId, false) ? shipmentInspection.shipmentInspectionId === _checkedItem.shipmentInspectionId : false}
                              onChange={(e) => {
                                e.preventDefault();
                                const isChecked = e.target.checked;
                                handleCheckedItem(
                                  shipmentInspection,
                                  isChecked,
                                );

                                if (isChecked) {
                                  e.target.checked = true;
                                } else {
                                  e.target.checked = false;
                                }
                              }}
                              onClick={e => {
                                e.stopPropagation();
                              }}
                            />
                          </td>
                          <td>{shipmentInspection.shipmentInspectionCode}</td>
                          <td>{shipmentInspection.shipmentInspectionName}</td>
                        </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => {
                  return page;
                });
              }}
            />

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  props.buttonEvent(_checkedItem);
                }}
              >
                {props.buttonTitle}
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectOneShipmentInspection;
