import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

import { checkEmptyNull } from 'components/checkValues/checkValues';

const KPIGraph = (props) => {
  const { unit, contents } = props;
  const Content = useRef();
  const KPIChart = useRef();

  const [_unit, setUnit] = useState(unit);
  const [_dataList, setDataList] = useState(contents);

  const options = {
    chart: {
      id: moment().format('YYYY-MM-DD') + '_KPI',
      toolbar: { show: true },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    },

    annotations: {
      yaxis: [
        {
          y: unit.target,
          borderColor: '#9C27B0',
          label: {
            borderColor: '#9C27B0',
            style: { background: '#9C27B0', color: '#fff' },
            text: '목표치',
          }
        }
      ]
    },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
      padding: { right: 30 },
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    legend: { show: 'bottom' },
    markers: { size: [0, 5, 0] },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: unit.kpiCode.kpiDtlNm,
      align: 'left'
    },
    xaxis: {
      type: '일',
      categories: [],
      tickPlacement: 'on',
    },
    yaxis: {
      type: unit.unit,
      min: 0,
      tickAmount: 5,
      labels: { formatter: (val) => { return checkEmptyNull(val, 0).toLocaleString() } }
    },
  };
  const [_options, setOptions] = useState(options);

  const series = [{ name: '일별', type: 'bar', data: [] }];
  const [_series, setSeries] = useState(series);

  useEffect(() => {
    setUnit(() => { return props.unit });
    setDataList(() => { return props.contents });

    const data = props.contents.map(content => content.value);
    const accumulatedData = data.map((amount, index) => {
      let accumulatedAmount = (amount * 1);
      for (let i = 0; i < index; i++) {
        accumulatedAmount += (data[i] * 1);
      }
      return accumulatedAmount;
    })

    setOptions((prev) => {
      const categories = props.contents.map(content => content.occurrenceDate);
      const returnData = {
        ...prev,
        annotations: {
          yaxis: [
            {
              y: props.unit.target,
              borderColor: '#9C27B0',
              label: {
                borderColor: '#9C27B0',
                style: { background: '#9C27B0', color: '#fff' },
                text: '목표치',
              }
            }
          ]
        },
        title: { ...prev.title, text: props.unit.kpiCode.kpiDtlNm },
        xaxis: { ...prev.xaxis, categories: categories },
        yaxis: { ...prev.yaxis, type: props.unit.unit, },
      };

      return returnData;
    })
    setSeries(() => {
      const targetList = [];
      for (let i = 0; i < data.length; i++) {
        targetList.push((props.unit.target * 1));
      }
      const returnData = [{ name: '일별', type: 'bar', data: data }];
      if (props.unit.realDtlCd === 'production') {
        returnData.push({ name: '누적', type: 'line', data: accumulatedData });
      }
      return returnData;
    })

    return () => { }
  }, [props]);
  useEffect(() => { }, [_options, _series]);

  const [_height, setHeight] = useState(250);
  useEffect(() => {
    const handleResize = () => { setHeight(() => { return Content.current.offsetHeight - 50 }); };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); }
  }, []);

  return (
    <div ref={Content} className='graph'>
      <ReactApexChart ref={KPIChart} options={_options} series={_series} type='line' width={'100%'} height={_height} />
    </div>
  );
};

export default KPIGraph;