import { AxiosInterceptor } from 'api/core';

export const processApi = {
  searchProcess: (paging, body) =>
    AxiosInterceptor.post('process/search' + paging, body),
  createProcess: (body) => AxiosInterceptor.post('process', body),
  uploadProcess: (body) => AxiosInterceptor.post('processes', body),
  updateProcess: (processId, body) =>
    AxiosInterceptor.put('process/' + processId, body),
  deleteProcess: (processId) => AxiosInterceptor.delete('process/' + processId),
};
