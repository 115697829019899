import { AxiosInterceptor } from 'api/core';

export const attachFileApi = {
  searchAttachFile: (paging, body) =>
    AxiosInterceptor.post('/attachFile/search' + paging, body),
  createAttachFile: (body) => AxiosInterceptor.post('/attachFile', body),
  updateAttachFile: (attachFileId, body) =>
    AxiosInterceptor.put('/attachFile/' + attachFileId, body),
  deleteAttachFile: (attachFileId) =>
    AxiosInterceptor.delete('/attachFile/' + attachFileId),
};
