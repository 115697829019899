import { MallAxiosInterceptor } from 'api/core';

export const qna = {
  getMallQnA: (mallQnAId) => MallAxiosInterceptor.get('mallQnA/' + mallQnAId),
  searchMallQnA: (paging, body) => MallAxiosInterceptor.post('mallQnA/search' + paging, body),
  createMallQnA: (body) => MallAxiosInterceptor.post('mallQnA', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  updateMallQnA: (mallQnAId, body) => MallAxiosInterceptor.put('mallQnA/' + mallQnAId, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  deleteMallQnA: (mallQnAId) => MallAxiosInterceptor.delete('mallQnA/' + mallQnAId),
};
