import { AxiosInterceptor } from 'api/core';

export const shipmentApi = {
  getShipment: (shipmentId) => AxiosInterceptor.get('shipment/' + shipmentId),
  searchShipment: (paging, body) =>
    AxiosInterceptor.post('shipment/search' + paging, body),
  createShipment: (body) => AxiosInterceptor.post('shipment', body),
  updateShipment: (shipmentId, body) =>
    AxiosInterceptor.put('shipment/' + shipmentId, body),
  deleteShipment: (shipmentId) =>
    AxiosInterceptor.delete('shipment/' + shipmentId),
};
