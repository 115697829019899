import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { procurementActions_setEndDate, procurementActions_setIsPushedSearchButton, procurementActions_setPageNumber, procurementActions_setProcurementAccount, procurementActions_setProcurementElementList, procurementActions_setProcurementStatus, procurementActions_setReset, procurementActions_setSearchOption, procurementActions_setSearchText, procurementActions_setStartDate, procurementActions_setTotalSize, procurementActions_setViewScale } from 'store/modules/actions/common/procurementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProcurementRow from 'components/row/ProcurementRow';
import PutIncompleteElementModal from 'pages/procurement/modal/PutIncompleteElementModal';
import PutProcurementElementModal from 'pages/procurement/modal/PutProcurementElementModal';
import ProcurementPdfModal from 'pages/procurement/modal/ProcurementPdfModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }

    & .statusButtons {display: flex; gap: 5px;}
  }
`;


const ProcurementTable = styled.table`
  thead tr {border: none;}
  tbody {
    tr {border-bottom: 1px solid var(--MainBlue);}
    td {border: 1px solid var(--gray-200); height: fit-content;}
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 12px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Procurement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, procurementReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_procurementList, setProcurementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_putProcurementElementModalStatus, setPutProcurementElementModalStatus] = useState(false);
  const [_createNotInStockModalStatus, setCreateNotInStockModalStatus] = useState(false);
  const [_printProcurementModalStatus, setPrintPocurementModalStatus] = useState(false);
  const [_eventProcurement, setEventProcurement] = useState({});

  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('202') === true || // procurement
      thisItem.authority.startsWith('205') === true, // procurement
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
    }

    return () => { };
  }, [procurementReducer.pageNumber]);

  useEffect(() => { }, [procurementReducer]);

  /* ====================================================================== #4 */
  const getProcurementList = async (page, procurementStatus, isPushedSearchButton) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (checkEmptyNull(procurementStatus, false) && procurementStatus !== 'all') {
      BodyToPost.procurementStatus = procurementStatus;
    }
    if (checkEmptyNull(procurementReducer.startDate, false) && checkEmptyNull(procurementReducer.endDate, false)) {
      const startDate = new Date(procurementReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.procurementStartDate = setStartDate;

      const endDate = new Date(procurementReducer.endDate);
      // endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.procurementEndDate = setEndDate;

      // 시작 날짜가 종료날짜보다 클 때
      if (procurementReducer.startDate > procurementReducer.endDate) {
        const reverseStartDate = new Date(procurementReducer.startDate);
        // reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(procurementReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.procurementStartDate = setReversetEndDate;
        BodyToPost.procurementEndDate = setReverseStartDate;
      }
    }
    if (isPushedSearchButton && checkEmptyNull(procurementReducer.searchOption, false) && checkEmptyNull(procurementReducer.searchText, false)) {
      BodyToPost[procurementReducer.searchOption] = procurementReducer.searchText;
    }

    await procurementApi.searchProcurement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);
      setProcurementList(() => { return response.data.content });
      dispatch(procurementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleTitleProcurementStatus = async (procurementStatus) => {
    dispatch(procurementActions_setProcurementStatus(procurementStatus));
    dispatch(procurementActions_setPageNumber(1));
    await getProcurementList(0, procurementStatus);
  }
  const handleCheckedItem = (procurement, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(procurement);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(procurementActions_setPageNumber(1));
    dispatch(procurementActions_setIsPushedSearchButton(true));
    await getProcurementList(0, procurementReducer.procurementStatus, true);
  };

  /* 엑셀 다운로드 모달창 */
  const actExcelDownload = () => {
    setExcelDownloadModalStatus(true);
  };

  /* 발주상태 변경 */
  const handleProcurementStatus = async (procurement, index) => {
    if (!checkNullObject(procurement.account, false)) return alert('거래처 선택후 상태변경이 가능합니다.');

    const procurementIndex = index;

    let eventStatusText = '';
    let eventStatus = '';
    switch (procurement.procurementStatus) {
      case 'waiting': eventStatusText = '진행'; eventStatus = 'proceeding'; break;
      case 'proceeding': eventStatusText = '완료'; eventStatus = 'end'; break;
      case 'incomplete': eventStatusText = '완료'; eventStatus = 'end'; break;
      default: return;
    }

    const BodyToPut = {
      ...procurement,
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      accountId: procurement?.account?.accountId,
      procurementStatus: eventStatus,
      procurementElementList: procurement.procurementElementList.map((element) => {
        const returnData = {
          procurementElementId: element.procurementElementId,
          elementId: checkNullObject(element.element, false) ? element.element.elementId : checkEmptyNull(element.elementId, false) ? element.elementId : null,
          quantity: element.quantity, // 발주 수량
          price: element.price,
          storeQuantity: checkEmptyNull(element.storeQuantity, 0), // 미입고 수량
          savedQuantity: 0, // 입고 수량
        };
        return returnData;
      }),
      deleteProcurementElementList: [],
    };
    console.log('handleProcurementStatus - BodyToPut : ', BodyToPut);

    // confirm
    const confirmText = `
    발주를 ${eventStatusText}시키겠습니까?
    ${procurement.procurementStatus === 'waiting' ? '발주 진행 시 발주 물품을 더 이상 수정할 수 없습니다.' : ''}
    `;
    if (window.confirm(confirmText) === true) {
      switch (eventStatus) {
        case 'end':
          let notInStockQuantity = 0; // 미입고 수량 확인
          procurement.procurementElementList.forEach((element) => {
            const storeQuantity = checkEmptyNull(element.storeQuantity, 0); // 미입고 수량
            if (storeQuantity > 0) notInStockQuantity++;
          });
          switch (notInStockQuantity > 0) {
            case true:
              const confirmText = `
                미입고된 제품이 있습니다. 
                추가 입고하시겠습니까?
                `;
              switch (window.confirm(confirmText)) {
                case true:
                  setEventProcurement(() => { return { procurementData: procurement, index: procurementIndex } });
                  setTimeout(setCreateNotInStockModalStatus(true), 1000);
                  break;

                default:
                  await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
                    if (response === undefined) return;
                    console.log('procurementApi.updateProcurement : ', response);

                    setProcurementList((prev) => {
                      const returnData = [...prev];
                      returnData.splice(procurementIndex, 1, response.data);
                      return returnData;
                    });
                  })
                  break;
              }
              break;

            default:
              await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
                if (response === undefined) return;
                console.log('procurementApi.updateProcurement : ', response);

                setProcurementList((prev) => {
                  const returnData = [...prev];
                  returnData.splice(procurementIndex, 1, response.data);
                  return returnData;
                });
              })
              break;
          }
          break;

        default:
          await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
            if (response === undefined) return;
            console.log('procurementApi.updateProcurement : ', response);

            setProcurementList((prev) => {
              const returnData = [...prev];
              returnData.splice(procurementIndex, 1, response.data);
              return returnData;
            });
          })
          break;
      }
    } else {
      return;
    }
  };

  /* 취소 */
  const actCancel = async (procurement, index) => {
    if (!checkNullObject(procurement.account, false)) return alert('거래처 선택후 상태변경이 가능합니다.');

    const procurementIndex = index;

    let eventStatusText = '취소';
    let eventStatus = 'cancel';

    const BodyToPut = {
      ...procurement,
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      accountId: procurement?.account?.accountId,
      procurementStatus: eventStatus,
      procurementElementList: procurement.procurementElementList.map((element) => {
        const returnData = {
          procurementElementId: element.procurementElementId,
          elementId: checkNullObject(element.element, false) ? element.element.elementId : checkEmptyNull(element.elementId, false) ? element.elementId : null,
          quantity: element.quantity, // 발주 수량
          price: element.price,
          storeQuantity: checkEmptyNull(element.storeQuantity, 0), // 미입고 수량
        };
        return returnData;
      }),
      deleteProcurementElementList: [],
    };
    console.log('handleProcurementStatus - BodyToPut : ', BodyToPut);

    // confirm
    const confirmText = `발주를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('procurementApi.updateProcurement : ', response);

        setProcurementList((prev) => {
          const returnData = [...prev];
          returnData.splice(procurementIndex, 1, response.data);
          return returnData;
        });
      })
    } else {
      return;
    }
  };

  /* 복사 */
  const actCopy = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('복사할 발주를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 초기화
    dispatch(procurementActions_setProcurementAccount({}));
    dispatch(procurementActions_setProcurementElementList([]));

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({ copyContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 발주를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 초기화
    dispatch(procurementActions_setProcurementAccount({}));
    dispatch(procurementActions_setProcurementElementList([]));

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 발주를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* 발주서 */
  const actPrintProcurement = (procurement, index) => {
    setEventProcurement(() => { return procurement; });
    setTimeout(setPrintPocurementModalStatus(true), 1000);
  };

  /* 발주물품 입고 */
  const actPutProcurementElement = (procurement, index) => {
    setEventProcurement(() => {return { procurementData: procurement, index: index };});
    setTimeout(setPutProcurementElementModalStatus(true), 1000);
  };

  /* 미입고 추가 */
  const actCreateNotInStock = (procurement, index) => {
    setEventProcurement(() => {return { procurementData: procurement, index: index };});
    setTimeout(setCreateNotInStockModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
        <>
          <Section className="Main">
            <NavBar
              title={
                <div className='Title'>
                  <NavTitle menuCode={'202'} />
                  <div className='statusButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'waiting', name: '대기' },
                      { status: 'proceeding', name: '진행' },
                      { status: 'incomplete', name: '미입고 완료' },
                      { status: 'end', name: '완료' },
                      { status: 'cancel', name: '취소' },
                    ].map((procurementStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={procurementReducer.procurementStatus === procurementStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={() => { handleTitleProcurementStatus(procurementStatus.status); }}
                        >
                          {procurementStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              }
              buttons={
                <>
                  {_authority.indexOf('202-2') !== -1 ? (
                    <>
                      <button className='btn-add' onClick={(e) => {
                        e.preventDefault();
                        dispatch(procurementActions_setProcurementAccount({}));
                        dispatch(procurementActions_setProcurementElementList([]));
                        setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                      }}
                      >
                        추가
                      </button>
                      <button className='btn-copy' onClick={actCopy}>복사</button>
                      <button className='btn-edit' onClick={actUpdate}>수정</button>
                      <button className='btn-delete' onClick={actDelete}>삭제</button>
                    </>
                  ) : null}
                  {/* <button className='btn-download' onClick={actExcelDownload}>다운로드</button> */}
                </>
              }
              nav={'search'}
              firstRow={
                <>
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    {/* <select
                      className="SelectStatus"
                      value={procurementReducer.procurementStatus}
                      onChange={(e) => {
                        dispatch(procurementActions_setProcurementStatus(e.target.value));
                        dispatch(procurementActions_setPageNumber(1));
                        getProcurementList(0, e.target.value, procurementReducer.isPushedSearchButton);
                      }}
                    >
                      {[
                        { optionValue: 'all', optionText: '전체' },
                        { optionValue: 'waiting', optionText: '대기' },
                        { optionValue: 'proceeding', optionText: '진행' },
                        { optionValue: 'incomplete', optionText: '미입고 완료' },
                        { optionValue: 'end', optionText: '완료' },
                        { optionValue: 'cancel', optionText: '취소' },
                      ].map((thisItem) => {
                        return (<option key={thisItem.optionValue + '_procurementStatus'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                      })}
                    </select> */}
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={procurementReducer.startDate}
                        onChange={(e) => { dispatch(procurementActions_setStartDate(e.target.value)) }}
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={procurementReducer.endDate}
                        onChange={(e) => { dispatch(procurementActions_setEndDate(e.target.value)) }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select className="SearchOption" onChange={(e) => { dispatch(procurementActions_setSearchOption(e.target.value)) }}>
                          {[
                            { optionValue: '', optionText: '검색옵션' },
                            { optionValue: 'procurementCode', optionText: '발주코드' },
                            { optionValue: 'procurementName', optionText: '발주이름' },
                            { optionValue: 'accountName', optionText: '거래처명' },
                          ].map((thisItem) => {
                            return (<option key={thisItem.optionValue + '_procurementSearchOption'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                          })}
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={procurementReducer.searchText}
                          onInput={(e) => { dispatch(procurementActions_setSearchText(e.target.value)) }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton"
                        onClick={() => {
                          dispatch(procurementActions_setReset());
                          getProcurementList(0, 'all', false);
                        }}
                      >
                        초기화
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <TableSection content={
              <ProcurementTable style={{ zoom: `${procurementReducer.viewScale}%` }}>
                <thead>
                  <tr style={{ border: 'none' }}>
                    {_authority.indexOf('202-2') !== -1 ? (<th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                    <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>발주상태</th>
                    <th rowSpan={2} style={{ maxWidth: '70px', minWidth: '70px', padding: 'unset', width: '70px' }}>발주서</th>
                    <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>미입고/부분입고</th>
                    <th rowSpan={2}>발주일자</th>
                    <th rowSpan={2}>입고예정일자</th>
                    <th rowSpan={2}>발주코드</th>
                    <th rowSpan={2}>발주이름</th>
                    <th rowSpan={2}>거래처</th>
                    <th rowSpan={2}>발주총금액</th>
                    <th colSpan={7} style={{ backgroundColor: 'var(--ThirdBlue)' }}>발주물품</th>
                    <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}></th>
                  </tr>
                  <tr>
                    <th>물품이름</th>
                    <th>물품코드</th>
                    <th>투입예정수량</th>
                    <th>현재고</th>
                    <th>단가</th>
                    <th>수량</th>
                    <th>금액</th>
                  </tr>
                </thead>
                <tbody>
                  {!checkNullArray(_procurementList, false) ? LoadingMsg() :
                    checkNullArray(_procurementList, []).map((procurement, index) => {
                      return (
                        <ProcurementRow
                          key={index + '_procurements'}
                          authority={_authority}
                          index={index}
                          content={procurement}
                          checkedItem={_checkedItem}
                          handleCheckedItem={handleCheckedItem}
                          handleProcurementStatus={handleProcurementStatus}
                          actCancel={actCancel}
                          actCreateNotInStock={actCreateNotInStock}
                          actPrintProcurement={actPrintProcurement}
                          actPutProcurementElement={actPutProcurementElement}
                        />
                      );
                    })}
                </tbody>
              </ProcurementTable>
            }
              sectionStyle={{ height: 'calc(100% - 66px)' }}
            ></TableSection>
          </Section>

          <PagingComponent
            page={procurementReducer.pageNumber}
            count={procurementReducer.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(procurementActions_setPageNumber(page));
            }}
          />

          <ViewerButtons>
            <ViewMinusButton
              viewScale={procurementReducer.viewScale}
              handleViewScale={(scale) => { dispatch(procurementActions_setViewScale(scale)) }}
            />
            <ViewPlusButton
              viewScale={procurementReducer.viewScale}
              handleViewScale={(scale) => { dispatch(procurementActions_setViewScale(scale)) }}
            />
            <ViewResetButton
              handleViewScale={() => { dispatch(procurementActions_setViewScale(100)) }}
            />
          </ViewerButtons>

          {_excelDownloadModalStatus === true && (
            <ExcelDownloadModal
              mainTitle={'procurement'}
              subTitle={'procurement'}
              open={_excelDownloadModalStatus}
              close={() => { setExcelDownloadModalStatus(false) }}
            />
          )}

          {/* 발주물품 부분 입고 */}
          {_putProcurementElementModalStatus === true && (
            <PutProcurementElementModal
              procurement={_eventProcurement}
              actPut={(data) => {
                setPutProcurementElementModalStatus(false);
                getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
              }}
              open={_putProcurementElementModalStatus}
              close={() => { setPutProcurementElementModalStatus(false) }}
            ></PutProcurementElementModal>
          )}

          {/* 미입고 */}
          {_createNotInStockModalStatus === true && (
            <PutIncompleteElementModal
              procurement={_eventProcurement}
              actPut={() => {
                setCreateNotInStockModalStatus(false);
                getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
              }}
              open={_createNotInStockModalStatus}
              close={() => { setCreateNotInStockModalStatus(false) }}
            ></PutIncompleteElementModal>
          )}

          {_printProcurementModalStatus === true && (
            <ProcurementPdfModal
              procurement={_eventProcurement}
              open={_printProcurementModalStatus}
              close={() => { setPrintPocurementModalStatus(false) }}
            ></ProcurementPdfModal>
          )}
        </>
      }
    />
  );
};

export default Procurement;
