import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import TableSection from 'components/layouts/table/TableSection';

const KPITable = (props) => {
  const { userReducer } = useSelector((state) => state);

  const { unit, contents } = props;
  const [_performance, setPerformance] = useState();
  const [_achievement, setAchievement] = useState();
  const [_goalRate, setGoalRate] = useState();

  useEffect(() => {
    const weekDataList = {};
    const dataList = checkNullArray(props.contents, []).map((data) => {
      if (unit.realDtlCd === 'production') {
        if (checkEmptyNull(data.occurrenceDate, false)) {
          const year = moment(checkEmptyNull(data.occurrenceDate, false)).year();
          const week = moment(checkEmptyNull(data.occurrenceDate, false)).week();
          weekDataList[`${year}-${week}`] = (checkEmptyNull(weekDataList[`${year}-${week}`], 0) * 1) + (data.value * 1);
        }
      } else {
        if (!checkEmptyNull(data.occurrenceDate, false)) return null;
        else return data.value * 1;
      }
    })
    const filterList = unit.realDtlCd === 'production' ? Object.values(weekDataList) : dataList.filter(data => data !== null);
    const sum = filterList.reduce((a, b) => (a + b), 0);
    const current = checkEmptyNull(unit.current, 0) * 1;
    const target = checkEmptyNull(unit.target, 0) * 1;
    const performance = BigNumber(sum).dividedBy(BigNumber(filterList.length)).toNumber();
    const achievement =
      unit.realDtlCd === 'production' ? BigNumber(BigNumber(performance).minus(BigNumber(current))).dividedBy(BigNumber(current)).multipliedBy(100).toNumber()
        : BigNumber(BigNumber(current).minus(BigNumber(performance))).dividedBy(BigNumber(current)).multipliedBy(100).toNumber();
    const goalRate =
      unit.realDtlCd === 'production' ? BigNumber(BigNumber(performance).minus(BigNumber(current))).dividedBy(BigNumber(target).minus(BigNumber(current))).multipliedBy(100).toNumber()
        : BigNumber(BigNumber(current).minus(BigNumber(performance))).dividedBy(BigNumber(current).minus(BigNumber(target))).multipliedBy(100).toNumber();

    setPerformance(() => { return performance; });
    setAchievement(() => { return achievement; });
    setGoalRate(() => { return goalRate; });
  }, [props])

  return (
    <section className='section-table'>
      {[7, 10].includes(userReducer.company.companyId) && checkNullArray(contents, false) && ['production', 'shorten'].includes(unit.realDtlCd) ? (
        <TableSection content={
          <table>
            <thead>
              <tr><th colSpan={2}>KPI</th></tr>
            </thead>
            <tbody>
              <tr><th>현재</th><td>{checkEmptyNull(unit.current, '').toLocaleString()}</td></tr>
              <tr><th>목표</th><td>{checkEmptyNull(unit.target, '').toLocaleString()}</td></tr>
              <tr><th>실적</th><td>{checkEmptyNull(_performance, '').toLocaleString()}</td></tr>
              <tr><th>성취율</th><td>{checkEmptyNull(_achievement, '').toLocaleString()} %</td></tr>
              <tr><th>목표달성률</th><td>{checkEmptyNull(_goalRate, '').toLocaleString()} %</td></tr>
            </tbody>
          </table>
        } />
      ) : null}
      <TableSection content={
        <table>
          <thead>
            <tr>
              <th>일자</th><th>{unit.unit}</th>
            </tr>
          </thead>
          <tbody>
            {checkNullArray(contents, []).map((data, index) => {
              return (
                <tr key={index + '_data'}>
                  <td>{data.occurrenceDate}</td><td>{(checkEmptyNull(data.value, 0) * 1).toLocaleString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      />
    </section>
  );
};

export default KPITable;