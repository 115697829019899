import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  modalReducer_setCurrentFamilyId,
  modalReducer_setFirstFamilies,
  modalReducer_setFirstFamily,
  modalReducer_setProductType,
  modalReducer_setSearchOption,
  modalReducer_setSearchText,
  modalReducer_setSecondFamilies,
  modalReducer_setSecondFamily,
  modalReducer_setThirdFamilies,
  modalReducer_setThirdFamily,
} from 'store/modules/actions/default/modalActions';

import { getCookie } from 'preferences/cookie/cookie';
import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import { rightArrow } from 'components/icons/src';
import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import Nav from 'components/nav/Nav';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

import GSProcurementModal from 'pages/procurement/custom/company/gs/modal/GSProcurementModal';

const NavSection = styled.div`
  align-items: center;
  /* background-color: var(--white); */
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  /* padding: 0px 15px; */
  width: 100%;

  &::--webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::--webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::--webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const FamilySection = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 5px;
  display: flex;
  height: 50px;
  justify-content: flex-start;
`;

const SelectType = styled.select`
  &:focus {
    background-color: var(--MainNavy);
  }
`;

const SelectFamily = styled.select``;

const RightArrow = styled.div`
  background-color: var(--ThirdBlue);
  height: 24px;
  width: 24px;

  mask-image: url(${rightArrow});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${rightArrow});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const SearchBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const SearchOption = styled.select``;

const SearchBar = styled.input`
  &:focus {
    border: 1px solid var(--gray-200);

    border-left: unset;
    border-right: unset;
  }
`;

const Navigation = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { modalReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  /* 물품타입 선택 */
  const actSelectType = async (e) => {
    dispatch(modalReducer_setProductType(e.target.value));
    dispatch(modalReducer_setCurrentFamilyId(0));
    console.log('event', e.target.value);

    await axios
      .post(
        AXIOS_BASE_URL + '/category/search',
        {
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        Finish_findAllCategory(response.data);
      })
      .catch((error) => {
        console.log('get/findAllCategory-error : ', error);
      });
  };
  const Finish_findAllCategory = (Result) => {
    const categoriesList = Result.content;
    console.log('get/category : ', Result);
    console.log('get/category - content : ', categoriesList);

    /* 초기화 */
    dispatch(
      modalReducer_setFirstFamilies(
        categoriesList.filter((thisItem) => thisItem.categoryLevel === 1),
      ),
    );
    dispatch(modalReducer_setSecondFamilies([]));
    dispatch(modalReducer_setThirdFamilies([]));

    document.getElementById('FirstFamily').value = '';
    document.getElementById('SecondFamily').value = '';
    document.getElementById('ThirdFamily').value = '';

    dispatch(modalReducer_setFirstFamily(''));
    dispatch(modalReducer_setSecondFamily(''));
    dispatch(modalReducer_setThirdFamily(''));
  };

  /* 패밀리 선택 */
  const showCategories = (e) => {
    const clickedCategory = e.target.value;
    dispatch(modalReducer_setCurrentFamilyId(Number(clickedCategory)));

    const thisCategory = modalReducer.allFamilies.filter(
      (thisItem) => thisItem.categoryId === Number(clickedCategory),
    )[0];
    console.log(clickedCategory, thisCategory, modalReducer.allFamilies);

    const setList = modalReducer.allFamilies.filter(
      (thisItem) =>
        thisItem.categoryParentLevel === thisCategory.categoryLevel &&
        thisItem.categoryParentCode === thisCategory.categoryCode,
    );

    switch (thisCategory.categoryLevel) {
      case 1:
        dispatch(modalReducer_setSecondFamilies(setList));
        dispatch(modalReducer_setThirdFamilies([]));

        document.getElementById('SecondFamily').value = '';
        document.getElementById('ThirdFamily').value = '';

        dispatch(modalReducer_setFirstFamily(clickedCategory));
        dispatch(modalReducer_setSecondFamily(''));
        dispatch(modalReducer_setThirdFamily(''));
        break;

      case 2:
        dispatch(modalReducer_setThirdFamilies(setList));

        document.getElementById('ThirdFamily').value = '';

        dispatch(modalReducer_setSecondFamily(clickedCategory));
        dispatch(modalReducer_setThirdFamily(''));
        break;

      case 3:
        dispatch(modalReducer_setThirdFamily(clickedCategory));
        break;

      default:
        return;
    }
  };

  /* 검색옵션 선택 */
  const actSelectSearchOption = (e) => {
    dispatch(modalReducer_setSearchOption(e.target.value));
  };

  /* 검색바 입력 */
  const actInputSearchText = (e) => {
    dispatch(modalReducer_setSearchText(e.target.value));
  };

  /* ====================================================================== #6 */

  return (
    <NavSection>
      <FamilySection>
        {/* 물품타입 */}
        <SelectType
          id="SelectType"
          value={modalReducer.productType || ''}
          onChange={actSelectType}
          style={{
            backgroundColor: 'var(--ThirdBlue) ',
            border: '1px solid var(--ThirdBlue)',
            borderRadius: '17px',
            boxSizing: 'border-box',
            color: 'var(--white)',
            height: '35px',
            padding: '5px 20px',
            width: '150px',
          }}
        >
          <option value="" disabled>
            물품유형
          </option>
          {userReducer.elementType.map((thisElementType) => {
            let thisTypeText = '';
            switch (thisElementType) {
              case 'material':
                thisTypeText = '자재';
                break;
              case 'semi':
                thisTypeText = '부자재';
                break;
              case 'half':
                thisTypeText = '반제품';
                break;
              case 'product':
                thisTypeText = '완제품';
                break;
              case 'tool':
                thisTypeText = '공구';
                break;

              default:
                break;
            }

            if (thisTypeText !== '') {
              return (
                <option key={thisElementType + '_nav'} value={thisElementType}>
                  {thisTypeText}
                </option>
              );
            }
          })}
        </SelectType>
        <RightArrow style={{ backgroundColor: 'transparent' }} />

        {/* 패밀리1 */}
        <SelectFamily
          id="FirstFamily"
          value={
            modalReducer.firstFamily !== '' &&
            modalReducer.firstFamilies.length > 0
              ? modalReducer.firstFamily
              : ''
          }
          onChange={showCategories}
          style={{
            border: '1px solid var(--gray-200)',
            borderRadius: '5px',
            boxAizing: 'border-box',
            height: '35px',
            padding: '5px 10px',
            width: '150px',
          }}
        >
          <option value="" disabled>
            패밀리 1
          </option>
          {modalReducer.productType !== '' &&
            modalReducer.firstFamilies.map((thisFamily) => (
              <option key={thisFamily.categoryId} value={thisFamily.categoryId}>
                {thisFamily.categoryName}
              </option>
            ))}
        </SelectFamily>
        <RightArrow />

        {/* 패밀리2 */}
        <SelectFamily
          id="SecondFamily"
          value={
            modalReducer.secondFamily !== '' &&
            modalReducer.secondFamilies.length > 0
              ? modalReducer.secondFamily
              : ''
          }
          onChange={showCategories}
          style={{
            border: '1px solid var(--gray-200)',
            borderRadius: '5px',
            boxAizing: 'border-box',
            height: '35px',
            padding: '5px 10px',
            width: '150px',
          }}
        >
          <option value="" disabled>
            패밀리 2
          </option>
          {modalReducer.firstFamily !== '' &&
            modalReducer.secondFamilies.length > 0 &&
            modalReducer.secondFamilies.map((thisFamily) => (
              <option key={thisFamily.categoryId} value={thisFamily.categoryId}>
                {thisFamily.categoryName}
              </option>
            ))}
        </SelectFamily>
        <RightArrow />

        {/* 패밀리3 */}
        <SelectFamily
          id="ThirdFamily"
          value={
            modalReducer.thirdFamily !== '' &&
            modalReducer.thirdFamilies.length > 0
              ? modalReducer.thirdFamily
              : ''
          }
          onChange={showCategories}
          style={{
            border: '1px solid var(--gray-200)',
            borderRadius: '5px',
            boxAizing: 'border-box',
            height: '35px',
            padding: '5px 10px',
            width: '150px',
          }}
        >
          <option value="" disabled>
            패밀리 3
          </option>
          {modalReducer.secondFamily !== '' &&
            modalReducer.thirdFamilies.length > 0 &&
            modalReducer.thirdFamilies.map((thisFamily) => (
              <option key={thisFamily.categoryId} value={thisFamily.categoryId}>
                {thisFamily.categoryName}
              </option>
            ))}
        </SelectFamily>
      </FamilySection>

      {/* 텍스트 */}
      <SearchBox>
        {/* 검색 옵션 */}
        <SearchOption
          id="Search_Option"
          value={modalReducer.searchOption || ''}
          onChange={actSelectSearchOption}
          style={{
            border: '1px solid var(--gray-200)',
            borderRadius: '17px 0px 0px 17px',
            boxSizing: 'border-box',
            height: '35px',
            padding: '5px 10px',
            width: '100px',
          }}
        >
          <option value="" disabled>
            검색옵션
          </option>
          <option value="procurementName">발주명</option>
          <option value="elementName">물품이름</option>
          <option value="elementCode">물품코드</option>
          <option value="userName">작업자</option>
        </SearchOption>

        {/* 검색 바 */}
        <SearchBar
          type="text"
          value={modalReducer.searchText || ''}
          placeholder="Search..."
          onInput={actInputSearchText}
          style={{
            border: '1px solid var(--gray-200)',
            borderRadius: '0px 17px 17px 0px',
            boxSizing: 'border-box',
            height: '35px',
            marginRight: '5px',
            padding: '5px',
            width: '250px',
          }}
        ></SearchBar>

        {/* 검색 버튼 */}
        <button id="searchButton" onClick={props.searchEvent}>
          <SearchButtonIcon />
        </button>
      </SearchBox>
    </NavSection>
  );
};

const InspectionTableSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & > .TableSection {
    max-height: 200px;
    overflow: auto;

    td,
    th {
      min-width: unset;
    }

    &::--webkit-scrollbar-thumb {
      background-color: var(--ThirdBlue);
    }
  }
`;

const InspectionButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  width: 100%;
`;

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;

const UpdateGSIncomingInspection = () => {
  /* ===================================================#======================================================*/
  const navigate = useNavigate();
  const { gsReducer, pageReducer, modalReducer, userReducer } = useSelector(
    (state) => state,
  );

  /* =================================================== #2 ===================================================*/
  const [_onload, setOnload] = useState('unload');

  const updateContent = gsReducer.updateOrder;

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,

    importInspectionId: updateContent.importInspectionId,
    importInspectionCode: updateContent.importInspectionCode,
    importInspectionName: updateContent.importInspectionName,

    customizedContent: JSON.parse(updateContent.customizedContent),

    importInspectionLogList: updateContent.importInspectionLogList.map(
      (thisItem) => {
        const thisCustomizedContent = JSON.parse(thisItem.customizedContent);
        return {
          importInspectionLogId: thisItem.importInspectionLogId,
          elementStockLogId: thisCustomizedContent.elementStockLogId,
          quantity: 1,
          customizedContent: {
            measurement1: thisCustomizedContent.measurement1,
            measurement2: thisCustomizedContent.measurement2,

            elementStockLogId: thisCustomizedContent.elementStockLogId,
            elementName: thisCustomizedContent.elementName,
            elementCode: thisCustomizedContent.elementCode,

            length: thisCustomizedContent.length,
            unitWeight: thisCustomizedContent.unitWeight,
          },
        };
      },
    ),
  });
  console.log(_formData);

  const [_procurementModalStatus, setProcurementModalStatus] = useState(false);

  const [_logsList, setLogsList] = useState([]);
  const [_inspectionLogsList, setInspectionLogsList] = useState([]);
  console.log('_inspectionLogsList : ', _inspectionLogsList);

  /* =================================================== #3 =================================================== */
  useEffect(() => {
    setOnload('loaded');

    return () => {};
  }, []);

  /* =================================================== #4들 =================================================== */
  //
  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormData({ ..._formData, [name]: value });
  };

  // 커스텀
  const handleCustomChangeEvent = (e) => {
    const { name, value } = e.target;
    const setCustom = { ..._formData.customizedContent, [name]: value };
    setFormData({ ..._formData, customizedContent: setCustom });
  };

  const setLogsTable = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      logType: 'In',
    };
    if (modalReducer.searchOption !== '') {
      BodyToPost[modalReducer.searchOption] = modalReducer.searchText;
    }
    console.log('setLogsTable - BodyToPost : ', BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/elementStockLog/search',
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('setLogsTable - response : ', response);
        setLogsList(response.data.content);
      })
      .catch((error) => {
        console.log('setLogsTable - error : ', error);
      });
  };

  /* =================================================== #5들 =================================================== */
  const actSearch = async (e) => {
    e.preventDefault();
    await setLogsTable();
  };

  const actSelectInspectionProduct = () => {
    let thisList = [];
    document
      .querySelectorAll('input[name="StockLogs"]:checked')
      .forEach((thisItem) => {
        const thisElementStockLogId = thisItem.closest('tr').dataset.key;
        const thisCheckIndex = _inspectionLogsList.findIndex(
          (thisIndexItem) =>
            thisIndexItem.elementStockLogId === Number(thisElementStockLogId),
        );
        if (thisCheckIndex !== -1) return;

        const thisElementStockLogIndex = _logsList.findIndex(
          (thisIndexItem) =>
            thisIndexItem.elementStockLogId === Number(thisElementStockLogId),
        );
        const thisElementStockLog = _logsList[thisElementStockLogIndex];

        thisList.push(thisElementStockLog);
      });

    setInspectionLogsList([..._inspectionLogsList, ...thisList]);
  };

  const actDelLog = (e) => {
    const thisElementStockLogId = e.target.closest('tr').dataset.key;

    const setList = _inspectionLogsList.filter(
      (thisItem) =>
        thisItem.elementStockLogId !== Number(thisElementStockLogId),
    );
    setInspectionLogsList(setList);
  };

  // 수입검사저장
  const saveForm = async (e) => {
    e.preventDefault();

    const InspectionList = [];
    _inspectionLogsList.forEach((thisItem) => {
      let count = 0;
      while (count < thisItem.amount) {
        let thisLength = 0;
        const thisLengthIndex =
          thisItem.element.elementAttributeValueList.findIndex(
            (thisAttrItem) =>
              thisAttrItem.elementAttribute.elementAttributeName === '길이',
          );
        if (thisLengthIndex !== -1) {
          thisLength =
            thisItem.element.elementAttributeValueList[thisLengthIndex].value;
        }

        let thisUnitWeight = 0;
        const thisUnitWeightIndex =
          thisItem.element.elementAttributeValueList.findIndex(
            (thisAttrItem) =>
              thisAttrItem.elementAttribute.elementAttributeName === '단중',
          );
        if (thisUnitWeightIndex !== -1) {
          thisUnitWeight =
            thisItem.element.elementAttributeValueList[thisUnitWeightIndex]
              .value;
        }

        const InspectionData = {
          elementStockLogId: thisItem.elementStockLogId,
          quantity: 1,
          customizedContent: JSON.stringify({
            measurement1: '',
            measurement2: '',

            elementStockLogId: thisItem.elementStockLogId,
            elementName: thisItem.element.elementName,
            elementCode: thisItem.element.elementCode,

            length: thisLength,
            unitWeight: thisUnitWeight,
          }),
        };
        InspectionList.push(InspectionData);

        count++;
      }
    });

    const BodyToPut = { ..._formData };
    BodyToPut.customizedContent = JSON.stringify(_formData.customizedContent);
    BodyToPut.importInspectionLogList = InspectionList;
    console.log('BodyToPut : ', BodyToPut);

    // await axios
    //   .put(AXIOS_BASE_URL + '/importInspection/' + _formData.importInspectionId, BodyToPut, {
    //     headers: {
    //       Authorization: getCookie('accessToken')
    //     }
    //   })
    //   .then(response => {
    //     console.log('post/importInspection - response : ', response);
    //     if(response.status === 200) {
    //       alert('검사를 수정했습니다.');
    //       navigate( pageReducer.currentPage, { replace: true });
    //     }
    //   })
    //   .catch(error => {
    //     console.error('post/importInspection - error : ', error);
    //   })
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'조도측정'}
              content={''}
              nav={''}
              search={''}
              searchButton={''}
              searchOptions={''}
              statusOptions={''}
            />

            <Form
              title={
                <>
                  검사
                  <br />
                  수정
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={saveForm}>
                    저장
                  </button>
                  <button
                    className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox">
                    <h4>검사코드</h4>
                    <input
                      onChange={handleChangeEvent}
                      type="data"
                      name="importInspectionCode"
                      defaultValue={_formData.importInspectionCode || ''}
                      placeholder="* 검사코드..."
                      data-required="required"
                    />
                  </div>

                  <div className="formBox">
                    <h4>검사명</h4>
                    <input
                      onChange={handleChangeEvent}
                      type="text"
                      name="importInspectionName"
                      defaultValue={_formData.importInspectionName || ''}
                      placeholder="* 검사명..."
                      data-required="required"
                    />
                  </div>

                  <div className="formBox">
                    <h4>비고</h4>
                    <textarea
                      name="remark"
                      onChange={handleCustomChangeEvent}
                      defaultValue={_formData.customizedContent.remark || ''}
                      placeholder="비고..."
                    ></textarea>
                  </div>

                  <div className="formBox">
                    <h4>검사물품</h4>
                    <InspectionTableSection>
                      <TableSection
                        content={
                          <table id="InspectionProductTable">
                            <thead>
                              <tr>
                                <th>입력시각</th>
                                <th>물품이름</th>
                                <th>물품코드</th>
                                <th>입고</th>
                                <th></th>
                                <th>작업자</th>
                                <th
                                  style={{ minWidth: '50px', width: '50px' }}
                                ></th>
                              </tr>
                            </thead>
                            <tbody>
                              {_inspectionLogsList.length > 0 &&
                                _inspectionLogsList.map((thisItem) => {
                                  let thisLogDate = '';
                                  if (
                                    typeof thisItem.checkedDate === 'object'
                                  ) {
                                    const thisCheckedDate = `${thisItem.checkedDate[0]}-${thisItem.checkedDate[1]}-${thisItem.checkedDate[2]} ${thisItem.checkedDate[3]}:${thisItem.checkedDate[4]}:${thisItem.checkedDate[5]}`;
                                    const newStartDate = new Date(
                                      thisCheckedDate,
                                    );
                                    thisLogDate = moment(newStartDate).format(
                                      'YYYY-MM-DD HH:mm:ss',
                                    );
                                  }

                                  return (
                                    <tr
                                      key={
                                        thisItem.elementStockLogId + '_logsRow'
                                      }
                                      data-key={thisItem.elementStockLogId}
                                    >
                                      <td>{thisLogDate}</td>
                                      <td>
                                        {thisItem.element !== undefined &&
                                          (thisItem.element.elementName || '')}
                                      </td>
                                      <td>
                                        {thisItem.element !== undefined &&
                                          (thisItem.element.elementCode || '')}
                                      </td>
                                      <td
                                        style={{
                                          color: 'var(--MainGreen)',
                                          minWidth: '150px',
                                          width: '150px',
                                        }}
                                      >
                                        {thisItem.logType === 'In' && (
                                          <CommaNum
                                            displayType={'text'}
                                            num={thisItem.amount || 0}
                                          />
                                        )}
                                        {thisItem.logType === 'In' &&
                                          ' ' +
                                            (thisItem.element.elementUnit ||
                                              '')}
                                      </td>
                                      <td>{thisItem.note}</td>
                                      <td>{thisItem.userName}</td>
                                      <td
                                        style={{
                                          minWidth: '50px',
                                          width: '50px',
                                        }}
                                      >
                                        <DelButton onClick={actDelLog}>
                                          <CloseButtonIcon />
                                        </DelButton>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        }
                      />
                    </InspectionTableSection>
                  </div>

                  <div className="formBox">
                    <h4>물품선택</h4>
                    <InspectionTableSection>
                      <Navigation searchEvent={actSearch} />
                      <TableSection
                        content={
                          <table id="InspectionTable">
                            <thead
                              style={{ backgroundColor: 'var(--ThirdBlue)' }}
                            >
                              <tr>
                                <th style={{ minWidth: '50px', width: '50px' }}>
                                  <AllCheckBox
                                    key={'AllCheckBox'}
                                    checkboxName={'StockLogs'}
                                  />
                                </th>
                                <th>입력시각</th>
                                <th>물품이름</th>
                                <th>물품코드</th>
                                <th>입고</th>
                                <th></th>
                                <th>작업자</th>
                              </tr>
                            </thead>
                            <tbody>
                              {_logsList.length > 0 &&
                                _logsList.map((thisItem) => {
                                  let thisLogDate = '';
                                  if (
                                    typeof thisItem.checkedDate === 'object'
                                  ) {
                                    const thisCheckedDate = `${thisItem.checkedDate[0]}-${thisItem.checkedDate[1]}-${thisItem.checkedDate[2]} ${thisItem.checkedDate[3]}:${thisItem.checkedDate[4]}:${thisItem.checkedDate[5]}`;
                                    const newStartDate = new Date(
                                      thisCheckedDate,
                                    );
                                    thisLogDate = moment(newStartDate).format(
                                      'YYYY-MM-DD HH:mm:ss',
                                    );
                                  }

                                  return (
                                    <tr
                                      key={
                                        thisItem.elementStockLogId + '_logsRow'
                                      }
                                      data-key={thisItem.elementStockLogId}
                                    >
                                      <td
                                        style={{
                                          minWidth: '50px',
                                          width: '50px',
                                        }}
                                      >
                                        <CheckBox
                                          act={''}
                                          all={true}
                                          name={'StockLogs'}
                                        />
                                      </td>
                                      <td>{thisLogDate}</td>
                                      <td>{thisItem.element.elementName}</td>
                                      <td>{thisItem.element.elementCode}</td>
                                      <td
                                        style={{
                                          color: 'var(--MainGreen)',
                                          minWidth: '150px',
                                          width: '150px',
                                        }}
                                      >
                                        {thisItem.logType === 'In' && (
                                          <CommaNum
                                            displayType={'text'}
                                            num={thisItem.amount}
                                          />
                                        )}
                                        {thisItem.logType === 'In' &&
                                          ' ' + thisItem.element.elementUnit}
                                      </td>
                                      <td>{thisItem.note}</td>
                                      <td>{thisItem.userName}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        }
                      />
                      <InspectionButton onClick={actSelectInspectionProduct}>
                        선택
                      </InspectionButton>
                    </InspectionTableSection>
                  </div>
                </>
              }
                        // formLayoutStyle={{
                          // padding: '0px 30px',
                          // width: '95%',
                        // }}
              formSectionStyle={{
                paddingTop: '10px',
                width: '100%',
              }}
            />
          </main>

          {_procurementModalStatus === true && (
            <GSProcurementModal
              open={_procurementModalStatus}
              close={() => {
                setProcurementModalStatus(false);
              }}
            ></GSProcurementModal>
          )}
        </>
      }
    />
  );
};

export default UpdateGSIncomingInspection;
