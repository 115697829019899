import { AxiosInterceptor } from 'api/core';

export const categoryApi = {
  getCategory: (categoryId) => AxiosInterceptor.get('/category/' + categoryId),
  searchCategory: (body) => AxiosInterceptor.post('/category/search', body),
  createCategory: (body) => AxiosInterceptor.post('/category', body),
  updateCategory: (categoryId, body) =>
    AxiosInterceptor.put('/category/' + categoryId, body),
  deleteCategory: (categoryId) =>
    AxiosInterceptor.delete('/category/' + categoryId),
};
