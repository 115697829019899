import React, { useEffect, useState } from 'react';

import { auth } from 'api/apis/auth/auth';
import { companyApi } from 'api/apis/companyApi';

import { checkNullArray } from 'components/checkValues/checkValues';

const CompanyContent = ({
  close, modalData
}) => {
  const [_companyList, setCompanyList] = useState([]);
  const [_companyId, setCompanyId] = useState();

  useEffect(() => {
    getCompanyList();
  }, []);
  useEffect(() => {}, [_companyId])

  const getCompanyList = async () => { // 회사 전체 리스트 불러오기
    await auth.searchCompanies().then((response) => {
      if (response === undefined) return;
      setCompanyList(() => { return checkNullArray(response.data.content, response.data); });
    });
  };

  const actCompanyUpdate = async() => { // 회사 변경
    const companyId = Number(_companyId);
    if(isNaN(companyId)) return alert('companyId를 확인해주세요.');
    const body = {
      id: modalData.id,
      companyId: companyId
    };

    await companyApi.updateUserCompany(body).then(response => {
      if(response === undefined) return;
      console.log('actCompanyUpdate : ', response);
      alert('사용자 회사를 변경했습니다.');
      close('update');
    })
  };

  return (
    <section className='company-content'>
      <div>
      {_companyList.map(company => {
        return(
          <p key={company.companyId + '_company'} title={company.companyName}>
            <span>{company.companyId}</span>
            <span>{company.companyName}</span>
          </p>
        );
      })}
      </div>

      <div>
        <input
          type='number'
          name='companyId'
          placeholder='companyId...'
          onInput={(e) => {
            if(e.keyCode === 13 || e.key === 'Enter') {
              return actCompanyUpdate();
            }
            const companyId = e.target.value;
            setCompanyId(() => {return companyId});
          }}
          onKeyUp={(e) => {
            if(e.keyCode === 13 || e.key === 'Enter') {
              actCompanyUpdate();
            }
          }}
        />
      </div>

      <button className='btn-save' onClick={actCompanyUpdate}>저장</button>
    </section>
  );
};

export default CompanyContent;