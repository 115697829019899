import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { companyMenu } from 'api/apis/operator/companyMenu';
import { companyPageCode } from 'api/apis/operator/companyPageCode';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import OperatorBody from 'components/layouts/body/OperatorBody';
import NavBar from 'components/nav/NavBar';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);

  & .TableSection {
    tbody th {
      background-color: var(--MainNavy);
      color: var(--white);
      min-width: 150px;
      width: 150px;
    }
  }
`;

const CreateCompanyMenu = () => {
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const companyName = searchParams.get('companyName');

  const [_onload, setOnload] = useState('unload');

  const [_companyPageCodes, setCompanyPageCodes] = useState({});
  const [_companyMenuList, setCompanyMenuList] = useState([]);

  const [_companyPageCode, setCompanyPageCode] = useState({});

  const [_formData, setFormData] = useState({
    companyId: companyId,
    companyPageCodeId: '',
    pageCode: '',
    pageName: '',
    pageUrl: '',
    indexX: '',
    indexY: '',
  });

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    getCompanyPageCode();
    setOnload('loaded');
    return () => { };
  }, []);

  const getCompanyPageCode = async () => {
    const BodyToPost = {
      companyId: companyId,
    }
    await companyPageCode.searchCompanyPageCode(BodyToPost).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyPageCode.searchCompanyPageCode : ', response);
      const returnData = {};
      response.data?.content.forEach(pageCode => { returnData[pageCode.pageCode] = pageCode })
      setCompanyPageCodes(() => { return returnData; });
      getCompanyMenu(returnData);
    })
  }
  const getCompanyMenu = async (companyPageCodes) => {
    const BodyToPost = {
      companyId: companyId,
    }
    await companyMenu.searchCompanyMenu(BodyToPost).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyMenu.searchCompanyMenu : ', response);
      const companyMenuList = response.data.content;
      setCompanyMenuList(() => { return companyMenuList; });
      const returnData = {};
      Object.keys(companyPageCodes).filter(companyPageCode => {
        const checkedIndex = companyMenuList.findIndex(item => item.pageCode === companyPageCode);
        if (checkedIndex === -1) {
          returnData[companyPageCode] = companyPageCodes[companyPageCode];
        }
      })
      setCompanyPageCodes(() => { return returnData });
    })
  };

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData(prev => { return { ...prev, [name]: value } })
  };

  const actCreate = async (e) => {
    e.preventDefault();

    const BodyToPost = {
      ..._formData,
      indexX: BigNumber(_formData.indexX).toNumber(),
      indexY: BigNumber(_formData.indexY).toNumber(),
      companyPageCodeId: _companyPageCode.companyPageCodeId
    };
    console.log('BodyToPost : ', BodyToPost);
    await companyMenu.createCompanyMenu(BodyToPost).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyMenuApi.createCompanyMenu : ', response);
      alert('메뉴를 추가했습니다.')
      navigate({
        pathname: '/operator/companyManagement/menu',
        search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
        replace: true,
      })
    })
  };

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={`${companyName} 메뉴관리`}
            buttons={
              <>
                <button className='btn-set'
                  onClick={(e) => {
                    e.preventDefault();
                    navigate({
                      pathname: '/operator/companyManagement/menu',
                      search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
                      replace: true,
                    })
                  }}
                >
                  뒤로
                </button>
              </>
            }
            nav={''}
          />

          <Form
            title={<>메뉴<br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate({
                      pathname: '/operator/companyManagement/menu',
                      search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
                      replace: true,
                    })
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <h4>pageCode</h4>
                  <select
                    name="pageCode"
                    value={_formData.pageCode}
                    onChange={(e) => {
                      const pageCode = e.target.value;
                      const companyPageCodeData = _companyPageCodes[pageCode];
                      console.log('companyPageCodeData : ', companyPageCodeData);
                      if (!checkNullObject(companyPageCodeData, false)) return;
                      setCompanyPageCode(() => { return companyPageCodeData })
                      handleInputEvent(e)
                    }}
                  >
                    <option value={''}>pageCode 선택</option>
                    {Object.keys(_companyPageCodes).map((pageCode, index) => {
                      const pageCodeData = _companyPageCodes[pageCode];
                      return (<option key={index + '_companyPageCode'} value={pageCodeData.pageCode}>{`${pageCodeData.pageCode} : ${pageCodeData.defaultPageName}`}</option>)
                    })}
                  </select>
                </div>

                <div className='formBox w100'>
                  <h4>companyMenu</h4>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr><th></th><th>D</th><th>C</th></tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>pageName</th>
                          <td>{_companyPageCode?.defaultPageName}</td>
                          <td>
                            <input
                              type="text"
                              name="pageName"
                              placeholder="pageName"
                              data-required="required"
                              value={_formData.pageName}
                              onInput={handleInputEvent}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>pageUrl</th>
                          <td>{_companyPageCode?.defaultPageUrl}</td>
                          <td>
                            <input
                              type="text"
                              name="pageUrl"
                              placeholder="pageUrl"
                              data-required="required"
                              value={_formData.pageUrl}
                              onInput={handleInputEvent}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>indexX</th>
                          <td>{_companyPageCode?.defaultIndexX}</td>
                          <td>
                            <input
                              type="text"
                              name="indexX"
                              placeholder="indexX"
                              data-required="required"
                              value={_formData.indexX}
                              onInput={handleInputEvent}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>indexY</th>
                          <td>{_companyPageCode?.defaultIndexY}</td>
                          <td>
                            <input
                              type="text"
                              name="indexY"
                              placeholder="indexY"
                              data-required="required"
                              value={_formData.indexY}
                              onInput={handleInputEvent}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  } />
                </div>
              </>
            }
          />
        </MainSection>
      </>
    }
    />
  );
};

export default CreateCompanyMenu;
