import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import TableSection from 'components/layouts/table/TableSection';

const KPITable = (props) => {
  const { userReducer } = useSelector((state) => state);

  const { unit, contents } = props;
  const [_current, setCurrent] = useState();
  const [_target, setTarget] = useState();
  const [_performance, setPerformance] = useState();
  const [_achievement, setAchievement] = useState();
  const [_goalRate, setGoalRate] = useState();

  useEffect(() => {
    /*
    현재 = 월별로 치환해서 표시 (일: 해당 월 일수 구해서 곱, 주: 해당 주수 구해서 곱, 월: 그대로)
    목표 = 월별로 치환해서 표시 (일: 해당 월 일수 구해서 곱, 주: 해당 주수 구해서 곱, 월: 그대로)
    실적 = contents.value 들의 합
    향상률(향상률) = ((실적 - 현재) / 현재) * 100 >증가목표 / ((현재 - 실적) / 현재) * 100 >감소목표
    목표달성율 = ((실적 - 현재) / (목표 - 현재)) * 100
    */

    const current = checkEmptyNull(unit.current, 0) * 5;
    // if(unit.)
    const target = checkEmptyNull(unit.target, 0) * 5;


    const performance = checkNullArray(contents, []).map(content => (content.value * 1)).reduce((a, b) => (a + b), 0);
    console.log(current, target, performance);

    let achievement, goalRate;
    if (unit.realDtlCd === 'production') { // 생산량 증가 (증가목표)
      achievement = BigNumber(performance).minus(BigNumber(current)).dividedBy(BigNumber(current)).multipliedBy(100).toNumber();
      goalRate = BigNumber(performance).minus(BigNumber(current)).dividedBy(BigNumber(target).minus(BigNumber(current))).multipliedBy(100).toNumber();
    } else if (unit.realDtlCd === 'shorten') { // 납기 단축 (감소목표)
      achievement = BigNumber(current).minus(BigNumber(performance)).dividedBy(BigNumber(current)).multipliedBy(100).toNumber();
      goalRate = BigNumber(performance).minus(BigNumber(current)).dividedBy(BigNumber(target).minus(BigNumber(current))).multipliedBy(100).toNumber();
      // goalRate = BigNumber(current).minus(BigNumber(performance)).dividedBy(BigNumber(current).minus(BigNumber(target))).multipliedBy(100).toNumber();
    }

    // const weekDataList = {};
    // const dataList = checkNullArray(props.contents, []).map((data) => {
    //   if (unit.realDtlCd === 'production') {
    //     if (checkEmptyNull(data.occurrenceDate, false)) {
    //       const year = moment(checkEmptyNull(data.occurrenceDate, false)).year();
    //       const week = moment(checkEmptyNull(data.occurrenceDate, false)).week();
    //       weekDataList[`${year}-${week}`] = (checkEmptyNull(weekDataList[`${year}-${week}`], 0) * 1) + (data.value * 1);
    //     }
    //   } else {
    //     if (!checkEmptyNull(data.occurrenceDate, false)) return null;
    //     else return data.value * 1;
    //   }
    // })
    // const filterList = unit.realDtlCd === 'production' ? Object.values(weekDataList) : dataList.filter(data => data !== null);
    // const sum = filterList.reduce((a, b) => (a + b), 0);
    // const current = checkEmptyNull(unit.current, 0) * 1;
    // const target = checkEmptyNull(unit.target, 0) * 1;
    // const performance = BigNumber(sum).dividedBy(BigNumber(filterList.length)).toNumber();
    // const achievement =
    //   unit.realDtlCd === 'production' ? BigNumber(BigNumber(performance).minus(BigNumber(current))).dividedBy(BigNumber(current)).multipliedBy(100).toNumber()
    //     : BigNumber(BigNumber(current).minus(BigNumber(performance))).dividedBy(BigNumber(current)).multipliedBy(100).toNumber();
    // const goalRate =
    //   unit.realDtlCd === 'production' ? BigNumber(BigNumber(performance).minus(BigNumber(current))).dividedBy(BigNumber(target).minus(BigNumber(current))).multipliedBy(100).toNumber()
    //     : BigNumber(BigNumber(current).minus(BigNumber(performance))).dividedBy(BigNumber(current).minus(BigNumber(target))).multipliedBy(100).toNumber();

    setCurrent(() => { return current; });
    setTarget(() => { return target; });
    setPerformance(() => { return performance; });
    setAchievement(() => { return achievement; });
    setGoalRate(() => { return goalRate; });
  }, [props])

  return (
    <section className='section-table'>
      {/* [7, 10].includes(userReducer.company.companyId) && */
        checkNullArray(contents, false) && ['production', 'shorten'].includes(unit.realDtlCd) ? (
          <TableSection content={
            <table>
              <thead>
                <tr><th colSpan={2}>월별 KPI</th></tr>
              </thead>
              <tbody>
                <tr>
                  <th>현재</th>
                  <td>
                    {checkEmptyNull(_current, '').toLocaleString()}<br />
                    {unit.cycle === 'DAY' ? `(일: ${checkEmptyNull((unit.current * 1), '').toLocaleString()})` : unit.cycle === 'WEEK' ? `(주: ${checkEmptyNull((unit.current * 1), '').toLocaleString()})` : ''}
                  </td>
                </tr>
                <tr>
                  <th>목표</th>
                  <td>
                    {checkEmptyNull(_target, '').toLocaleString()}<br />
                    {unit.cycle === 'DAY' ? `(일: ${checkEmptyNull((unit.target * 1), '').toLocaleString()})` : unit.cycle === 'WEEK' ? `(주: ${checkEmptyNull((unit.target * 1), '').toLocaleString()})` : ''}
                  </td>
                </tr>
                {/* <tr><th>목표</th><td>{checkEmptyNull(_target, '').toLocaleString()}</td></tr> */}
                <tr><th>누적실적</th><td>{checkEmptyNull(_performance, '').toLocaleString()}</td></tr>
                <tr><th>향상률</th><td>{checkEmptyNull(_achievement, '').toLocaleString()} %</td></tr>
                <tr><th>목표달성률</th><td>{checkEmptyNull(_goalRate, '').toLocaleString()} %</td></tr>
              </tbody>
            </table>
          } />
        ) : null}
      <TableSection content={
        <table>
          <thead>
            <tr>
              <th>일자</th><th>{unit.unit}</th>
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(contents, false) ? (<>
              <tr>
                <td colSpan={2}>데이터가 없습니다</td>
              </tr>
            </>) : (<>
              {checkNullArray(contents, []).map((data, index) => {
                return (
                  <tr key={index + '_data'}>
                    <td>{data.occurrenceDate}</td><td>{(checkEmptyNull(data.value, 0) * 1).toLocaleString()}</td>
                  </tr>
                );
              })}
            </>)}
          </tbody>
        </table>
      }
      />
    </section>
  );
};

export default KPITable;