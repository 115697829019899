import React from 'react';
import RouteList from 'preferences/route/RouteList';
import { APIInterceptor } from 'api/core';
import { WSHandler } from 'api/core/WSHandler';

const App = () => {
  return (
    <>
      <APIInterceptor></APIInterceptor>
      <WSHandler></WSHandler>
      <RouteList></RouteList>
    </>
  );
};

export default App;
