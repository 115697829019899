import { AxiosInterceptor } from 'api/core';

export const fileDataApi = {
  getFileData: (fileDataId) => AxiosInterceptor.get('/fileData/' + fileDataId),
  searchFileData: (paging, body) => AxiosInterceptor.post('/fileData/search' + paging, body),
  createFileData: (body) => AxiosInterceptor.post('/fileData', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }),
  updateFileData: (fileDataId, body) => AxiosInterceptor.put('/fileData/' + fileDataId, body),
  deleteFileData: (fileDataId) => AxiosInterceptor.delete('/fileData/' + fileDataId),
};
