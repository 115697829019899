import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from 'preferences/cookie/cookie';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { userReducer_reset } from 'store/modules/actions/user/userActions';

import { OperatorMenus } from './consts/OperatorMenus';
import { SpringBoardButton, SpringBoardComponent, SpringBoardMenu } from './OperatorDashboard.style';
import Footer from 'components/footer/Footer';
import OperatorHeader from './components/OperatorHeader';

const BoardMenu = (props) => {
  return (
    <SpringBoardMenu to={props.boardmenu.menuLink}>
      {props.boardmenu.icon}
      <p>{props.boardmenu.menuName}</p>
    </SpringBoardMenu>
  );
};

const OperatorDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') {
      alert(`접근 권한이 없습니다.`);

      console.log('================================= removeCookie');
      removeCookie('accessToken');
      removeCookie('refreshToken');
      dispatch(userReducer_reset());
      dispatch(mallSiteUserActions_setUser());

      return navigate('/', { replace: true });;
    }
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    return () => { };
  }, []);

  return (
    <SpringBoardComponent>
      <OperatorHeader />

      <section>
        {OperatorMenus.map((menu, index) => { return (<BoardMenu key={index + '_menu'} boardmenu={menu} />); })}
      </section>

      <section>
        <div>
          <SpringBoardButton to="/dashboard">아이피스 유저</SpringBoardButton>
        </div>
        <div>
          <SpringBoardButton to="/operator/test">테스트</SpringBoardButton>
        </div>

        <Footer />
      </section>
    </SpringBoardComponent>
  );
};

export default OperatorDashboard;
