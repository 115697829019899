import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { company } from 'api/apis/operator/company';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const UpdateCompany = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { operatorReducer, menuReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({...operatorReducer.updateCompany});

  /* ========================================================================= #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });

    setOnload('loaded');
    return () => {};
  }, []);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData({ ..._formData, [name]: value });
  };

  const updateCompany = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.companyName, false)) return alert('companyName은 필수 값입니다.');

    const body = {
      companyId: _formData.companyId,
      companyName: _formData.companyName,
      adminEmail: _formData.adminEmail,
    };
    if(_formData.companyId !== 2) {
      body.elementTypes = JSON.stringify(['material', 'half', 'product']);
      body.stockTypes = JSON.stringify({stockTypes: 'In/Put/Out'});
    }
    console.log('body : ', body);

    await company.updateCompany(body.companyId, body).then((response) => {
      if (response === undefined) return;
      console.log('company.updateCompany : ', response.data);
      alert('회사를 수정했습니다');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={'회사관리'} nav={''} />

        <Form
          title={<>회사<br />수정</>}
          buttons={
            <>
              <button className="formButton" onClick={updateCompany}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
              <div className="formBox">
                <h4>companyName</h4>
                <input
                  type="text"
                  name="companyName"
                  defaultValue={_formData.companyName}
                  onChange={handleInputEvent}
                  data-required="required"
                  placeholder="companyName"
                />
              </div>

              <div className="formBox">
                <h4>adminEmail</h4>
                <input
                  type="text"
                  name="adminEmail"
                  defaultValue={_formData.adminEmail}
                  onChange={handleInputEvent}
                  data-required="required"
                  placeholder="adminEmail"
                />
              </div>
            </>
          }
        />
      </main>
      }
    />
  );
};

export default UpdateCompany;
