import React, { useEffect, useState } from 'react';

import { userApi } from 'api/apis/userApi';

const EmailContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    teamId: modalData.team?.teamId,
    id: modalData.id,
    loginId: modalData.loginId,
    name: modalData.name,
    role: modalData.role,
    email: modalData.email,
    emailVerified: modalData.emailVerified,
    phoneNumber: modalData.phoneNumber,
    memo: modalData.memo,
  };

  const actEmailUpdate = async(e) => { // 이메일 동의 설정
    const emailVerified = e.target.dataset.agree;
    await userApi.updateUser(body.id, {...body, emailVerified: emailVerified}).then(response => {
      if(response === undefined) return;
      console.log('actRoleUpdate : ', response);
      alert(`이메일 동의를 [ ${emailVerified ? '동의' : '비동의'} ](으)로 설정했습니다.`);
      close('update');
    })
  };

  return (
    <section className='email-content'>
      <div>
        <h4>이메일</h4>
        <input
          type='text'
          name='email'
          disabled={true}
          value={body.email}
        />
      </div>

      <div>
        <button className='btn-save' data-agree={true} onClick={actEmailUpdate}>동의</button>
        <button className='btn-save' data-agree={false} onClick={actEmailUpdate}>비동의</button>
      </div>
    </section>
  );
};

export default EmailContent;