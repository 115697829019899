import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { companyMenu } from 'api/apis/operator/companyMenu';
import { priceMenu } from 'api/apis/operator/priceMenu';
import { priceTypeApi } from 'api/apis/operator/priceType';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import LoadingComponent from 'components/loading/LoadingComponent';
import NavBar from 'components/nav/NavBar';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  .TableSection {

  }
`;

const CreateCompanyModules = () => {
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const companyName = searchParams.get('companyName');

  const [_onload, setOnload] = useState('unload');

  const [_priceTypes, setPriceTypes] = useState([]);
  const [_priceType, setPriceType] = useState('');
  const [_moduleList, setModuleList] = useState([]);
  const keys = ['companyId', 'priceMenuId', 'priceMenuTypeName', 'companyPageCodeId', 'pageCode', 'defaultPageName'];

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    getPriceTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    getModuleList(_priceType);
  }, [_priceType])

  useEffect(() => { }, [_loadingStatus]);

  const getPriceTypes = async () => { // 요금유형 리스트 불러오기
    await priceTypeApi.search({ companyId: userReducer.company.companyId }).then(response => {
      if (response === undefined) return;
      setPriceTypes(() => { return response.data.content });
    })
  }
  const getModuleList = async (priceType) => { // 모듈 리스트 불러오기
    const body = { companyId: 1 };
    if (checkEmptyNull(priceType, false)) body.priceMenuTypeId = priceType;
    await priceMenu.searchPriceMenu(`?page=0&size=7000&sort=id,DESC`, body).then(response => {
      if (response === undefined) return;
      console.log('priceMenu.searchPriceMenu : ', response);
      setModuleList(() => { return response.data.content });
    })
  }

  const handleAllCheck = (e) => { // 전체 선택 이벤트
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheckedItem((prev) => {
        const prevData = [...prev];
        const newData = checkNullArray(_moduleList, []).map((thisItem) => {
          const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.priceMenuId === thisItem.priceMenuId);
          if (thisFindIndex !== -1) return null;
          else return thisItem;
        });
        const filterData = newData.filter((thisItem) => thisItem !== null);
        return [...prevData, ...filterData];
      });
      e.target.checked = true;
    } else {
      setCheckedItem((prev) => {
        const prevData = prev.map((thisItem) => {
          const thisFindIndex = checkNullArray(_moduleList, []).findIndex((thisIndex) => thisIndex.priceMenuId === thisItem.priceMenuId);
          if (thisFindIndex !== -1) return null;
          else return thisItem;
        });
        const filterData = prevData.filter((thisItem) => thisItem !== null);
        return filterData;
      });
      e.target.checked = false;
    }
  }
  const handleCheckedItem = (item, isChecked) => { // 선택 이벤트
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.priceMenuId === item.priceMenuId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    setCheckedItem(() => { return checkedItem });
  };
  const handlePriceType = (e) => { // 요금유형 선택
    const priceType = e.target.value;
    setPriceType(() => { return priceType });
    // getModuleList(priceType);
  }

  const actCreate = async (e) => { // 모듈 메뉴 추가
    e.preventDefault();
    const companyPageCodes = _checkedItem.map(item => item.pageCode);
    if (!checkNullArray(companyPageCodes, false)) return alert('선택된 메뉴가 없습니다.')

    setLoadingStatus(() => { return true }); // 로딩 화면 표시
    const body = {
      companyId: Number(companyId),
      pageCodes: companyPageCodes
    }

    await companyMenu.createCompanyModules(body).then(response => {
      if (response === undefined) return;
      alert(`모듈 메뉴가 추가되었습니다.`);
      setLoadingStatus(() => { return false }); // 로딩 화면 숨기기
      navigate({
        pathname: '/operator/companyManagement/menu',
        search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
        replace: true,
      })
    })
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (<>
    <Grid2Body contents={
      <Section className="Main">
        <NavBar title={'메뉴관리'} nav={''} />

        <Form
          title={<>모듈메뉴<br />추가</>}
          buttons={
            <>
              <button className="formButton" onClick={actCreate}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate({
                    pathname: '/operator/companyManagement/menu',
                    search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
                    replace: true,
                  })
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
              <div className='formBox w100'>
                <h4>module</h4>
                <select value={_priceType} onChange={handlePriceType}>
                  <option value={''}>모듈 선택</option>
                  {checkNullArray(_priceTypes, []).map((priceType, index) => {
                    return (<option key={index + '_priceType'} value={priceType.priceMenuTypeId}>{priceType.priceMenuTypeName}</option>)
                  })}
                </select>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <input type="checkBox" name="allElements" onChange={handleAllCheck} />
                        </th>
                        {keys.map((key, index) => { return (<th key={index + '_key'}>{key}</th>) })}
                      </tr>
                    </thead>
                    <tbody>
                      {_moduleList.map((module, index) => {
                        return (
                          <tr key={index + '_modules'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkbox"
                                name="modules"
                                checked={(() => {
                                  const checkedId = _checkedItem ? _checkedItem.findIndex((item) => item.priceMenuId === module.priceMenuId) : -1;
                                  if (checkedId !== -1) return true;
                                  else return false;
                                })()}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleCheckedItem(module, isChecked);
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                              />
                            </td>
                            {keys.map((key, keyIndex) => {
                              return (<td key={keyIndex + '_module_info'}>{module[key]}</td>)
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                } />
              </div>
            </>
          }
        />
      </Section>
    }
    />

    {_loadingStatus ? (<LoadingComponent close={() => {
      navigate({
        pathname: '/operator/companyManagement/menu',
        search: `?${createSearchParams({ companyId: companyId, companyName: companyName })}`,
        replace: true,
      })
    }} title={'모듈메뉴를 추가하고 있습니다...'} />) : null}
  </>);
};

export default CreateCompanyModules;
