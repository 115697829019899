import React from 'react';

import { MasterUserModalComponent } from './MasterUserModal.style';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

import RoleContent from './content/RoleContent';
import CompanyContent from './content/CompanyContent';
import TeamContent from './content/TeamContent';
import UserContent from './content/UserContent';
import EmailContent from './content/EmailContent';
import DeleteContent from './content/DeleteContent';

const MasterUserModal = ({
  open, close,
  modalType,
  modalData,
}) => {
  const translateType = (type) => {
    switch(type) {
      case 'role': return 'role 변경';
      case 'company': return '회사 변경';
      case 'team': return '팀 변경';
      case 'user': return '사용자 정보 수정';
      case 'email': return '이메일 동의 설정';
      case 'delete': return '사용자 삭제';

      default: return '';
    }
  }
  const typeContent = (type) => {
    switch(type) {
      case 'role': return <RoleContent close={close} modalData={modalData} />;
      case 'company': return <CompanyContent close={close} modalData={modalData} />;
      case 'team': return <TeamContent close={close} modalData={modalData} />;
      case 'user': return <UserContent close={close} modalData={modalData} />;
      case 'email': return <EmailContent close={close} modalData={modalData} />;
      case 'delete': return <DeleteContent close={close} modalData={modalData} />;

      default: return <></>;
    }
  }

  return (
    <>
    {open && (
      <MasterUserModalComponent>
        <div className={modalType}>
          <header>
            <h4>{translateType(modalType)}</h4>
            <div onClick={() => {close();}}><CloseButtonIcon /></div>
          </header>
          {typeContent(modalType)}
        </div>
      </MasterUserModalComponent>
    )}
    </>
  );
};

export default MasterUserModal;