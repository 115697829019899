/* 404 */
import NotFound from 'pages/common/NotFound';

/* oauth2 */
import OAuth2RedirectHandler from 'api/core/OAuth2RedirectHandler';
import Mall2RedirectHandler from 'api/core/Mall2RedirectHandler';
import Cluster2RedirectHandler from 'api/core/Cluster2RedirectHandler';
import Operating2RedirectHandler from 'api/core/Operating2RedirectHandler';

/* auth */
import Join from 'pages/auth/Join';
import Login from 'pages/auth/Login';
import FindEmail from 'pages/auth/email/FindEmail';
import FindPw from 'pages/auth/pw/FindPw';
// import PrivacyPolicy from 'pages/auth/PrivacyPolicy';
// 가입코드
import CompanySelect from 'pages/auth/CompanySelect';
import CompanySelectFromG from 'pages/auth/google/CompanySelectFromG';

/* common */
// 스프링보드(대시보드)
import Dashboard from 'pages/common/Dashboard';
// 내정보
import MyProfile from 'pages/user/myProfile/MyProfile';
// 회사정보
import CompanyInformation from 'pages/account/company/CompanyInformation';
/* inquiry - 문의 */
import InquiryList from 'pages/inquiry/index';
import InquiryInfo from 'pages/inquiry/info';

/* operator - 어드민 */
import OperatorDashboard from 'pages/operator/OperatorDashboard';
// 프로젝트
import ProjectManagementIndex from 'pages/operator/projectManagement/ProjectManagementIndex';
import ProjectManagement from 'pages/operator/projectManagement/content/ProjectManagement';
import ProjectTypeManagement from 'pages/operator/projectManagement/content/ProjectTypeManagement';

// 페이지코드
import PageCodeManagement from 'pages/operator/pageCode/PageCodeManagement';
import CreatePageCode from 'pages/operator/pageCode/crud/CreatePageCode';
import UpdatePageCode from 'pages/operator/pageCode/crud/UpdatePageCode';
import DeletePageCode from 'pages/operator/pageCode/crud/DeletePageCode';
// 모듈
import ModuleManagement from 'pages/operator/module/ModuleManagement';
import CreateModule from 'pages/operator/module/crud/CreateModule';
// 회사
import CompanyManagement from 'pages/operator/management/CompanyManagement';
import CreateCompanyManagement from 'pages/operator/management/crud/CreateCompany';
import UpdateCompanyManagement from 'pages/operator/management/crud/UpdateCompany';
import DeleteCompanyManagement from 'pages/operator/management/crud/DeleteCompany';
// 회사/접속현황
import CompanyAccessHistory from 'pages/operator/management/accessHistory/CompanyAccessHistory';
// 회사/메뉴관리
import CompanyMenu from 'pages/operator/management/menu/CompanyMenu';
import CreateCompanyModules from 'pages/operator/management/menu/crud/CreateCompanyModules';
import CreateCompanyMenu from 'pages/operator/management/menu/crud/CreateCompanyMenu';
import UpdateCompanyMenu from 'pages/operator/management/menu/crud/UpdateCompanyMenu';
import DeleteCompanyMenu from 'pages/operator/management/menu/crud/DeleteCompanyMenu';
// 사용자
import MasterUserManagement from 'pages/operator/user/MasterUserManagement';
import UpdateMasterCompany from 'pages/operator/UpdateMasterCompany';
// 알람
import AlarmTemplate from 'pages/operator/alarmTemplate/AlarmTemplate';
// 센서
import Sensor from 'pages/operator/sensor/sensor';
// 문의
import QnAManagement from 'pages/operator/inquiry/QnAManagement';
// 테스트
import Test from 'pages/test/Test';

/* user - 시스템/사용자 */
import UserManagement from 'pages/user/UserManagement';
import UpdateUser from 'pages/user/crud/UpdateUser';
import DeleteUser from 'pages/user/crud/DeleteUser';
import CreateWorker from 'pages/user/worker/crud/CreateWorker';
import UpdateWorker from 'pages/user/worker/crud/UpdateWorker';
/* team - 시스템/팀 */
import TeamManagement from 'pages/team/TeamManagement';
import CreateTeam from 'pages/team/crud/CreateTeam';
import UpdateTeam from 'pages/team/crud/UpdateTeam';
import DeleteTeam from 'pages/team/crud/DeleteTeam';
/* types - 시스템/설정 */
import TypeManagement from 'pages/types/TypeManagement';
import DeleteType from 'pages/types/crud/DeleteType';
/* coreElement - 시스템/핵심지표제품 >> 혀재 사용안함 */
import CoreElementManagement from 'pages/coreElement/CoreElementManagement';
import DeleteCoreElements from 'pages/coreElement/crud/DeleteCoreElement';
/* alarm - 시스템/알람설정 */
import AlarmManagement from 'pages/alarm/AlarmManagement';

/* element - 기준정보/물품정보 */
// 패밀리3 물품정보
import ElementManagement from 'pages/element/ElementManagement';
// 패밀리5 물품정보
import ElementManagementV2 from 'pages/element/ElementManagementV2';
import CopyElement from 'pages/element/crud/CopyElement';
import CreateElement from 'pages/element/crud/CreateElement';
import UpdateElement from 'pages/element/crud/UpdateElement';
import DeleteElement from 'pages/element/crud/DeleteElement';
/* category - 기준정보/패밀리 */
// 패밀리3 패밀리
import CategoryManagement from 'pages/category/CategoryManagement';
// 패밀리5 패밀리
import CategoryManagementV2 from 'pages/category/CategoryManagementV2';
import DeleteCategory from 'pages/category/crud/DeleteCategory';
/* attributes - 기준정보/항목 */
import AttributeManagement from 'pages/attributes/AttributeManagement';
import DeleteAttributes from 'pages/attributes/crud/DeleteAttributes';
/* account - 기준정보/거래처 */
import AccountManagement from 'pages/account/AccountManagement';
import CreateAccount from 'pages/account/curd/CreateAccount';
import UpdateAccount from 'pages/account/curd/UpdateAccount';
import DeleteAccount from 'pages/account/curd/DeleteAccount';
/* process - 기준정보/공정정의 */
import ProcessManagement from 'pages/process/ProcessManagement';
import CopyProcesses from 'pages/process/crud/CopyProcesses';
import CreateProcesses from 'pages/process/crud/CreateProcesses';
import UpdateProcesses from 'pages/process/crud/UpdateProcesses';
import DeleteProcesses from 'pages/process/crud/DeleteProcesses';
/* workOrder - 기준정보/작업정의 */
import WorkOrderManagement from 'pages/workOrder/WorkOrderManagement';
import CopyWorkOrder from 'pages/workOrder/crud/CopyWorkOrder';
import CreateWorkOrder from 'pages/workOrder/crud/CreateWorkOrder';
import UpdateWorkOrder from 'pages/workOrder/crud/UpdateWorkOrder';
import DeleteWorkOrder from 'pages/workOrder/crud/DeleteWorkOrder';
// 작업정의 > 작업지시 추가
import CreateWorkOrderLog from 'pages/workOrder/crud/CreateWorkOrderLog';
/* equipment - 기준정보/설비 */
import EquipmentManagement from 'pages/equipment/EquipmentManagement';
import CreateEquipment from 'pages/equipment/crud/CreateEquipment';
import UpdateEquipment from 'pages/equipment/crud/UpdateEquipment';
import DeleteEquipment from 'pages/equipment/crud/DeleteEquipment';
/* drawing - 기준정보/도면 >> 현재 우리창호/경서에서만 사용 */
import DrawingManagement from 'pages/drawing/DrawingManagement';
import CreateDrawingMangement from 'pages/drawing/crud/CreateDrawing';
import DeleteDrawingMangement from 'pages/drawing/crud/DeleteDrawing';
/* file - 기준정보/파일 */
import FileManagement from 'pages/file/FileManagement';
import DeleteFile from 'pages/file/crud/DeleteFile';
import DeleteFolder from 'pages/file/folder/crud/DeleteFolder';
/* shipmentInspectionItem - 기준정보/점검/검사정의 */
import ShipmentInspectionItem from 'pages/shipmentInspectionItem/ShipmentInspectionItem';
import CreateShipmentInspectionItem from 'pages/shipmentInspectionItem/crud/CreateShipmentInspectionItem';
import UpdateShipmentInspectionItem from 'pages/shipmentInspectionItem/crud/UpdateShipmentInspectionItem';
import DeleteShipmentInspectionItem from 'pages/shipmentInspectionItem/crud/DeleteShipmentInspectionItem';
/* shipmentInspection - 기준정보/시험성적서정의 */
import ShipmentInspection from 'pages/shipmentInspection/ShipmentInspection';
import CreateShipmentInspection from 'pages/shipmentInspection/crud/CreateShipmentInspection';
import UpdateShipmentInspection from 'pages/shipmentInspection/crud/UpdateShipmentInspection';
import DeleteShipmentInspection from 'pages/shipmentInspection/crud/DeleteShipmentInspection';

/* contract - 주문/수주 */
import Contract from 'pages/contract/Contract';
import CopyContract from 'pages/contract/crud/CopyContract';
import CreateContract from 'pages/contract/crud/CreateContract';
import UpdateContract from 'pages/contract/crud/UpdateContract';
import DeleteContract from 'pages/contract/crud/DeleteContract';
/* procurement - 주문/발주 */
import Procurement from 'pages/procurement/Procurement';
import CopyProcurement from 'pages/procurement/crud/CopyProcurement';
import CreateProcurement from 'pages/procurement/crud/CreateProcurement';
import UpdateProcurement from 'pages/procurement/crud/UpdateProcurement';
import DeleteProcurement from 'pages/procurement/crud/DeleteProcurement';
/* procurement/management */
import NotInStock from 'pages/procurement/management/NotInStock';
import DeleteNotInStock from 'pages/procurement/management/crud/DeleteNotInStock';
/* shipment - 주문/출하 */
import Shipment from 'pages/shipment/Shipment';
import CopyShipment from 'pages/shipment/crud/CopyShipement';
import CreateShipment from 'pages/shipment/crud/CreateShipment';
import UpdateShipment from 'pages/shipment/crud/UpdateShipment';
import DeleteShipment from 'pages/shipment/crud/DeleteShipment';
/* invioce - 주문/송장 */
import InvoiceManagement from 'pages/invoice/InvoiceManagement';
import CopyInvoice from 'pages/invoice/crud/CopyInvoice';
import CreateInvoice from 'pages/invoice/crud/CreateInvoice';
import UpdateInvoice from 'pages/invoice/crud/UpdateInvoice';
import DeleteInvoice from 'pages/invoice/crud/DeleteInvoice';

/* stock - 재고/현재고 */
import CurrentStock from 'pages/stock/CurrentStock';
import CurrentStockV2 from 'pages/stock/CurrentStockV2';
import CurrentStockV3 from 'pages/stock/CurrentStockV3';

/* stock/multi - 재고/다중처리 */
import StockIn from 'pages/stock/multi/StockIn';
import StockPut from 'pages/stock/multi/StockPut';
import MultiStock from 'pages/stock/multi/MultiStock';

/* stockMoving - 재고/재고이동 */
import StockMoving from 'pages/stockMoving/StockMoving';
import StockMovingLogs from 'pages/stockMoving/StockMovingLogs';

/* stockPlaceHistory - 재고/재고위치추적 */
import StockPlaceHistory from 'pages/stockPlaceHistory/StockPlaceHistory';

/* stockHistory - 재고/재고상세이력 */
// 패밀리3 재고상세이력
import StockHistory from 'pages/stockHistory/StockHistory';
// 패밀리5 재고상세이력
import StockHistoryV2 from 'pages/stockHistory/StockHistoryV2';
import UpdateStockHistory from 'pages/stockHistory/crud/UpdateStockHistory';

/* productionOrder - 생산관리/작업지시 */
import ProductionOrder from 'pages/productionOrder/ProductionOrder';
import CopyProductionOrder from 'pages/productionOrder/crud/CopyProductionOrder';
import CreateProductionOrder from 'pages/productionOrder/crud/CreateProductionOrder';
import UpdateProductionOrder from 'pages/productionOrder/crud/UpdateProductionOrder';
import DeleteProductionOrder from 'pages/productionOrder/crud/DeleteProductionOrder';

/* productionStatus - 생산관리/작업현황 */
import ProductionStatus from 'pages/productionStatus/ProductionStatus';

/* productionPerformance - 생산관리/작업실적 */
import ProductionPerformance from 'pages/productionPerformance/ProductionPerformance';

/* calendar - 생산관리/일일계획 */
import CalendarPlan from 'pages/calendar/CalendarPlan';

/* productionHistory - 생산관리/작업이력 */
import ProductionHistory from 'pages/productionHistory/ProductionHistory';

/* failedProductionOrder - 생산관리/불량재작업 */
import FailedProductionOrder from 'pages/failedProductionOrder/FailedProductionOrder';
import CreateFailedProductionOrder from 'pages/failedProductionOrder/crud/CreateFailedProductionOrder';

/* reProductionOrder - 생산관리/재작업이력 */
import ReProductionOrder from 'pages/reProductionOrder/ReProductionOrder';

/* manHour - 생산관리/공수관리 */
import ManHourManagement from 'pages/manHour/ManHourManagement';
import ManHourResult from 'pages/manHour/result/ManHourResult';
import Worker from 'pages/manHour/result/contents/Worker';
import Production from 'pages/manHour/result/contents/Production';

/* cost - 생산관리/원가확인 */
import Cost from 'pages/cost/Cost';

/* working - 제조실행/작업실행 */
import WorkerScreen from 'pages/working/WorkerScreen';

/* equipment/working - 제조실행/설비가동 */
import EquipmentStatus from 'pages/equipment/working/EquipmentStatus';

/* qualityControl - 품질/품질관리 */
import QualityControl from 'pages/qualityControl/QualityControl';

/* failedReason - 품질/불량사유 */
import FailedReason from 'pages/failedReason/FailedReason';
import CreateFailedReason from 'pages/failedReason/curd/CreateFailedReason';
import UpdateFailedReason from 'pages/failedReason/curd/UpdateFailedReason';
import DeleteFailedReason from 'pages/failedReason/curd/DeleteFailedReason';

/* shipmentInspectionLog - 품질/시험성적서 */
import ShipmentInspectionLog from 'pages/shipmentInspectionLog/ShipmentInspectionLog';
import CreateShipmentInspectionLog from 'pages/shipmentInspectionLog/crud/CreateShipmentInspectionLog';
import UpdateShipmentInspectionLog from 'pages/shipmentInspectionLog/crud/UpdateShipmentInspectionLog';
import DeleteShipmentInspectionLog from 'pages/shipmentInspectionLog/crud/DeleteShipmentInspectionLog';

/* monitoring - 모니터링/모니터링 */
import MonitoringIndex from 'pages/monitoring/index';
import MonitoringTotal from 'pages/monitoring/total';
import MonitoringMaterial from 'pages/monitoring/material';
import MonitoringProduction from 'pages/monitoring/production';
import MonitoringQuality from 'pages/monitoring/quality';

/* kpi - 모니터링/KPI모니터링 */
import KPIMonitoring from 'pages/kpi/KPIMonitoring';

/* scheduler - 모니터링/작업일정 */
import Scheduler from 'pages/scheduler/Scheduler';

/* accessHistory - 모니터링/사용자접속현황 */
import AccessHistory from 'pages/accessHistory/AccessHistory';

/* barcode - 바코드 */
import BarcodeIndex from 'pages/barcode/Index';
// 바코드 스캔
import BarcodeScan from 'pages/barcode/scan/ScanBarcode';
// 바코드 출력
import BarcodePrint from 'pages/barcode/print/PrintBarcode';
import BarcodePrintElement from 'pages/barcode/print/Element';
import BarcodePrintStock from 'pages/barcode/print/Stock';
import BarcodePrintLot from 'pages/barcode/print/Lot';
import BarcodePrintSerial from 'pages/barcode/print/Serial';

/* mallManagement */
/* mallManagement/mallOrder - 고객주문관리/주문관리 */
import MallOrderManagement from 'pages/mallManagement/mallOrder/MallOrderManagement';
import CreateMallOrder from 'pages/mallManagement/mallOrder/crud/CreateMallOrder';
import UpdateMallOrder from 'pages/mallManagement/mallOrder/crud/UpdateMallOrder';
import DeleteMallOrder from 'pages/mallManagement/mallOrder/crud/DeleteMallOrder';
/* mallManagement/mallQnA - 고객주문관리/문의관리 */
import MallQnAManagement from 'pages/mallManagement/mallQnA/MallQnAManagement';
import CreateMallQnA from 'pages/mallManagement/mallQnA/crud/CreateMallQnA';
import UpdateMallQnA from 'pages/mallManagement/mallQnA/crud/UpdateMallQnA';
import DeleteMallQnA from 'pages/mallManagement/mallQnA/crud/DeleteMallQnA';
/* mallManagement/mallElement - 고객주문관리/판매물품관리 */
import MallElementManagement from 'pages/mallManagement/mallElement/MallElementManagement';
import CreateMallElement from 'pages/mallManagement/mallElement/crud/CreateMallElement';
import UpdateMallElement from 'pages/mallManagement/mallElement/crud/UpdateMallElement';
import DeleteMallElement from 'pages/mallManagement/mallElement/crud/DeleteMallElement';
/* mallManagement/mallCategory - 고객주문관리/카테고리관리 */
import MallCategoryManagement from 'pages/mallManagement/mallCategory/MallCategoryManagement';
import DeleteMallCategory from 'pages/mallManagement/mallCategory/crud/DeleteMallCategory';
/* mallManagement/mallUser - 고객주문관리/고객관리 */
import MallUserManagement from 'pages/mallManagement/mallUser/MallUserManagement';
import UpdateMallUser from 'pages/mallManagement/mallUser/crud/UpdateMallUser';
import DeleteMallUser from 'pages/mallManagement/mallUser/crud/DeleteMallUser';

/* mall */
/* mall/pages - sitemap */
import MallSiteMap from 'mall/pages/sitemap';
/* mall/pages - main */
import MallMain from 'mall/pages/main';
/* mall/pages/sign - auth */
import MallSignJoin from 'mall/pages/sign/join';
import MallSignLogin from 'mall/pages/sign/login';
import MallSignEmailFind from 'mall/pages/sign/email/find';
import MallSignPwFind from 'mall/pages/sign/pw/find';

/* mall/pages/cart - 장바구니 */
import MallCart from 'mall/pages/cart/index';
/* mall/pages/qna - 문의 */
import MallQna from 'mall/pages/qna/index';
import MallQnaList from 'mall/pages/qna/list';

/* mall/pages/product - 제품 */
import MallProductAll from 'mall/pages/product/all';
import MallProductList from 'mall/pages/product/list';
// 상세보기
import MallProductDetail from 'mall/pages/product/detail';
// 문의
import MallProductQna from 'mall/pages/product/qna';
// 주문
import MallProductOrder from 'mall/pages/product/order';

/* mall/pages/mypage - 마이페이지 */
import MallMypage from 'mall/pages/mypage/index';
import MallMypageInfo from 'mall/pages/mypage/info';
import MallMypageOrderList from 'mall/pages/mypage/order/list';
import MallMypageQnaList from 'mall/pages/mypage/qna/list';

/* platform */
/* cluster */
/* cluster/pages - main */
import ClusterMain from 'platform/cluster/pages/main';
import ClusterBoard from 'platform/cluster/pages/board';
/* cluster/pages/sign - auth */
import ClusterSignJoin from 'platform/cluster/pages/sign/join';
import ClusterSignLogin from 'platform/cluster/pages/sign/login';
import ClusterSignEmailFind from 'platform/cluster/pages/sign/pw/find';
import ClusterSignPwFind from 'platform/cluster/pages/sign/email/find';
/* 메인 페이지 */
import MainPageRequestContent from 'platform/cluster/pages/MainPageRequestContent';
import MainPageSharingContent from 'platform/cluster/pages/MainPageSharingContent';
import MainPageBoardContent from 'platform/cluster/pages/MainPageBoardContent';
/* cluster/pages/mypage - 마이 페이지 */
import MyPage from 'platform/cluster/pages/mypage/MyPage';
// 내 정보
import MyInfo from 'platform/cluster/pages/mypage/MyInfo';
// 공유 컨텐츠
import MyPageRequestContent from 'platform/cluster/pages/mypage/RequestContent';
import MyPageSharedContent from 'platform/cluster/pages/mypage/SharedContent';
import MyPageShareManagement from 'platform/cluster/pages/mypage/Management';
import MyPageSettingType from 'platform/cluster/pages/mypage/SettingType';
import MyPageSettingAttributes from 'platform/cluster/pages/mypage/SettingAttributes';
// 내 게시글
import MainBoard from 'platform/cluster/pages/mypage/MainBoard';

/* operating */
/* operating/pages - main */
import OperatingMain from 'platform/operating/pages/main';
/* operating/pages - auth */
import OperatingSignLogin from 'platform/operating/pages/sign/login';

// KPI 모니터링
import OperatingKPIMonitoring from 'platform/operating/pages/monitoring/OperatingKPIMonitoring';
// 작업 현황
import OperatingProductionStatus from 'platform/operating/pages/productionStatus/OperatingProductionStatus';
// 공정 트래킹
import OperatingProcessTracking from 'platform/operating/pages/processTracking/OperatingProcessTracking';
// 마이페이지
import OperatingMyPage from 'platform/operating/pages/mypage/OperatingMyPage';

export {
  // 404
  NotFound,

  // outh
  OAuth2RedirectHandler, Mall2RedirectHandler, Cluster2RedirectHandler, Operating2RedirectHandler,

  // auth
  Join, Login, FindEmail, FindPw, CompanySelect, CompanySelectFromG,

  // common
  Dashboard, MyProfile, CompanyInformation,
  InquiryList, InquiryInfo,

  // operator
  OperatorDashboard,
  ProjectManagementIndex, ProjectManagement, ProjectTypeManagement,
  PageCodeManagement, CreatePageCode, UpdatePageCode, DeletePageCode,
  ModuleManagement, CreateModule,
  CompanyManagement, CreateCompanyManagement, UpdateCompanyManagement, DeleteCompanyManagement,
  CompanyAccessHistory,
  CompanyMenu, CreateCompanyModules, CreateCompanyMenu, UpdateCompanyMenu, DeleteCompanyMenu,
  MasterUserManagement, UpdateMasterCompany,
  AlarmTemplate,
  Sensor,
  QnAManagement,
  Test,

  // 시스템
  UserManagement, UpdateUser, DeleteUser, CreateWorker, UpdateWorker,
  TeamManagement, CreateTeam, UpdateTeam, DeleteTeam,
  TypeManagement, DeleteType,
  CoreElementManagement, DeleteCoreElements,
  AlarmManagement,

  // 기준정보
  ElementManagement, ElementManagementV2, CopyElement, CreateElement, UpdateElement, DeleteElement,
  CategoryManagement, CategoryManagementV2, DeleteCategory,
  AttributeManagement, DeleteAttributes,
  AccountManagement, CreateAccount, UpdateAccount, DeleteAccount,
  ProcessManagement, CopyProcesses, CreateProcesses, UpdateProcesses, DeleteProcesses,
  WorkOrderManagement, CopyWorkOrder, CreateWorkOrder, UpdateWorkOrder, DeleteWorkOrder, CreateWorkOrderLog,
  EquipmentManagement, CreateEquipment, UpdateEquipment, DeleteEquipment,
  DrawingManagement, CreateDrawingMangement, DeleteDrawingMangement,
  FileManagement, DeleteFile, DeleteFolder,
  ShipmentInspectionItem, CreateShipmentInspectionItem, UpdateShipmentInspectionItem, DeleteShipmentInspectionItem,
  ShipmentInspection, CreateShipmentInspection, UpdateShipmentInspection, DeleteShipmentInspection,

  // 주문
  Contract, CopyContract, CreateContract, UpdateContract, DeleteContract,
  Procurement, CopyProcurement, CreateProcurement, UpdateProcurement, DeleteProcurement,
  NotInStock, DeleteNotInStock,
  Shipment, CopyShipment, CreateShipment, UpdateShipment, DeleteShipment,
  InvoiceManagement, CopyInvoice, CreateInvoice, UpdateInvoice, DeleteInvoice,

  // 재고
  CurrentStock, CurrentStockV2, CurrentStockV3,
  StockIn, StockPut, MultiStock,
  StockMoving, StockMovingLogs,
  StockPlaceHistory,
  StockHistory, StockHistoryV2, UpdateStockHistory,

  // 생산관리
  ProductionOrder, CopyProductionOrder, CreateProductionOrder, UpdateProductionOrder, DeleteProductionOrder,
  ProductionStatus,
  ProductionPerformance,
  CalendarPlan,
  ProductionHistory,
  FailedProductionOrder, CreateFailedProductionOrder,
  ReProductionOrder,
  ManHourManagement, ManHourResult, Worker, Production,
  Cost,

  // 제조실행
  WorkerScreen,
  EquipmentStatus,

  // 품질
  QualityControl,
  FailedReason, CreateFailedReason, UpdateFailedReason, DeleteFailedReason,
  ShipmentInspectionLog, CreateShipmentInspectionLog, UpdateShipmentInspectionLog, DeleteShipmentInspectionLog,

  // 모니터링
  MonitoringIndex, MonitoringTotal, MonitoringMaterial, MonitoringProduction, MonitoringQuality,
  KPIMonitoring,
  Scheduler,
  AccessHistory,

  // 바코드
  BarcodeIndex, BarcodeScan,
  BarcodePrint, BarcodePrintElement, BarcodePrintStock, BarcodePrintLot, BarcodePrintSerial,

  // 고객주문관리
  MallOrderManagement, CreateMallOrder, UpdateMallOrder, DeleteMallOrder,
  MallQnAManagement, CreateMallQnA, UpdateMallQnA, DeleteMallQnA,
  MallElementManagement, CreateMallElement, UpdateMallElement, DeleteMallElement,
  MallCategoryManagement, DeleteMallCategory,
  MallUserManagement, UpdateMallUser, DeleteMallUser,

  // 몰
  MallSiteMap,
  MallMain,
  MallSignJoin, MallSignLogin, MallSignEmailFind, MallSignPwFind,
  MallCart,
  MallQna, MallQnaList,
  MallProductAll, MallProductList, MallProductDetail, MallProductQna, MallProductOrder,
  MallMypage, MallMypageInfo, MallMypageOrderList, MallMypageQnaList,

  // platform
  // cluster
  ClusterSignJoin, ClusterSignLogin, ClusterSignEmailFind, ClusterSignPwFind,
  ClusterMain, ClusterBoard,
  // 메인 페이지
  MainPageRequestContent, MainPageSharingContent, MainPageBoardContent,
  // 마이 페이지
  MyPage, MyInfo,
  MyPageRequestContent, MyPageSharedContent, MyPageShareManagement, MyPageSettingType, MyPageSettingAttributes,
  MainBoard,

  // operating
  OperatingSignLogin,
  OperatingMain,
  OperatingKPIMonitoring, OperatingProductionStatus, OperatingProcessTracking,
  OperatingMyPage,
}