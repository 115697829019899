import { AxiosInterceptor } from 'api/core';

export const shipmentInspectionLog = {
  getShipmentInspectionLog: (shipmentInspectionLogId) => AxiosInterceptor.get('shipmentInspectionLog/' + shipmentInspectionLogId),
  searchShipmentInspectionLog: (paging, body) => AxiosInterceptor.post('shipmentInspectionLog/search' + paging, body),
  createShipmentInspectionLog: (body) => AxiosInterceptor.post('shipmentInspectionLog', body),
  copyShipmentInspectionLog: (body) => AxiosInterceptor.post('shipmentInspectionLog', body),
  updateShipmentInspectionLog: (shipmentInspectionLogId, body) => AxiosInterceptor.put('shipmentInspectionLog/' + shipmentInspectionLogId, body),
  deleteShipmentInspectionLog: (shipmentInspectionLogId) => AxiosInterceptor.delete('shipmentInspectionLog/' + shipmentInspectionLogId),
};
