import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { shipmentActions_setStartDate, shipmentActions_setEndDate, shipmentActions_setSearchOption, shipmentActions_setSearchText, shipmentActions_setPageNumber, shipmentActions_setTotalSize, shipmentActions_setIsPushedSearchButton, shipmentActions_setReset, shipmentActions_setViewScale, shipmentActions_setShipmentStatus } from 'store/modules/actions/common/shipmentActions';

import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';
import { rotateRight } from 'components/icons/src';
import { ViewResetBtn } from 'components/buttons/ViewButton';

import DeliveryInvoicePdfModal from './invoice/modal/DeliveryInvoicePdfModal';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import InvoicePdfModal from 'pages/shipment/invoice/modal/InvoicePdfModal';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShipmentModal from './modal/ShipmentModal';
import ShipmentRow from 'components/row/ShipmentRow';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';

const ShipmentTable = styled.table`
  thead tr {border: none;}
  tbody {
    tr {border-bottom: 1px solid var(--MainBlue);}
    td {border: 1px solid var(--gray-200); height: fit-content;}
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 24px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Shipment = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shipmentReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_shipmentList, setShipmentList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);
  const [_shipmentModalStatus, setShipmentModalStatus] = useState(false);

  const [_printInvoiceModalStatus, setPrintInvoiceModalStatus] = useState(false);
  const [_printDeliveryModalStatus, setPrintDeliveryModalStatus] = useState(false);
  const [_eventShipment, setEventShipment] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('205') === true, // 출하
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getShipmentList(shipmentReducer.pageNumber - 1, shipmentReducer.shipmentStatus, shipmentReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentList(shipmentReducer.pageNumber - 1, shipmentReducer.shipmentStatus, shipmentReducer.isPushedSearchButton);
    }

    return () => { };
  }, [shipmentReducer.pageNumber]);

  useEffect(() => { }, [shipmentReducer]);

  /* ========================================================================= #4 */
  /* 출하 */
  const getShipmentList = async (page, shipmentStatus, isPushedSearchButton) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(shipmentStatus, false) && shipmentStatus !== 'all') {
      BodyToPost.shipmentStatus = shipmentStatus;
    }
    if (checkEmptyNull(shipmentReducer.startDate, false) && checkEmptyNull(shipmentReducer.endDate, false)) {
      const startDate = new Date(shipmentReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.shipmentStartDate = setStartDate;

      const endDate = new Date(shipmentReducer.endDate);
      // endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.shipmentEndDate = setEndDate;

      // 시작 날짜가 종료날짜보다 클 때
      if (shipmentReducer.startDate > shipmentReducer.endDate) {
        const reverseStartDate = new Date(shipmentReducer.startDate);
        // reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(shipmentReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.shipmentStartDate = setReversetEndDate;
        BodyToPost.shipmentEndDate = setReverseStartDate;
      }
    }
    if (isPushedSearchButton && checkEmptyNull(shipmentReducer.searchOption, false) && checkEmptyNull(shipmentReducer.searchText, false)) {
      BodyToPost[shipmentReducer.searchOption] = shipmentReducer.searchText;
    }

    await shipmentApi.searchShipment(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.searchShipment : ', response);
      setShipmentList(() => { return response.data.content });
      dispatch(shipmentActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (shipment, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(shipment);
    setCheckedItem(() => { return checkedItem });
  };
  const handleShipmentStatus = async (shipment, index) => {
    setEventShipment(() => { return shipment });
    setTimeout(setShipmentModalStatus(true), 1000);
  };

  const actSearch = async () => {
    dispatch(shipmentActions_setPageNumber(1));
    dispatch(shipmentActions_setIsPushedSearchButton(true));
    await getShipmentList(0, shipmentReducer.shipmentStatus, true);
  };
  const actExcelDownload = () => {
    setExcelDownloadModalStatus(true);
  };

  /* 거래명세서 */
  const actPrintInvoice = (shipment, index) => {
    setEventShipment(() => { return shipment });
    setTimeout(setPrintInvoiceModalStatus(true), 1000);
  };
  const actPrintDeliveryInvoice = (shipment, index) => {
    console.log('shipment : ', shipment);
    setEventShipment(() => { return shipment });
    setTimeout(setPrintDeliveryModalStatus(true), 1000);
  };

  const goDetail = (detailContent) => {
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(detailContent),
      })}`,
      replace: true,
    });
  };

  /* 복사 */
  const actCopy = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('복사할 출하를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({
        copyContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 출하를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 출하를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        deleteContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={'출하관리'}
              buttons={
                <>
                  {_authority.indexOf('205-2') !== -1 ? (
                    <>
                      <button
                        className='btn-add'
                        onClick={(e) => {
                          e.preventDefault();
                          setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                        }}
                      >
                        추가
                      </button>
                      <button className='btn-copy' onClick={actCopy}>복사</button>
                      <button className='btn-edit' onClick={actUpdate}>수정</button>
                      <button className='btn-delete' onClick={actDelete}>삭제</button>
                    </>
                  ) : null}
                  {/* <button className='btn-download' onClick={actExcelDownload}>다운로드</button> */}
                </>
              }
              nav={'search'}
              firstRow={
                <>
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <select
                      className="SelectStatus"
                      value={shipmentReducer.shipmentStatus}
                      onChange={(e) => {
                        dispatch(shipmentActions_setShipmentStatus(e.target.value));
                        dispatch(shipmentActions_setPageNumber(1));
                        getShipmentList(0, e.target.value, shipmentReducer.isPushedSearchButton);
                      }}
                    >
                      {[
                        { optionValue: 'all', optionText: '전체' },
                        { optionValue: 'wait', optionText: '대기' },
                        { optionValue: 'end', optionText: '완료' },
                      ].map((thisItem) => {
                        return (
                          <option key={thisItem.optionValue + '_shipmentStatus'} value={thisItem.optionValue}>
                            {thisItem.optionText}
                          </option>
                        );
                      })}
                    </select>
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={shipmentReducer.startDate}
                        onChange={(e) => { dispatch(shipmentActions_setStartDate(e.target.value)) }}
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={shipmentReducer.endDate}
                        onChange={(e) => { dispatch(shipmentActions_setEndDate(e.target.value)) }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          value={shipmentReducer.searchOption}
                          onChange={(e) => { dispatch(shipmentActions_setSearchOption(e.target.value)) }}
                        >
                          {[
                            { optionValue: '', optionText: '검색옵션' },
                            { optionValue: 'shipmentCode', optionText: '출하코드' },
                            { optionValue: 'shipmentName', optionText: '출하이름' },
                          ].map((thisItem) => {
                            return (
                              <option key={thisItem.optionValue + '_shipmentSearchOption'} value={thisItem.optionValue}>
                                {thisItem.optionText}
                              </option>
                            );
                          })}
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={shipmentReducer.searchText}
                          onInput={(e) => { dispatch(shipmentActions_setSearchText(e.target.value)); }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton"
                        onClick={() => {
                          dispatch(shipmentActions_setReset());
                          getShipmentList(0, false);
                        }}
                      >
                        초기화
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <TableSection
              sectionStyle={{ height: 'calc(100% - 66px)' }}
              content={
                <ShipmentTable style={{ zoom: `${shipmentReducer.viewScale}%` }}>
                  <thead>
                    <tr>
                      {_authority.indexOf('205-2') !== -1 ? (
                        <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                      ) : null}
                      <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>
                      <th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>거래<br />명세서</th>
                      <th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>송장</th>
                      <th rowSpan={2}>출하일자<br />(거래일자)</th>
                      <th rowSpan={2}>출하코드<br />(일련번호)</th>
                      <th rowSpan={2}>출하이름</th>
                      <th rowSpan={2}>거래처</th>
                      <th rowSpan={2}>출하총금액</th>
                      <th rowSpan={2}>비고</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!checkNullArray(_shipmentList, false) ? LoadingMsg() :
                      checkNullArray(_shipmentList, []).map((shipment, index) => {
                        return (
                          <ShipmentRow
                            key={index + '_shipments'}
                            authority={_authority}
                            index={index}
                            content={shipment}
                            checkedItem={_checkedItem}
                            handleCheckedItem={handleCheckedItem}
                            handleShipmentStatus={handleShipmentStatus}
                            actPrintInvoice={actPrintInvoice}
                            actPrintDeliveryInvoice={actPrintDeliveryInvoice}
                            goDetail={goDetail}
                          />
                        );
                      })}
                  </tbody>
                </ShipmentTable>
              }
            ></TableSection>
          </main>

          <PagingComponent
            page={shipmentReducer.pageNumber}
            count={shipmentReducer.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(shipmentActions_setPageNumber(page));
            }}
          />

          <ViewerButtons>
            <ViewMinusButton
              viewScale={shipmentReducer.viewScale}
              handleViewScale={(scale) => { dispatch(shipmentActions_setViewScale(scale)) }}
            />
            <ViewPlusButton
              viewScale={shipmentReducer.viewScale}
              handleViewScale={(scale) => { dispatch(shipmentActions_setViewScale(scale)) }}
            />
            <button onClick={ViewResetBtn}><img src={rotateRight} className='icon-default' alt='화면 줌 초기화 버튼' /></button>
          </ViewerButtons>

          {_excelDownloadModalStatus === true && (
            <ExcelDownloadModal
              mainTitle={'shipment'}
              subTitle={'shipment'}
              open={_excelDownloadModalStatus}
              close={() => { setExcelDownloadModalStatus(false) }}
            />
          )}

          {_shipmentModalStatus === true && (
            <ShipmentModal
              shipment={_eventShipment}
              open={_shipmentModalStatus}
              close={() => {
                setShipmentModalStatus(false);
                getShipmentList(shipmentReducer.pageNumber - 1, shipmentReducer.shipmentStatus, shipmentReducer.isPushedSearchButton);
              }}
            />
          )}

          {_printInvoiceModalStatus === true && (
            <InvoicePdfModal
              shipment={_eventShipment}
              open={_printInvoiceModalStatus}
              close={() => { setPrintInvoiceModalStatus(false) }}
            />
          )}
          {_printDeliveryModalStatus === true && (
            <DeliveryInvoicePdfModal
              shipment={_eventShipment}
              open={_printDeliveryModalStatus}
              close={() => { setPrintDeliveryModalStatus(false) }}
            />
          )}
        </>
      }
    />
  );
};

export default Shipment;
