import { CompanySelect, CompanySelectFromG, FindEmail, FindPw, Join, Login, Mall2RedirectHandler, MallMain, MallProductAll, MallProductDetail, MallProductList, MallSignEmailFind, MallSignJoin, MallSignLogin, MallSignPwFind, MallSiteMap, NotFound, OAuth2RedirectHandler } from "../RouteImport";
import RouteLayout from "../RouteLayout";

export const guestRoutes = [
  { path: '*', element: <NotFound />, },
  { path: '/', element: <Login />, },
  { path: 'oauth2/redirect', element: <OAuth2RedirectHandler />, }, // 토큰 받아옴
  {
    path: 'sign', element: <RouteLayout />, // 로그인 페이지
    children: [
      { path: '', element: <Login /> },
      { path: 'join', element: <Join /> },
      { path: 'email/find', element: <FindEmail /> },
      { path: 'pw/find', element: <FindPw /> },
    ]
  },
  {
    path: 'companySelect', element: <RouteLayout />, // 회사 선택 >> 가입코드 입력
    children: [
      { path: '', element: <CompanySelect /> },
      { path: 'google', element: <CompanySelectFromG /> }
    ]
  },
  {
    path: ':code/mall', element: <RouteLayout />,
    children: [
      { path: 'oauth2/redirect', element: <Mall2RedirectHandler /> }, // 토큰 받아옴
      { path: 'sitemap', element: <MallSiteMap /> }, // 사이트맵 페이지
      {
        path: 'sign', element: <RouteLayout />, // 로그인 페이지
        children: [
          { path: '', element: <MallSignLogin />, },
          { path: 'login', element: <MallSignLogin />, },
          { path: 'join', element: <MallSignJoin />, },
          { path: 'email/find', element: <MallSignEmailFind /> },
          { path: 'pw/find', element: <MallSignPwFind /> },
        ],
      },
      {
        path: '', element: <MallMain />,
        children: [
          {
            path: 'product', element: <RouteLayout />,
            children: [
              { path: 'all', element: <MallProductAll />, },  // 전체 물품
              { path: 'list', element: <MallProductList />, },
              { path: 'detail', element: <MallProductDetail />, },
            ],
          },
        ]
      },
    ]
  }
];